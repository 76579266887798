.slogan {

  width: 100%;
  height: 240px;
  background-color: var(--navy);

  &>.pack {
    display: flex;
    justify-content: center;
    height: 100%;
    padding-top: 29px;
    padding-bottom: 38px;
    box-sizing: border-box;

    &>img {
      width: 94px;
      height: 91px;
    }

    &>.left {
      align-self: flex-end;
      transform: scaleX(-1);
    }

    &>.title {
      align-self: center;
      text-align: center;
      font-size: 28px;
      font-weight: 500;
      line-height: 1.43;
      margin-left: 25px;
      margin-right: 17px;
      color: white;
      white-space: nowrap;
      font-family: "score";

      &>b {
        color: var(--dark-sky-blue);
        font-family: "score";
        font-weight: bold;
      }
    }

    &>.right {
      align-self: flex-start;
    }
  }
}

.airslogan {
  &>.pack {
    &>.title {
      margin: 0 50px;
    }
  }
}

.homecleanslogan {
  background-color: var(--shamrock);

  &>.pack {
    &>img {
      width: 62.8px;
      height: 59.5px;
    }

    &>.title {
      &>b {
        color: white;
      }
    }
  }
}

.businessslogan {
  background-color: #40ccc7;

  &>.pack {
    &>img {
      width: 62.8px;
      height: 59.5px;
    }

    &>.title {
      color: var(--navy);
      margin-right: 33px;

      &>b {
        color: var(--navy);
      }
    }
  }
}

.matslogan {
  &>.pack {
    &>.title {
      margin: 0 35px;
    }
  }
}

.filterslogan {
  &>.pack {
    &>.title {
      margin: 0 65px;
    }
  }
}

.airfilterslogan {
  &>.pack {
    &>.title {
      margin: 0 72.2px 0 34px;
    }
  }
}

.purifyslogan {
  &>.pack {
    &>.title {
      margin: 0 46.2px 0 31px;
    }
  }
}

@media screen and (max-width : 1024px) {
  .slogan {
    height: 252px;

    &>.pack {
      padding: 55.5px 0;

      &>img {
        width: 55px;
        height: 50px;
      }

      &>.title {
        font-size: 25px;
        margin: 0 27px;
      }
    }
  }

  .washslogan {
    &>.pack {
      &>.title {
        margin: 0 20px;
      }
    }
  }

}

@media screen and (max-width : 767px) {
  .slogan {
    height: 220px;

    &>.pack {
      flex-direction: column;
      justify-content: unset;
      padding: 33px 0 23px 0;

      &>.left {
        align-self: flex-start;
        transform: scale(-1);
      }

      &>.title {
        font-size: 16px;

      }

      &>.right {
        align-self: flex-end;
      }
    }
  }

  .businessslogan {
    &>.pack {
      &>img {
        width: 35px;
      }

      &>.title {
        margin: 0;

      }
    }
  }

  .homecleanslogan {
    &>.pack {
      &>img {
        width: 35px;
      }
    }
  }

  .purifyslogan {
    height: 289px;
  }

  .washslogan,
  .hoodslogan,
  .airslogan,
  .filterslogan,
  .birdslogan,
  .bugslogan {
    &>.pack {
      &>.title {
        margin: 0;
      }
    }
  }
}