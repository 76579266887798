.business > .banner {
  height: 370px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: -webkit-image-set(url("/public/assets/business/banner.png") 1x, url("/public/assets/business/banner@2x.png") 2x, url("/public/assets/business/banner@3x.png") 3x);
  background-image: image-set(url("/public/assets/business/banner.png") 1x, url("/public/assets/business/banner@2x.png") 2x, url("/public/assets/business/banner@3x.png") 3x);
  text-align: center;
  padding-top: 234px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.business > .banner > .title {
  font-size: 39px;
  font-weight: bold;
  color: white;
}

.business > .building-care {
  width: 100%;
  height: 1093px;
  background-color: #f8f8f8;
}

.business > .building-care > .pack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 97px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.business > .building-care > .pack > .left > .category {
  width: 147px;
  height: 33px;
  background-color: #40ccc7;
  font-weight: bold;
  border-radius: 16.5px;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 11px;
  font-family: 'gmarket';
}

.business > .building-care > .pack > .left > .title {
  margin-top: 19px;
  font-size: 35px;
  font-weight: 500;
  line-height: 1.26;
  color: var(--navy);
  font-family: "score";
}

.business > .building-care > .pack > .left > .title > b {
  font-family: "score";
  font-weight: bold;
}

.business > .building-care > .pack > .right {
  display: -ms-grid;
  display: grid;
  row-gap: 20px;
}

.business > .building-care > .pack > .right > .card {
  width: 524px;
  height: 198px;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 20px;
  padding-left: 51px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.business > .building-care > .pack > .right > .card > img {
  width: 120px;
  height: 120px;
}

.business > .building-care > .pack > .right > .card > .content {
  margin-left: 43px;
}

.business > .building-care > .pack > .right > .card > .content > .title {
  font-size: 25px;
  position: relative;
}

.business > .building-care > .pack > .right > .card > .content > .title > .tl {
  z-index: 10;
  position: relative;
}

.business > .building-care > .pack > .right > .card > .content > .title > .back {
  position: absolute;
  width: 110%;
  height: 21px;
  background-color: #a5e6e2;
  left: -5%;
  bottom: -3px;
  z-index: 5;
}

.business > .building-care > .pack > .right > .card > .content > .sub {
  margin-top: 12px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.54;
  white-space: pre-line;
}

.business > .purify-one {
  width: 100%;
  height: 1596px;
  background-color: #f8f8f8;
  padding-top: 162px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.business > .purify-one > .pack > .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.business > .purify-one > .pack > .top > .left > .category {
  width: 162px;
  height: 33px;
  border-radius: 16.5px;
  color: white;
  background-color: #40ccc7;
  font-size: 11px;
  font-weight: bold;
  font-family: 'gmarket';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.business > .purify-one > .pack > .top > .left > .title {
  font-size: 36px;
  color: var(--navy);
  margin: 13px 0;
  font-family: "score";
}

.business > .purify-one > .pack > .top > .left > .title > b {
  font-family: "score";
  font-weight: bold;
}

.business > .purify-one > .pack > .top > .left > .sub {
  font-size: 20px;
}

.business > .purify-one > .pack > .top > img {
  width: 167px;
  height: 167px;
}

.business > .purify-one > .pack > .bottom {
  margin-top: 85px;
  width: 100%;
}

.business > .purify-one > .pack > .bottom > .bottom-head {
  width: 100%;
  height: 110px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 580px 413px;
      grid-template-columns: 580px 413px;
  -webkit-column-gap: 1px;
          column-gap: 1px;
  background-color: white;
  font-size: 22px;
  font-weight: bold;
  color: var(--navy);
}

.business > .purify-one > .pack > .bottom > .bottom-head > .left {
  background-color: #a7e8e6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.business > .purify-one > .pack > .bottom > .bottom-head > .right {
  display: -ms-grid;
  display: grid;
  height: 100%;
  row-gap: 1px;
}

.business > .purify-one > .pack > .bottom > .bottom-head > .right > .right-top {
  background-color: #e0e0e0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.business > .purify-one > .pack > .bottom > .bottom-head > .right > .right-bottom {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  -webkit-column-gap: 1px;
          column-gap: 1px;
}

.business > .purify-one > .pack > .bottom > .bottom-head > .right > .right-bottom > .month {
  background-color: #e0e0e0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.business > .purify-one > .pack > .bottom > .bottom-body {
  background-color: #e0e0e0;
  height: 917px;
  font-size: 18px;
}

.business > .purify-one > .pack > .bottom > .bottom-body > div {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 580px 413px;
      grid-template-columns: 580px 413px;
  row-gap: 1px;
  -webkit-column-gap: 1px;
          column-gap: 1px;
}

.business > .purify-one > .pack > .bottom > .bottom-body > div > .left {
  display: -ms-grid;
  display: grid;
  row-gap: 1px;
}

.business > .purify-one > .pack > .bottom > .bottom-body > div > .left > .box {
  width: 100%;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 28px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.business > .purify-one > .pack > .bottom > .bottom-body > div > .right {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  -webkit-column-gap: 1px;
          column-gap: 1px;
}

.business > .purify-one > .pack > .bottom > .bottom-body > div > .right > .box {
  background-color: white;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.business > .purify-one > .pack > .bottom > .bottom-body > .body-first > .left {
  -ms-grid-rows: 53px 54px 157px 54px 54px;
      grid-template-rows: 53px 54px 157px 54px 54px;
}

.business > .purify-one > .pack > .bottom > .bottom-body > .body-second {
  margin-top: 1px;
}

.business > .purify-one > .pack > .bottom > .bottom-body > .body-second > .left {
  -ms-grid-rows: 54px 54px 54px 55px 55px 55px 113px;
      grid-template-rows: 54px 54px 54px 55px 55px 55px 113px;
}

.business > .purify-one > .pack > .bottom > .bottom-body > .body-last {
  margin-top: 1px;
  height: 93px;
}

.business > .purify-two {
  height: 1329px;
  width: 100%;
  padding-top: 155px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.business > .purify-two > .pack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.business > .purify-two > .pack > .category {
  width: 162px;
  height: 33px;
  background-color: #40ccc7;
  border-radius: 16.5px;
  color: white;
  font-size: 11px;
  font-family: 'gmarket';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.business > .purify-two > .pack > .title {
  font-size: 36px;
  color: var(--navy);
  margin-top: 19px;
  margin-bottom: 9px;
  font-family: "score";
}

.business > .purify-two > .pack > .title > b {
  font-family: "score";
  font-weight: bold;
}

.business > .purify-two > .pack > .sub {
  font-size: 20px;
  text-align: center;
}

.business > .purify-two > .pack > .system {
  width: 450px;
  margin-top: 51px;
  margin-bottom: 61px;
}

.business > .purify-two > .pack > .content-wrapper {
  padding: 1px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 640px;
  background-color: #e0e0e0;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 154px 350px 485px;
      grid-template-columns: 154px 350px 485px;
  -webkit-column-gap: 1px;
          column-gap: 1px;
}

.business > .purify-two > .pack > .content-wrapper .center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.business > .purify-two > .pack > .content-wrapper .col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: unset;
      -ms-flex-align: unset;
          align-items: unset;
  padding-left: 43px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.business > .purify-two > .pack > .content-wrapper .first {
  font-size: 20px;
}

.business > .purify-two > .pack > .content-wrapper > .category-wrapper > .head {
  background-color: #2e9aa1;
}

.business > .purify-two > .pack > .content-wrapper > .category-wrapper > .box {
  font-size: 20px;
  font-weight: 500;
}

.business > .purify-two > .pack > .content-wrapper > .water-wrapper > .head {
  background-color: #40ccc7;
}

.business > .purify-two > .pack > .content-wrapper > .water-wrapper > .box {
  font-size: 18px;
  line-height: 1.39;
  font-weight: 500;
  background-color: #e3f8f7;
}

.business > .purify-two > .pack > .content-wrapper > .water-wrapper > .box > b {
  color: #40ccc7;
}

.business > .purify-two > .pack > .content-wrapper > .water-wrapper > .box > div > b {
  color: #40ccc7;
}

.business > .purify-two > .pack > .content-wrapper > .water-wrapper .mint {
  color: #40ccc7;
}

.business > .purify-two > .pack > .content-wrapper > .lax-wrapper > .head {
  background-color: #c9c9c9;
}

.business > .purify-two > .pack > .content-wrapper > .lax-wrapper > .first {
  font-weight: 500;
  background-color: white;
}

.business > .purify-two > .pack > .content-wrapper > .lax-wrapper > .box {
  font-size: 18px;
  font-weight: 500;
}

.business > .purify-two > .pack > .content-wrapper > div {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 69px 60px 183px 139px 183px;
      grid-template-rows: 69px 60px 183px 139px 183px;
  row-gap: 1px;
}

.business > .purify-two > .pack > .content-wrapper > div > .head {
  font-size: 22px;
  font-weight: bold;
}

.business > .purify-two > .pack > .content-wrapper > div > .box {
  background-color: white;
}

@media screen and (max-width: 1024px) {
  .business > .banner {
    height: 298px;
    padding-top: 210px;
  }
  .business > .banner > .title {
    font-size: 30px;
  }
  .business > .building-care {
    height: 930px;
  }
  .business > .building-care > .pack {
    padding-top: 109px;
  }
  .business > .building-care > .pack > .left > .title {
    font-size: 25px;
    margin-top: 14px;
  }
  .business > .building-care > .pack > .right {
    row-gap: 23px;
  }
  .business > .building-care > .pack > .right > .card {
    width: 401px;
    height: 159px;
    padding-left: 33px;
  }
  .business > .building-care > .pack > .right > .card > img {
    width: 87px;
    height: 87px;
  }
  .business > .building-care > .pack > .right > .card > .content {
    margin-left: 22px;
  }
  .business > .building-care > .pack > .right > .card > .content > .title {
    font-size: 16px;
  }
  .business > .building-care > .pack > .right > .card > .content > .title > .back {
    height: 14px;
  }
  .business > .building-care > .pack > .right > .card > .content > .sub {
    margin-top: 8px;
  }
  .business > .purify-one {
    height: 1242px;
  }
  .business > .purify-one > .pack > .top {
    width: 100%;
  }
  .business > .purify-one > .pack > .top > .left {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
  }
  .business > .purify-one > .pack > .top > .left > .title {
    font-size: 25px;
  }
  .business > .purify-one > .pack > .top > .left > .sub {
    font-size: 15px;
  }
  .business > .purify-one > .pack > .bottom {
    margin-top: 34px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .business > .purify-one > .pack > .bottom > .bottom-head {
    -ms-grid-columns: 411px 293px;
        grid-template-columns: 411px 293px;
    height: 79px;
    font-size: 14px;
  }
  .business > .purify-one > .pack > .bottom > .bottom-body {
    height: 651px;
    font-size: 13px;
  }
  .business > .purify-one > .pack > .bottom > .bottom-body > div {
    -ms-grid-columns: 411px 293px;
        grid-template-columns: 411px 293px;
  }
  .business > .purify-one > .pack > .bottom > .bottom-body > .body-first > .left {
    -ms-grid-rows: 37px 38px 111px 38px 38px;
        grid-template-rows: 37px 38px 111px 38px 38px;
  }
  .business > .purify-one > .pack > .bottom > .bottom-body > .body-second > .left {
    -ms-grid-rows: 38px 38px 38px 39px 39px 39px 80px;
        grid-template-rows: 38px 38px 38px 39px 39px 39px 80px;
  }
  .business > .purify-one > .pack > .bottom > .bottom-body > .body-last {
    height: 66px;
  }
  .business > .purify-two {
    height: 1094px;
    padding-top: 170px;
  }
  .business > .purify-two > .pack > .title {
    font-size: 25px;
    margin: 12px 0;
  }
  .business > .purify-two > .pack > .sub {
    font-size: 15px;
  }
  .business > .purify-two > .pack > .system {
    width: 302px;
    margin-top: 48px;
    margin-bottom: 60px;
  }
  .business > .purify-two > .pack > .content-wrapper {
    height: 456px;
    -ms-grid-columns: 109px 247px 344px;
        grid-template-columns: 109px 247px 344px;
  }
  .business > .purify-two > .pack > .content-wrapper .col {
    padding-left: 0;
  }
  .business > .purify-two > .pack > .content-wrapper .first {
    font-size: 14px;
  }
  .business > .purify-two > .pack > .content-wrapper > .category-wrapper > .box {
    font-size: 14px;
  }
  .business > .purify-two > .pack > .content-wrapper > .water-wrapper > .box {
    font-size: 13px;
    text-align: center;
  }
  .business > .purify-two > .pack > .content-wrapper > .lax-wrapper > .box {
    font-size: 13px;
    text-align: center;
  }
  .business > .purify-two > .pack > .content-wrapper > div {
    -ms-grid-rows: 49px 42px 130px 99px 130px;
        grid-template-rows: 49px 42px 130px 99px 130px;
  }
  .business > .purify-two > .pack > .content-wrapper > div > .head {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .business > .banner {
    height: 209px;
    padding-top: 117px;
  }
  .business > .banner > .title {
    font-size: 23px;
  }
  .business > .building-care {
    height: 1093px;
  }
  .business > .building-care > .pack {
    padding-top: 87px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
  }
  .business > .building-care > .pack > .left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .business > .building-care > .pack > .left > .title {
    text-align: center;
  }
  .business > .building-care > .pack > .right {
    margin-top: 54px;
    row-gap: 20px;
    width: 100%;
  }
  .business > .building-care > .pack > .right > .card {
    width: 100%;
    height: 153px;
    padding-left: 14px;
  }
  .business > .building-care > .pack > .right > .card > img {
    width: 71px;
    height: 71px;
  }
  .business > .building-care > .pack > .right > .card > .content {
    margin-left: 15px;
  }
  .business > .building-care > .pack > .right > .card > .content > .title {
    font-size: 15px;
  }
  .business > .building-care > .pack > .right > .card > .content > .sub {
    font-size: 11px;
    margin-top: 13px;
  }
  .business > .purify-one {
    height: 2053px;
    padding-top: 149px;
  }
  .business > .purify-one > .pack > .top > .left > .title {
    margin: 17px 0;
  }
  .business > .purify-one > .pack > .top > .left > .sub {
    font-size: 13px;
    line-height: 1.62;
  }
  .business > .purify-one > .pack > .bottom > .mb-wrapper {
    display: -ms-grid;
    display: grid;
    row-gap: 57px;
  }
  .business > .purify-one > .pack > .bottom > .mb-wrapper > .box {
    display: -ms-grid;
    display: grid;
    row-gap: 1px;
    background-color: #e0e0e0;
  }
  .business > .purify-one > .pack > .bottom > .mb-wrapper > .box > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
  }
  .business > .purify-one > .pack > .bottom > .mb-wrapper > .box > .content {
    background-color: white;
    font-size: 13px;
  }
  .business > .purify-one > .pack > .bottom > .mb-wrapper > .box > .head {
    background-color: #a7e8e6;
    font-size: 14px;
    color: var(--navy);
    font-weight: bold;
  }
  .business > .purify-one > .pack > .bottom > .mb-wrapper > .box > .foot {
    background-color: white;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    -webkit-column-gap: 1px;
            column-gap: 1px;
  }
  .business > .purify-one > .pack > .bottom > .mb-wrapper > .box > .foot > .left {
    border-right: solid 1px #e0e0e0;
  }
  .business > .purify-one > .pack > .bottom > .mb-wrapper > .box > .foot > div {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 35px 74px;
        grid-template-rows: 35px 74px;
  }
  .business > .purify-one > .pack > .bottom > .mb-wrapper > .box > .foot > div > div {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    line-height: 0.7;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 13px;
  }
  .business > .purify-one > .pack > .bottom > .mb-wrapper > .box .grey {
    background-color: #e0e0e0;
    color: var(--navy);
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 13px;
  }
  .business > .purify-one > .pack > .bottom > .mb-wrapper > .one {
    -ms-grid-rows: 32px 57px 38px 129px 58px 40px 38px 110px;
        grid-template-rows: 32px 57px 38px 129px 58px 40px 38px 110px;
  }
  .business > .purify-one > .pack > .bottom > .mb-wrapper > .two {
    -ms-grid-rows: 32px 57px 38px 40px 58px 57px 61px 100px 38px 110px;
        grid-template-rows: 32px 57px 38px 40px 58px 57px 61px 100px 38px 110px;
  }
  .business > .purify-one > .pack > .bottom > .mb-wrapper > .three {
    -ms-grid-rows: 32px 57px 38px 110px;
        grid-template-rows: 32px 57px 38px 110px;
  }
}
