a {
  color: black;
}

#root {
  font-family: 'Noto Sans KR', sans-serif;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  --white: #fff;
  --brown-grey: #8d8d8d;
  --very-light-pink: #e0e0e0;
  --black: #000;
  --dark-sky-blue: #46bad1;
  --white: #f8f8f8;
  --very-light-pink: #c9c9c9;
  --seafoam-blue: #76c7d3;
  --navy: #001a3d;
  --shamrock: #00bb68;
  --brownish-grey: #707070;
  --aqua-marine: #40ccc7;
}

* {
  font-family: 'Noto Sans KR', sans-serif;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  border: none;
  scrollbar-width: none;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: none;
}

* [contenteditable=true]:empty:before {
  content: attr(placeholder);
  color: #bfbfbf;
}

* [placeholder]:empty:focus::before {
  content: "";
}

u {
  text-decoration: underline;
}

html,
body {
  width: 100%;
  height: 100%;
}

body::-webkit-scrollbar {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
  width: 100%;
}

input[type=number] {
  -moz-appearance: textfield;
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

.none {
  display: none !important;
}

input,
p,
textarea {
  border: 0;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-appearance: none;
  resize: none;
  font-family: 'Noto Sans KR', sans-serif;
}

input::-webkit-input-placeholder,
p::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-family: 'Noto Sans KR', sans-serif;
  color: #c6c6c6;
}

input:-ms-input-placeholder,
p:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  font-family: 'Noto Sans KR', sans-serif;
  color: #c6c6c6;
}

input::-ms-input-placeholder,
p::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  font-family: 'Noto Sans KR', sans-serif;
  color: #c6c6c6;
}

input::placeholder,
p::placeholder,
textarea::placeholder {
  font-family: 'Noto Sans KR', sans-serif;
  color: #c6c6c6;
}

input:focus,
p:focus,
textarea:focus {
  outline: none;
}

.pack {
  width: 993px;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .pack {
    width: 704px;
  }
}

@media screen and (max-width: 767px) {
  .pack {
    width: 320px;
  }
}
