.business {
  &>.banner {
    height: 370px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: image-set(url('/public/assets/business/banner.png') 1x, url('/public/assets/business/banner@2x.png') 2x, url('/public/assets/business/banner@3x.png') 3x);
    text-align: center;
    padding-top: 234px;
    box-sizing: border-box;

    &>.title {
      font-size: 39px;
      font-weight: bold;
      color: white;
    }
  }

  &>.building-care {
    width: 100%;
    height: 1093px;
    background-color: #f8f8f8;

    &>.pack {
      display: flex;
      padding-top: 97px;
      box-sizing: border-box;
      justify-content: space-between;

      &>.left {
        &>.category {
          width: 147px;
          height: 33px;
          background-color: #40ccc7;
          font-weight: bold;
          border-radius: 16.5px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 11px;
          font-family: 'gmarket';
        }

        &>.title {
          margin-top: 19px;
          font-size: 35px;
          font-weight: 500;
          line-height: 1.26;
          color: var(--navy);
          font-family: "score";

          &>b {
            font-family: "score";
            font-weight: bold;
          }
        }
      }

      &>.right {
        display: grid;
        row-gap: 20px;

        &>.card {
          width: 524px;
          height: 198px;
          background-color: white;
          display: flex;
          align-items: center;
          border-radius: 20px;
          padding-left: 51px;
          box-sizing: border-box;

          &>img {
            width: 120px;
            height: 120px;
          }

          &>.content {
            margin-left: 43px;

            &>.title {
              font-size: 25px;
              position: relative;

              &>.tl {
                z-index: 10;
                position: relative;
              }

              &>.back {
                position: absolute;
                width: 110%;
                height: 21px;
                background-color: rgb(165, 230, 226);
                left: -5%;
                bottom: -3px;
                z-index: 5;
              }
            }

            &>.sub {
              margin-top: 12px;
              font-size: 13px;
              font-weight: 500;
              line-height: 1.54;
              white-space: pre-line;
            }
          }
        }
      }
    }
  }

  &>.purify-one {
    width: 100%;
    height: 1596px;
    background-color: #f8f8f8;
    padding-top: 162px;
    box-sizing: border-box;

    &>.pack {
      &>.top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &>.left {
          &>.category {
            width: 162px;
            height: 33px;
            border-radius: 16.5px;
            color: white;
            background-color: #40ccc7;
            font-size: 11px;
            font-weight: bold;
            font-family: 'gmarket';
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &>.title {
            font-size: 36px;
            color: var(--navy);
            margin: 13px 0;
            font-family: "score";

            &>b {
              font-family: "score";
              font-weight: bold;
            }
          }

          &>.sub {
            font-size: 20px;
          }
        }

        &>img {
          width: 167px;
          height: 167px;
        }
      }

      &>.bottom {
        margin-top: 85px;
        width: 100%;

        &>.bottom-head {
          width: 100%;
          height: 110px;
          display: grid;
          grid-template-columns: 580px 413px;
          column-gap: 1px;
          background-color: white;
          font-size: 22px;
          font-weight: bold;
          color: var(--navy);

          &>.left {
            background-color: #a7e8e6;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &>.right {
            display: grid;
            height: 100%;
            row-gap: 1px;

            &>.right-top {
              background-color: #e0e0e0;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &>.right-bottom {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              column-gap: 1px;

              &>.month {
                background-color: #e0e0e0;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }

        &>.bottom-body {
          background-color: #e0e0e0;
          height: 917px;
          font-size: 18px;

          &>div {
            display: grid;
            grid-template-columns: 580px 413px;
            row-gap: 1px;
            column-gap: 1px;

            &>.left {
              display: grid;
              row-gap: 1px;

              &>.box {
                width: 100%;
                background-color: white;
                display: flex;
                align-items: center;
                padding-left: 28px;
                box-sizing: border-box;
              }
            }

            &>.right {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              column-gap: 1px;

              &>.box {
                background-color: white;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;

              }
            }
          }

          &>.body-first {
            &>.left {
              grid-template-rows: 53px 54px 157px 54px 54px;
            }
          }

          &>.body-second {
            margin-top: 1px;

            &>.left {
              grid-template-rows: 54px 54px 54px 55px 55px 55px 113px;
            }

          }

          &>.body-last {
            margin-top: 1px;
            height: 93px;
          }
        }
      }
    }
  }

  &>.purify-two {
    height: 1329px;
    width: 100%;
    padding-top: 155px;
    box-sizing: border-box;

    &>.pack {
      display: flex;
      flex-direction: column;
      align-items: center;

      &>.category {
        width: 162px;
        height: 33px;
        background-color: #40ccc7;
        border-radius: 16.5px;
        color: white;
        font-size: 11px;
        font-family: 'gmarket';
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &>.title {
        font-size: 36px;
        color: var(--navy);
        margin-top: 19px;
        margin-bottom: 9px;
        font-family: "score";

        &>b {
          font-family: "score";
          font-weight: bold;
        }
      }

      &>.sub {
        font-size: 20px;
        text-align: center;
      }

      &>.system {
        width: 450px;
        margin-top: 51px;
        margin-bottom: 61px;
      }

      &>.content-wrapper {
        padding: 1px;
        box-sizing: border-box;
        width: 100%;
        height: 640px;
        background-color: #e0e0e0;
        display: grid;
        grid-template-columns: 154px 350px 485px;
        column-gap: 1px;

        .center {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .col {
          flex-direction: column;
          align-items: unset;
          padding-left: 43px;
          box-sizing: border-box;
        }

        .first {
          font-size: 20px;
        }

        &>.category-wrapper {
          &>.head {
            background-color: #2e9aa1;
          }

          &>.box {
            font-size: 20px;
            font-weight: 500;
          }
        }

        &>.water-wrapper {
          &>.head {
            background-color: #40ccc7;
          }

          &>.box {
            font-size: 18px;
            line-height: 1.39;
            font-weight: 500;
            background-color: #e3f8f7;

            &>b {
              color: #40ccc7;
            }

            &>div>b {
              color: #40ccc7;
            }
          }

          .mint {
            color: #40ccc7;
          }
        }

        &>.lax-wrapper {
          &>.head {
            background-color: #c9c9c9;
          }

          &>.first {
            font-weight: 500;
            background-color: white;
          }

          &>.box {
            font-size: 18px;
            font-weight: 500;
          }

        }

        &>div {
          display: grid;
          grid-template-rows: 69px 60px 183px 139px 183px;
          row-gap: 1px;

          &>.head {
            font-size: 22px;
            font-weight: bold;
          }

          &>.box {
            background-color: white;
          }

        }
      }
    }
  }
}

@media screen and (max-width : 1024px) {
  .business {
    &>.banner {
      height: 298px;
      padding-top: 210px;

      &>.title {
        font-size: 30px;
      }
    }

    &>.building-care {
      height: 930px;

      &>.pack {
        padding-top: 109px;

        &>.left {
          &>.title {
            font-size: 25px;
            margin-top: 14px;
          }
        }

        &>.right {
          row-gap: 23px;

          &>.card {
            width: 401px;
            height: 159px;
            padding-left: 33px;

            &>img {
              width: 87px;
              height: 87px;
            }

            &>.content {
              margin-left: 22px;

              &>.title {
                font-size: 16px;

                &>.back {
                  height: 14px;

                }
              }

              &>.sub {
                margin-top: 8px;
              }

            }
          }
        }
      }
    }

    &>.purify-one {
      height: 1242px;

      &>.pack {
        &>.top {
          width: 100%;

          &>.left {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            &>.title {
              font-size: 25px;
            }

            &>.sub {
              font-size: 15px;
            }
          }
        }

        &>.bottom {
          margin-top: 34px;
          display: flex;
          flex-direction: column;

          &>.bottom-head {
            grid-template-columns: 411px 293px;
            height: 79px;
            font-size: 14px;
          }

          &>.bottom-body {
            height: 651px;
            font-size: 13px;

            &>div {
              grid-template-columns: 411px 293px;
            }

            &>.body-first {
              &>.left {
                grid-template-rows: 37px 38px 111px 38px 38px;
              }
            }

            &>.body-second {
              &>.left {
                grid-template-rows: 38px 38px 38px 39px 39px 39px 80px;
              }
            }

            &>.body-last {
              height: 66px;
            }
          }
        }
      }
    }

    &>.purify-two {
      height: 1094px;
      padding-top: 170px;

      &>.pack {
        &>.title {
          font-size: 25px;
          margin: 12px 0;
        }

        &>.sub {
          font-size: 15px;
        }

        &>.system {
          width: 302px;
          margin-top: 48px;
          margin-bottom: 60px;
        }

        &>.content-wrapper {
          height: 456px;
          grid-template-columns: 109px 247px 344px;

          .col {
            padding-left: 0;
          }

          .first {
            font-size: 14px;
          }

          &>.category-wrapper {
            &>.box {
              font-size: 14px;
            }
          }

          &>.water-wrapper {
            &>.box {
              font-size: 13px;
              text-align: center;
            }
          }

          &>.lax-wrapper {
            &>.box {
              font-size: 13px;
              text-align: center;
            }
          }

          &>div {
            grid-template-rows: 49px 42px 130px 99px 130px;

            &>.head {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width : 767px) {
  .business {
    &>.banner {
      height: 209px;
      padding-top: 117px;

      &>.title {
        font-size: 23px;
      }
    }

    &>.building-care {
      height: 1093px;

      &>.pack {
        padding-top: 87px;
        flex-direction: column;
        align-items: center;
        justify-content: unset;

        &>.left {
          display: flex;
          flex-direction: column;
          align-items: center;

          &>.title {
            text-align: center;
          }
        }

        &>.right {
          margin-top: 54px;
          row-gap: 20px;
          width: 100%;

          &>.card {
            width: 100%;
            height: 153px;
            padding-left: 14px;

            &>img {
              width: 71px;
              height: 71px;
            }

            &>.content {
              margin-left: 15px;

              &>.title {
                font-size: 15px;
              }

              &>.sub {
                font-size: 11px;
                margin-top: 13px;
              }
            }
          }
        }
      }
    }

    &>.purify-one {
      height: 2053px;
      padding-top: 149px;

      &>.pack {
        &>.top {
          &>.left {
            &>.title {
              margin: 17px 0;
            }

            &>.sub {
              font-size: 13px;
              line-height: 1.62;
            }
          }
        }

        &>.bottom {
          &>.mb-wrapper {
            display: grid;
            row-gap: 57px;

            &>.box {
              display: grid;
              row-gap: 1px;
              background-color: #e0e0e0;

              &>div {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
              }

              &>.content {
                background-color: white;
                font-size: 13px;
              }

              &>.head {
                background-color: #a7e8e6;
                font-size: 14px;
                color: var(--navy);
                font-weight: bold;
              }

              &>.foot {
                background-color: white;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 1px;

                &>.left {
                  border-right: solid 1px #e0e0e0;
                }

                &>div {
                  display: grid;
                  grid-template-rows: 35px 74px;

                  &>div {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    line-height: 0.7;
                    align-items: center;
                    justify-content: center;
                    font-size: 13px;
                  }
                }
              }

              .grey {
                background-color: #e0e0e0;
                color: var(--navy);
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px;
              }
            }

            &>.one {
              grid-template-rows: 32px 57px 38px 129px 58px 40px 38px 110px;
            }

            &>.two {
              grid-template-rows: 32px 57px 38px 40px 58px 57px 61px 100px 38px 110px;
            }

            &>.three {
              grid-template-rows: 32px 57px 38px 110px;
            }
          }
        }
      }
    }

  }
}