.summary {
  height: 741px;
  width: 100%;
  background-color: white;
}

.summary > .pack {
  height: 100%;
  position: relative;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.summary > .pack > .back {
  left: 400px;
  top: 305px;
  position: absolute;
  height: 306px;
  width: 100vw;
  background-color: var(--navy);
  z-index: -1;
}

.summary > .pack > .backimg {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  top: 93px;
  z-index: -2;
  left: -200px;
}

.summary > .pack > .content-wrapper {
  color: white;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  padding-left: 52.8px;
  padding-top: 346px;
}

.summary > .pack > .content-wrapper > .title {
  font-size: 35px;
  font-weight: 500;
  line-height: 1.26;
  font-family: "score";
}

.summary > .pack > .content-wrapper > .title > b {
  font-family: "score";
  font-weight: bold;
}

.summary > .pack > .content-wrapper > .line {
  width: 24px;
  height: 4px;
  background-color: white;
  margin-top: 18px;
  margin-bottom: 9px;
}

.summary > .pack > .content-wrapper > .sub {
  line-height: 1.59;
  font-size: 17px;
}

.hoodsummary {
  height: 747px;
}

.hoodsummary > .pack > .back {
  height: 267px;
  top: 328px;
}

.hoodsummary > .pack > .backimg {
  top: 73px;
}

.hoodsummary > .pack > .content-wrapper {
  padding-top: 369px;
}

.airsummary {
  height: 715px;
}

.airsummary > .pack > .back {
  height: 270px;
}

.airsummary > .pack > .backimg {
  top: 83px;
}

.filtersummary {
  height: 715px;
}

.filtersummary > .pack > .back {
  height: 287px;
}

.filtersummary > .pack > .backimg {
  top: 88px;
}

.matsummary {
  height: 741px;
}

.matsummary > .pack > .back {
  height: 342px;
}

.homecleansummary > .pack > .back {
  background-color: var(--shamrock);
}

.businesssummary > .pack > .back {
  background-color: #40ccc7;
}

.businesssummary > .pack > .content-wrapper {
  color: var(--navy);
}

.businesssummary > .pack > .content-wrapper > .line {
  background-color: var(--navy);
}

.birdsummary > .pack > .back {
  height: 319px;
}

.birdsummary > .pack > .backimg {
  top: 106px;
}

.bugsummary {
  height: 879px;
}

.bugsummary > .pack > .back {
  height: 328px;
}

.bugsummary > .pack > .backimg {
  top: 91px;
}

.bugsummary > .pack > .content-wrapper {
  padding-top: 365px;
}

.buildingsummary > .pack > .back {
  height: 495px;
  top: 172px;
}

.buildingsummary > .pack > .backimg {
  top: 66px;
}

.buildingsummary > .pack > .content-wrapper {
  padding-top: 231px;
}

.buildingsummary > .pack > .content-wrapper > .category-wrapper {
  margin-top: 38px;
}

.buildingsummary > .pack > .content-wrapper > .category-wrapper > div {
  -webkit-column-gap: 12px;
          column-gap: 12px;
}

.buildingsummary > .pack > .content-wrapper > .category-wrapper > div > div {
  height: 41px;
  background-color: white;
  border-radius: 20.5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: var(--navy);
}

.buildingsummary > .pack > .content-wrapper > .category-wrapper > .one {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (102px)[3];
      grid-template-columns: repeat(3, 102px);
  margin-bottom: 12px;
}

.buildingsummary > .pack > .content-wrapper > .category-wrapper > .two {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (102px)[4];
      grid-template-columns: repeat(4, 102px);
}

.aircaresummary {
  height: 706px;
}

.aircaresummary > .pack > .back {
  top: 204px;
  height: 287px;
}

.aircaresummary > .pack > .content-wrapper {
  padding-top: 263px;
  padding-left: 0;
}

.aircaresummary > .pack > .content-wrapper > .sub {
  font-size: 16px;
}

.aircaresummary > .pack > .content-wrapper > .line {
  margin-top: 20.5px;
  margin-bottom: 17.5px;
}

.aircaresummary > .pack > .backimg {
  top: 83px;
}

.airfiltersummary {
  height: 763px;
}

.airfiltersummary > .pack > .back {
  height: 299px;
  top: 188px;
}

.airfiltersummary > .pack > .backimg {
  top: 69px;
}

.airfiltersummary > .pack > .content-wrapper {
  padding-top: 236px;
  padding-left: 0;
}

.airfiltersummary > .pack > .content-wrapper > .line {
  margin-top: 17.5px;
  margin-bottom: 20.5px;
}

.purifysummary {
  height: 744px;
}

.purifysummary > .pack > .back {
  height: 187px;
  top: 291px;
}

.purifysummary > .pack > .backimg {
  top: 83px;
}

.purifysummary > .pack > .content-wrapper {
  padding-left: 0;
  padding-top: 339px;
}

@media screen and (max-width: 1024px) {
  .summary {
    height: 471px;
  }
  .summary > .pack > .back {
    height: 216px;
    left: 200px;
    top: 175px;
  }
  .summary > .pack > .backimg {
    width: 384px;
    height: 255px;
    top: 28px;
    left: -63px;
  }
  .summary > .pack > .content-wrapper {
    padding-top: 209px;
    padding-left: 20px;
  }
  .summary > .pack > .content-wrapper > .title {
    font-size: 23px;
  }
  .summary > .pack > .content-wrapper > .line {
    width: 13.2px;
    margin: 9px 0;
  }
  .summary > .pack > .content-wrapper > .sub {
    font-size: 14px;
  }
  .washsummary {
    height: 522px;
  }
  .washsummary > .pack > .back {
    height: 246px;
    top: 191px;
  }
  .washsummary > .pack > .backimg {
    width: 384px;
    top: 42px;
  }
  .washsummary > .pack > .content-wrapper {
    padding-top: 226px;
  }
  .hoodsummary {
    height: 545px;
  }
  .hoodsummary > .pack > .back {
    height: 203px;
    top: 191px;
  }
  .hoodsummary > .pack > .backimg {
    width: 406px;
    top: 41px;
  }
  .hoodsummary > .pack > .content-wrapper {
    padding-top: 226px;
  }
  .airsummary {
    height: 545px;
  }
  .airsummary > .pack > .back {
    height: 203px;
    top: 213px;
  }
  .airsummary > .pack > .backimg {
    width: 406px;
    top: 41px;
  }
  .airsummary > .pack > .content-wrapper {
    padding-top: 248px;
  }
  .buildingsummary {
    height: 644px;
  }
  .buildingsummary > .pack > .back {
    height: 389px;
    top: 147px;
  }
  .buildingsummary > .pack > .backimg {
    width: 404px;
    top: 59px;
  }
  .buildingsummary > .pack > .content-wrapper {
    padding-top: 186px;
  }
  .buildingsummary > .pack > .content-wrapper > .line {
    width: 23.5px;
    margin-top: 21px;
    margin-bottom: 13px;
  }
  .buildingsummary > .pack > .content-wrapper > .category-wrapper {
    margin-top: 23px;
  }
  .buildingsummary > .pack > .content-wrapper > .category-wrapper > div > div {
    height: 30px;
    font-size: 13px;
  }
  .buildingsummary > .pack > .content-wrapper > .category-wrapper > .one {
    -ms-grid-columns: (83px)[3];
        grid-template-columns: repeat(3, 83px);
    -webkit-column-gap: 6px;
            column-gap: 6px;
  }
  .buildingsummary > .pack > .content-wrapper > .category-wrapper > .two {
    -ms-grid-columns: (83px)[4];
        grid-template-columns: repeat(4, 83px);
    -webkit-column-gap: 6px;
            column-gap: 6px;
  }
  .filtersummary {
    height: 545px;
  }
  .filtersummary > .pack > .back {
    height: 223px;
    top: 213px;
  }
  .filtersummary > .pack > .backimg {
    width: 406px;
    top: 41px;
  }
  .filtersummary > .pack > .content-wrapper {
    padding-top: 248px;
  }
  .matsummary {
    height: 593px;
  }
  .matsummary > .pack > .back {
    height: 258px;
    top: 252px;
  }
  .matsummary > .pack > .backimg {
    width: 482px;
    top: 80px;
  }
  .matsummary > .pack > .content-wrapper {
    padding-top: 286px;
  }
  .bugsummary {
    height: 593px;
  }
  .bugsummary > .pack > .back {
    top: 252px;
    height: 248px;
  }
  .bugsummary > .pack > .backimg {
    width: 487px;
    top: 74px;
  }
  .bugsummary > .pack > .content-wrapper {
    padding-top: 285px;
  }
  .birdsummary {
    height: 593px;
  }
  .birdsummary > .pack > .back {
    height: 238px;
    top: 252px;
  }
  .birdsummary > .pack > .backimg {
    width: 478px;
    top: 80px;
  }
  .birdsummary > .pack > .content-wrapper {
    padding-top: 286px;
  }
  .purifysummary {
    height: 515px;
  }
  .purifysummary > .pack > .back {
    height: 135px;
    left: 230px;
  }
}

@media screen and (max-width: 767px) {
  .summary {
    height: 613px;
  }
  .summary > .pack > .backimg {
    width: 319px;
    height: 212px;
    top: 55px;
    left: -40px;
  }
  .summary > .pack > .back {
    top: 231px;
    height: 241px;
    left: 35px;
  }
  .summary > .pack > .content-wrapper {
    padding-top: 267px;
    padding-left: 0;
  }
  .summary > .pack > .content-wrapper > .title {
    font-size: 18px;
  }
  .summary > .pack > .content-wrapper > .line {
    width: 12.5px;
    margin: 11px 0;
  }
  .summary > .pack > .content-wrapper > .sub {
    font-size: 13px;
  }
  .matsummary {
    height: 652px;
  }
  .matsummary > .pack > .back {
    height: 247px;
    top: 241px;
    left: 10px;
  }
  .bugsummary {
    height: 662px;
  }
  .bugsummary > .pack > .back {
    height: 225px;
    top: 241px;
    left: 10px;
  }
  .jointsummary {
    height: 652px;
  }
  .jointsummary > .pack > .back {
    height: 188px;
    left: 10px;
  }
  .jointsummary > .pack > .backimg {
    width: 327px;
  }
  .washsummary {
    height: 652px;
  }
  .washsummary > .pack > .back {
    height: 241px;
    top: 231px;
    left: 10px;
  }
  .washsummary > .pack > .backimg {
    width: 319px;
    top: 55px;
  }
  .hoodsummary {
    height: 553px;
  }
  .hoodsummary > .pack > .back {
    height: 200px;
    left: 10px;
  }
  .hoodsummary > .pack > .backimg {
    top: 64px;
  }
  .airsummary {
    height: 553px;
  }
  .airsummary > .pack > .back {
    height: 200px;
    top: 231px;
    left: 10px;
  }
  .airsummary > .pack > .backimg {
    top: 64px;
  }
  .airsummary > .pack > .content-wrapper {
    padding-top: 265px;
  }
  .birdsummary {
    height: 652px;
  }
  .birdsummary > .pack > .back {
    height: 225px;
    top: 241px;
    left: 10px;
  }
  .filtersummary {
    height: 553px;
  }
  .filtersummary > .pack > .back {
    height: 216px;
    left: 10px;
  }
  .filtersummary > .pack > .backimg {
    top: 64px;
  }
  .buildingsummary {
    height: 763px;
  }
  .buildingsummary > .pack > .backimg {
    width: 325px;
    top: 69px;
  }
  .buildingsummary > .pack > .back {
    height: 360px;
    left: 0;
  }
  .buildingsummary > .pack > .content-wrapper > .category-wrapper {
    margin-top: 24px;
  }
  .buildingsummary > .pack > .content-wrapper > .category-wrapper > div {
    -webkit-column-gap: 8px;
            column-gap: 8px;
  }
  .buildingsummary > .pack > .content-wrapper > .category-wrapper > div > div {
    height: 27px;
    font-size: 12px;
  }
  .buildingsummary > .pack > .content-wrapper > .category-wrapper > .one {
    -ms-grid-columns: (71px)[3];
        grid-template-columns: repeat(3, 71px);
  }
  .buildingsummary > .pack > .content-wrapper > .category-wrapper > .two {
    -ms-grid-columns: (71px)[4];
        grid-template-columns: repeat(4, 71px);
  }
  .aircaresummary > .pack > .backimg,
  .airfiltersummary > .pack > .backimg {
    width: 325px;
    top: 69px;
    height: 217px;
  }
  .aircaresummary > .pack > .back,
  .airfiltersummary > .pack > .back {
    height: 229px;
    top: 286px;
    left: 0;
  }
  .aircaresummary > .pack > .content-wrapper,
  .airfiltersummary > .pack > .content-wrapper {
    padding-top: 319px;
  }
  .purifysummary {
    height: 603px;
  }
  .purifysummary > .pack > .back {
    height: 126px;
    left: 0;
    top: 286px;
  }
  .purifysummary > .pack > .backimg {
    width: 325px;
    top: 75px;
  }
  .purifysummary > .pack > .content-wrapper {
    padding-top: 317px;
    line-height: 1.5;
  }
  .purifysummary > .pack > .content-wrapper > .title {
    font-size: 20px;
  }
}
