.product {
  width: 100%;

  &>.banner {
    height: 370px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: image-set(url('/public/assets/product/banner.png') 1x, url('/public/assets/product/banner@2x.png') 2x, url('/public/assets/product/banner@3x.png') 3x);
    text-align: center;
    padding-top: 234px;
    box-sizing: border-box;

    &>.title {
      font-size: 39px;
      font-weight: bold;
      color: white;
    }
  }

  &>.aircon {

    &>.section8 {
      width: 100%;
      height: 1200px;
      background-color: #f8f8f8;
      padding-top: 120px;
      box-sizing: border-box;

      &>.pack {
        &>.title {
          font-size: 35px;
          font-weight: bold;
          line-height: 1.37;
          color: var(--navy);
          text-align: center;
          font-family: "score";

          &>b {
            font-family: "score";
            font-weight: bold;
          }
        }

        &>.list {
          display: grid;
          margin-top: 57px;
          row-gap: 48px;

          &>.card {
            display: flex;
            align-items: center;

            &>img {
              width: fit-content;
              margin-right: 37px;
            }

            &>.content {
              width: 561px;

              &>.title {
                width: 100%;
                height: 53px;
                background-color: var(--dark-sky-blue);
                padding-left: 34px;
                box-sizing: border-box;
                font-size: 22px;
                font-weight: bold;
                color: white;
                display: flex;
                align-items: center;
              }

              &>.width-line {
                width: 100%;
                height: 1px;
                background-color: #e0e0e0;
              }

              &>.subject {
                height: 55.5px;
                width: 100%;
                background-color: white;
                display: flex;
                align-items: center;
                padding-left: 31px;
                box-sizing: border-box;
                font-weight: 500;

                b {
                  font-size: 18px;
                  color: var(--dark-sky-blue);
                  margin-right: 15px;
                  white-space: nowrap;
                }
              }

              &>.three {
                display: flex;
                padding: unset;

                &>.left {
                  width: 249px;
                  padding-left: 30.5px;

                }

                &>.right {
                  width: 198px;
                  padding-left: 23.1px;
                }

                &>.pax {
                  width: 114px;
                  padding-left: 14.9px;
                }

                &>div {
                  height: 100%;
                  display: flex;
                  align-items: center;
                  box-sizing: border-box;
                }

                &>.left,
                .right {
                  border-right: solid 1px #e0e0e0;
                }
              }

              &>.fix {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                padding: unset;

                &>div {
                  height: 100%;
                  display: flex;
                  align-items: center;
                  box-sizing: border-box;
                }

                &>.left {
                  padding-left: 31px;

                  border-right: solid 1px #e0e0e0;
                }

                &>.right {
                  padding-left: 15px;

                }
              }

              &>.two {
                display: flex;

                &>.left {
                  width: 219px;
                  border-right: solid 1px #e0e0e0;
                  height: 100%;
                  display: flex;
                  align-items: center;
                }

                &>.right {
                  padding-left: 30.5px;
                  box-sizing: border-box;
                }

              }

              &>.third {
                display: grid;
                width: 100%;
                font-size: 18px;
                grid-template-columns: repeat(4, 1fr);
                padding: unset;

                &>div {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                }

                &>div:nth-child(n+2) {
                  border-left: solid 1px #e0e0e0;
                }

              }
            }
          }
        }
      }
    }

  }

}

@media screen and (max-width : 1024px) {
  .product {
    &>.banner {
      height: 298px;
      padding-top: 210px;

      &>.title {
        font-size: 30px;
      }
    }

    &>.aircon {
      &>.section8 {
        height: 1029px;
        padding-top: 124px;

        &>.pack {

          &>.title {
            font-size: 25px;
          }

          &>.list {
            margin-top: 52px;
            row-gap: 48px;

            &>.card {
              &>img {
                margin-right: 25px;
                width: 169px;
              }

              &>.content {
                width: 486px;

                &>.title {
                  height: 50px;
                  padding-left: 38px;
                  font-size: 18px;
                }

                &>.subject {
                  height: 50px;
                  padding-left: 40px;

                  b {
                    font-size: 16px;
                    margin-right: 40px;
                  }

                  span {
                    font-size: 16px;
                  }
                }

                &>.fix {
                  padding: unset;

                  &>.left {
                    padding-left: 40px;
                  }
                }

                &>.three {
                  padding: unset;

                  &>.left {
                    width: 206.7px;
                    padding-left: 42px;

                    &>b {
                      margin-right: 19.2px;
                    }
                  }

                  &>.right {
                    width: 176.8px;
                    padding-left: 16.8px;

                    &>b {
                      margin-right: 8.2px;
                    }
                  }

                  &>.pax {
                    width: 102.5px;
                    padding-left: 11.2px;
                  }
                }

                &>.lux {
                  &>b {
                    margin-right: 22px;
                  }
                }

                &>.two {

                  &>.left {
                    width: 180px;

                    &>b {
                      margin-right: 19px;
                    }
                  }

                  &>.right {
                    width: 270px;
                    padding-left: 23px;

                    &>b {
                      margin-right: 19px;
                    }
                  }
                }

                &>.third {
                  padding-left: 0;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width : 767px) {
  .product {
    &>.aircon {
      &>.section8 {
        height: 1789px;
        padding-top: 155px;

        &>.pack {
          &>.list {
            margin-top: 56px;
            row-gap: 70px;

            &>.card {
              flex-direction: column;
              width: 320px;

              &>img {
                width: 156px;
                margin-right: unset;
                margin-bottom: 30px;
              }

              &>.content {
                width: 100%;

                &>.title {
                  padding: unset;
                  justify-content: center;
                }

                &>.subject {
                  height: 53px;
                  padding-left: 24px;
                }

                &>.one {
                  padding-left: 32px;
                }

                &>.fix {
                  padding: unset;
                  grid-template-columns: 204.5px 115.5px;

                  &>.left {
                    padding-left: 32px;
                  }

                  &>.right {
                    padding-left: 33.5px;
                  }

                }

                &>.three {
                  padding: unset;
                  grid-template-columns: 100%;
                  flex-wrap: wrap;
                  height: 106px;

                  &>div {
                    height: 53px;
                  }

                  &>.left {
                    width: 320px;
                    border-right: unset;
                    border-bottom: solid 1px #e0e0e0;
                    padding-left: 22.5px;

                  }

                  &>.right {
                    padding-left: 22.5px;
                    width: 204.5px;
                  }

                  &>.pax {
                    width: 115.5px;
                    padding-left: 18px;
                  }
                }

                .lux {
                  height: 78px;
                  padding-left: 22.5px;
                }

                .short,
                .lux {

                  &>b {
                    margin-right: 31px !important;
                  }
                }

                &>.two {
                  flex-direction: column;
                  height: 107px;
                  display: grid;
                  row-gap: 1px;
                  background-color: #e0e0e0;
                  padding: 0;
                  grid-template-rows: 53px 53px;

                  &>.left {
                    width: 100%;
                    background-color: white;
                    padding-left: 22.5px;
                    box-sizing: border-box;
                    border-right: unset;

                  }

                  &>.right {
                    padding-left: 22.5px;
                    box-sizing: border-box;
                    background-color: white;
                    width: 100%;
                    height: 100%;
                    align-items: center;
                    display: flex;
                  }
                }

                &>.third {
                  padding: unset;
                }
              }
            }
          }
        }
      }
    }
  }
}