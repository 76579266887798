.slogan {
  width: 100%;
  height: 240px;
  background-color: var(--navy);
}

.slogan > .pack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  padding-top: 29px;
  padding-bottom: 38px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.slogan > .pack > img {
  width: 94px;
  height: 91px;
}

.slogan > .pack > .left {
  -ms-flex-item-align: end;
      align-self: flex-end;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.slogan > .pack > .title {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.43;
  margin-left: 25px;
  margin-right: 17px;
  color: white;
  white-space: nowrap;
  font-family: "score";
}

.slogan > .pack > .title > b {
  color: var(--dark-sky-blue);
  font-family: "score";
  font-weight: bold;
}

.slogan > .pack > .right {
  -ms-flex-item-align: start;
      align-self: flex-start;
}

.airslogan > .pack > .title {
  margin: 0 50px;
}

.homecleanslogan {
  background-color: var(--shamrock);
}

.homecleanslogan > .pack > img {
  width: 62.8px;
  height: 59.5px;
}

.homecleanslogan > .pack > .title > b {
  color: white;
}

.businessslogan {
  background-color: #40ccc7;
}

.businessslogan > .pack > img {
  width: 62.8px;
  height: 59.5px;
}

.businessslogan > .pack > .title {
  color: var(--navy);
  margin-right: 33px;
}

.businessslogan > .pack > .title > b {
  color: var(--navy);
}

.matslogan > .pack > .title {
  margin: 0 35px;
}

.filterslogan > .pack > .title {
  margin: 0 65px;
}

.airfilterslogan > .pack > .title {
  margin: 0 72.2px 0 34px;
}

.purifyslogan > .pack > .title {
  margin: 0 46.2px 0 31px;
}

@media screen and (max-width: 1024px) {
  .slogan {
    height: 252px;
  }
  .slogan > .pack {
    padding: 55.5px 0;
  }
  .slogan > .pack > img {
    width: 55px;
    height: 50px;
  }
  .slogan > .pack > .title {
    font-size: 25px;
    margin: 0 27px;
  }
  .washslogan > .pack > .title {
    margin: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .slogan {
    height: 220px;
  }
  .slogan > .pack {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    padding: 33px 0 23px 0;
  }
  .slogan > .pack > .left {
    -ms-flex-item-align: start;
        align-self: flex-start;
    -webkit-transform: scale(-1);
            transform: scale(-1);
  }
  .slogan > .pack > .title {
    font-size: 16px;
  }
  .slogan > .pack > .right {
    -ms-flex-item-align: end;
        align-self: flex-end;
  }
  .businessslogan > .pack > img {
    width: 35px;
  }
  .businessslogan > .pack > .title {
    margin: 0;
  }
  .homecleanslogan > .pack > img {
    width: 35px;
  }
  .purifyslogan {
    height: 289px;
  }
  .washslogan > .pack > .title,
  .hoodslogan > .pack > .title,
  .airslogan > .pack > .title,
  .filterslogan > .pack > .title,
  .birdslogan > .pack > .title,
  .bugslogan > .pack > .title {
    margin: 0;
  }
}
