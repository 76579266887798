footer{
    height: 522px;
    width: 100%;
    background-color: #3c3c3c;
    font-size: 13px;
    color: white;
    &>.pack{
      padding-top: 73px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
          white-space: nowrap;
      &>.left{
        margin-right: 46px;
        display: flex;
        flex-direction: column;
        &>img{
          width: 129px;
        }
        &>span{
            line-height: 1.54;
        }
      }
      &>.right{
        display: flex;
        &>.box{
          &>.list{
            margin-top: 14px;
            display: grid;
            row-gap: 7px;
            &>a{
                          color: white;
            }
          }
        }
      }
    }
}
@media screen and (max-width : 1024px) {
  footer{
    height: 310px;
    &>.pack{
      padding-top: 65.2px;
      line-height: 1.77;
    }
  }
}
@media screen and (max-width : 767px) {
footer{
  height: 243px;

}

}