.main .section1 {
  width: 100%;
  height: 663px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: -webkit-image-set(url("/public/assets/main/section1.png") 1x, url("/public/assets/main/section1@2x.png") 2x, url("/public/assets/main/section1@3x.png") 3x);
  background-image: image-set(url("/public/assets/main/section1.png") 1x, url("/public/assets/main/section1@2x.png") 2x, url("/public/assets/main/section1@3x.png") 3x);
}

.main .section1 > .pack {
  font-size: 45px;
  color: var(--navy);
  padding-top: 244px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 1.31;
  position: relative;
}

.main .section1 > .pack > b {
  position: relative;
  z-index: 10;
  font-family: 'score';
  font-weight: bold;
}

.main .section1 > .pack > .line {
  height: 23px;
  background-color: #46bad1;
  opacity: 0.4;
  position: absolute;
}

.main .section1 > .pack > .one {
  width: 400px;
  top: 278px;
  left: 0;
}

.main .section1 > .pack > .two {
  top: 339px;
  left: 0;
  width: 258px;
}

.main .section2 {
  background-color: white;
  width: 100%;
  height: 864px;
  padding-top: 206px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.main .section2 > .pack {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 388px 654px;
      grid-template-columns: 388px 654px;
  -webkit-column-gap: 139px;
          column-gap: 139px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  opacity: 0;
  -webkit-transform: translate3d(0, 5%, 0);
          transform: translate3d(0, 5%, 0);
}

.main .section2 > .pack > .left {
  white-space: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.main .section2 > .pack > .left > .title {
  color: var(--navy);
  font-size: 45px;
  line-height: 1.33;
}

.main .section2 > .pack > .left > .title > b {
  font-family: 'score';
  font-weight: bold;
}

.main .section2 > .pack > .left > .sub {
  font-size: 16px;
  line-height: 1.63;
  margin: 37px 0;
}

.main .section2 > .pack > .left > .sub > b {
  color: #46BAD1;
}

.main .section2 > .pack > .left > button {
  width: 180px;
  height: 47px;
  background-color: var(--navy);
  color: white;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 17px 0 19px;
  cursor: pointer;
  font-weight: 500;
}

.main .section2 > .pack > .left > button > img {
  width: 38px;
}

.main .section2 > .pack > .right {
  width: 654px;
  height: 367px;
}

.main .section3 {
  height: 1188px;
  width: 100%;
  background-color: var(--white);
  padding-top: 113px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.main .section3 > .pack > .title {
  color: var(--navy);
  font-size: 34px;
  line-height: 1.29;
  font-family: 'score';
  font-weight: 400;
}

.main .section3 > .pack > .title > b {
  font-family: 'score';
}

.main .section3 > .pack > .wrapper {
  margin-top: 40px;
  display: -ms-grid;
  display: grid;
  row-gap: 19px;
}

.main .section3 > .pack > .wrapper > .card {
  opacity: 0;
  -webkit-transform: translate3d(0, 6%, 0);
          transform: translate3d(0, 6%, 0);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
}

.main .section3 > .pack > .wrapper > .card > img {
  width: 357px;
  height: 100%;
}

.main .section3 > .pack > .wrapper > .card > .content {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 34px 21px 27px 40px;
}

.main .section3 > .pack > .wrapper > .card > .content > .title {
  font-size: 22px;
}

.main .section3 > .pack > .wrapper > .card > .content > .sub {
  font-size: 14px;
  white-space: pre-line;
  line-height: 1.5;
  margin-top: 3px;
  margin-bottom: 45.8px;
}

.main .section3 > .pack > .wrapper > .card > .content > .category {
  display: -ms-grid;
  display: grid;
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

.main .section3 > .pack > .wrapper > .card > .content > .category > div {
  font-size: 14px;
  color: var(--dark-sky-blue);
  font-weight: 500;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  white-space: nowrap;
}

.main .section3 > .pack > .wrapper > .card > .content > .category > div > img {
  height: 31px;
  margin-bottom: 6px;
}

.main .section3 > .pack > .wrapper > .card > .content > .btn {
  position: absolute;
  right: 21px;
  bottom: 27px;
  width: 180px;
  height: 47px;
  background-color: var(--navy);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 17px 0 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.main .section3 > .pack > .wrapper > .card > .content > .btn > span {
  font-size: 16px;
  font-weight: 500;
  color: white;
}

.main .section3 > .pack > .wrapper > .card > .content > .btn > img {
  width: 38px;
}

.main .section4 {
  background-color: white;
  height: 1533px;
  width: 100%;
  padding-top: 201px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.main .section4 > .pack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.main .section4 > .pack > .title {
  font-size: 37px;
  line-height: 1.24;
  color: var(--navy);
  font-family: 'score';
}

.main .section4 > .pack > .title > b {
  font-family: 'score';
  font-weight: bold;
}

.main .section4 > .pack > .sub {
  color: #7e7a7a;
  font-size: 14px;
  font-weight: 500;
  margin-top: 12px;
  margin-bottom: 30px;
}

.main .section4 > .pack > .nav-wrapper {
  margin-top: 58px;
  display: -ms-grid;
  display: grid;
}

.main .section4 > .pack > .nav-wrapper > .card {
  padding: 26px 0 39px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.main .section4 > .pack > .nav-wrapper > .card > .title {
  font-size: 26px;
  color: var(--navy);
}

.main .section4 > .pack > .nav-wrapper > .card > .sub {
  color: #7e7a7a;
  font-size: 15px;
  font-weight: 500;
  margin-top: 7px;
  margin-bottom: 24px;
  white-space: nowrap;
}

.main .section4 > .pack > .nav-wrapper > .card > .btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.main .section4 > .pack > .nav-wrapper > .card > .btn-wrapper > a {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 18px;
  font-weight: 500;
  color: var(--navy);
  background-color: #b2e3ec;
  border-radius: 50px;
  white-space: nowrap;
  height: 52px;
}

.main .section4 > .pack > .nav-wrapper > .card > .btn-wrapper > a:hover {
  background-color: var(--navy);
  color: white;
}

.main .section5 {
  padding: 54px 0 184px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main .section5 > .pack > .content {
  height: 485px;
  background-color: #f8f8f8;
  width: 100vw;
  padding: 88px 0 0 483px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  white-space: nowrap;
}

.main .section5 > .pack > .content > .main-img {
  width: 601px;
  height: 384px;
  position: absolute;
  left: -180px;
  top: -54px;
}

.main .section5 > .pack > .content > .title {
  font-size: 34px;
  line-height: 1.35;
  color: var(--navy);
  font-family: 'score';
}

.main .section5 > .pack > .content > .title > b {
  font-family: 'score';
  font-weight: bold;
}

.main .section5 > .pack > .content > .sub {
  font-size: 17px;
  line-height: 1.47;
  margin-top: 10px;
  margin-bottom: 40px;
}

.main .section5 > .pack > .content > .btn {
  width: 257px;
  height: 47px;
  background-color: var(--navy);
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding: 0 17px 0 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.main .section5 > .pack > .content > .btn > img {
  width: 38px;
}

@media screen and (max-width: 1024px) {
  .main > .section1 {
    height: 373px;
  }
  .main > .section1 > .pack {
    font-size: 28px;
    padding-top: 137px;
  }
  .main > .section1 > .pack > .line {
    height: 16px;
  }
  .main > .section1 > .pack > .one {
    width: 246px;
    top: 160px;
  }
  .main > .section1 > .pack > .two {
    width: 162px;
    top: 196px;
  }
  .main > .section2 {
    height: 466px;
    padding-top: 91px;
  }
  .main > .section2 > .pack {
    -ms-grid-columns: 241px 392px;
        grid-template-columns: 241px 392px;
    -webkit-column-gap: 64px;
            column-gap: 64px;
  }
  .main > .section2 > .pack > .left > .title {
    font-size: 28px;
  }
  .main > .section2 > .pack > .left > .sub {
    font-size: 13px;
    margin: 13px 0 39px 0;
  }
  .main > .section2 > .pack > .left > button {
    width: 168px;
    height: 44px;
    font-size: 14px;
  }
  .main > .section2 > .pack > .right {
    width: 100%;
    height: 219px;
  }
  .main > .section3 {
    height: 856px;
    padding-top: 69px;
  }
  .main > .section3 > .pack > .title {
    font-size: 27px;
  }
  .main > .section3 > .pack > .wrapper {
    margin-top: 24px;
    row-gap: 31px;
  }
  .main > .section3 > .pack > .wrapper > .card {
    height: 187px;
  }
  .main > .section3 > .pack > .wrapper > .card > img {
    width: 211px;
  }
  .main > .section3 > .pack > .wrapper > .card > .content {
    padding: 21px 19px 20px 28px;
  }
  .main > .section3 > .pack > .wrapper > .card > .content > .title {
    font-size: 15px;
  }
  .main > .section3 > .pack > .wrapper > .card > .content > .sub {
    font-size: 13px;
    line-height: 1.38;
    margin-bottom: 38px;
    margin-top: 6px;
  }
  .main > .section3 > .pack > .wrapper > .card > .content > .category {
    -webkit-column-gap: 24px;
            column-gap: 24px;
  }
  .main > .section3 > .pack > .wrapper > .card > .content > .category > div {
    font-size: 10px;
  }
  .main > .section3 > .pack > .wrapper > .card > .content > .category > div > img {
    height: 22px;
  }
  .main > .section3 > .pack > .wrapper > .card > .content > .btn {
    right: 19px;
    bottom: 20px;
    width: 148px;
    height: 44px;
  }
  .main > .section3 > .pack > .wrapper > .card > .content > .btn > span {
    font-size: 13px;
  }
  .main > .section3 > .pack > .wrapper > .card > .content > .btn > img {
    width: 31px;
  }
  .main > .section4 {
    height: 1184px;
    padding-top: 165px;
  }
  .main > .section4 > .pack > .title {
    font-size: 27px;
  }
  .main > .section4 > .pack > .sub {
    font-size: 13px;
    margin-top: 7px;
    margin-bottom: 16px;
  }
  .main > .section4 > .pack > .nav-wrapper {
    margin-top: 45px;
  }
  .main > .section4 > .pack > .nav-wrapper > .card {
    padding: 25px 0 28px 0;
  }
  .main > .section4 > .pack > .nav-wrapper > .card > .title {
    font-size: 22px;
  }
  .main > .section4 > .pack > .nav-wrapper > .card > .btn-wrapper > a {
    height: 43px;
    font-size: 13px;
  }
  .main > .section5 > .pack {
    padding-left: 95px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .main > .section5 > .pack > .content {
    height: 324px;
    padding: 50px 0 0 241px;
  }
  .main > .section5 > .pack > .content > .main-img {
    width: 315px;
    height: 202px;
    left: -95px;
  }
  .main > .section5 > .pack > .content > .title {
    font-size: 25px;
  }
  .main > .section5 > .pack > .content > .sub {
    font-size: 13px;
    margin-top: 9px;
    margin-bottom: 47px;
  }
  .main > .section5 > .pack > .content > .btn {
    width: 230px;
    height: 44px;
    font-size: 13px;
    padding: 0 18px 0 17px;
  }
  .main > .section5 > .pack > .content > .btn > img {
    width: 31px;
  }
}

@media screen and (max-width: 767px) {
  .main > .section1 {
    background-image: -webkit-image-set(url("/public/assets/main/section1mb.png") 1x, url("/public/assets/main/section1mb@2x.png") 2x, url("/public/assets/main/section1mb@3x.png") 3x);
    background-image: image-set(url("/public/assets/main/section1mb.png") 1x, url("/public/assets/main/section1mb@2x.png") 2x, url("/public/assets/main/section1mb@3x.png") 3x);
    height: 649px;
  }
  .main > .section1 > .pack {
    font-size: 30px;
    padding-top: 116px;
  }
  .main > .section1 > .pack > .one {
    top: 140px;
    width: 267px;
  }
  .main > .section1 > .pack > .two {
    width: 175px;
    top: 178px;
  }
  .main > .section2 {
    height: 690px;
    padding-top: 116px;
  }
  .main > .section2 > .pack {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .main > .section2 > .pack > .right {
    width: 100%;
    height: 179px;
  }
  .main > .section2 > .pack > .left {
    text-align: center;
    margin-top: 27px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .main > .section2 > .pack > .left > .title {
    font-size: 25px;
  }
  .main > .section3 {
    height: 1998px;
    padding-top: 60px;
  }
  .main > .section3 > .pack > .title {
    font-size: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .main > .section3 > .pack > .wrapper {
    -ms-grid-rows: 525px 583px 529px;
        grid-template-rows: 525px 583px 529px;
  }
  .main > .section3 > .pack > .wrapper > .card {
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .main > .section3 > .pack > .wrapper > .card > img {
    width: 100%;
    height: 215px;
  }
  .main > .section3 > .pack > .wrapper > .card > .content {
    background-color: white;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 16px 0 36px 0;
  }
  .main > .section3 > .pack > .wrapper > .card > .content > .title {
    font-size: 22px;
  }
  .main > .section3 > .pack > .wrapper > .card > .content > .sub {
    text-align: center;
    font-size: 13px;
    margin-top: 9px;
    margin-bottom: 25px;
  }
  .main > .section3 > .pack > .wrapper > .card > .content > .btn {
    position: unset;
    width: 180px;
    height: 50px;
    margin-top: 52px;
  }
  .main > .section3 > .pack > .wrapper > .card > .content > .category {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
  }
  .main > .section3 > .pack > .wrapper > .card > .content > .category > div > img {
    height: 27.6px;
  }
  .main > .section3 > .pack > .wrapper > .card:nth-child(2) > .content > .category {
    width: 160px;
    display: block;
  }
  .main > .section3 > .pack > .wrapper > .card:nth-child(2) > .content > .category > div {
    -webkit-column-gap: 23px;
            column-gap: 23px;
  }
  .main > .section3 > .pack > .wrapper > .card:nth-child(2) > .content > .category > .top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 16.3px;
  }
  .main > .section3 > .pack > .wrapper > .card:nth-child(2) > .content > .category > .top > div > img {
    width: 32px;
  }
  .main > .section3 > .pack > .wrapper > .card:nth-child(2) > .content > .category > .bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .main > .section3 > .pack > .wrapper > .card:nth-child(2) > .content > .category > .bottom > div > img {
    width: 32px;
  }
  .main > .section3 > .pack > .wrapper > .card:nth-child(3) > .content > .category {
    -ms-grid-columns: (37px)[4] !important;
        grid-template-columns: repeat(4, 37px) !important;
  }
  .main > .section3 > .pack > .wrapper > .card:nth-child(3) > .content > .category > div > img {
    width: 100%;
    height: 38.6px;
  }
  .main > .section4 {
    height: 1600px;
    padding-top: 82px;
  }
  .main > .section4 > .pack > .title {
    font-size: 25px;
    margin-top: 31px;
  }
  .main > .section4 > .pack > .sub {
    margin-bottom: 28px;
  }
  .main > .section4 > .pack > .nav-wrapper > .card {
    padding: 39px 0 49px 0;
  }
  .main > .section4 > .pack > .nav-wrapper > .card > .title {
    font-size: 20px;
  }
  .main > .section4 > .pack > .nav-wrapper > .card > .sub {
    font-size: 13px;
    white-space: pre-wrap;
  }
  .main > .section4 > .pack > .nav-wrapper > .card > .btn-wrapper {
    width: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (159px)[2];
        grid-template-columns: repeat(2, 159px);
    gap: 10px 8px;
  }
  .main > .section4 > .pack > .nav-wrapper > .card > .btn-wrapper > a {
    width: 156px;
    height: 45px;
  }
  .main > .section4 > .pack > .nav-wrapper > .card > .btn-wrapper > a:nth-child(5) {
    width: 100%;
    grid-column: span 2;
  }
  .main > .section5 {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: 804px;
    background-color: #f8f8f8;
    padding: 134px 0 150px 0;
  }
  .main > .section5 > .pack {
    padding: unset;
  }
  .main > .section5 > .pack > .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: unset;
    text-align: center;
    height: auto;
    width: 320px;
  }
  .main > .section5 > .pack > .content > .main-img {
    position: unset;
    width: 100%;
    height: 204px;
  }
  .main > .section5 > .pack > .content > .title {
    font-size: 25px;
    margin-bottom: 37px;
  }
  .main > .section5 > .pack > .content > .sub {
    margin-top: 19px;
    margin-bottom: 48px;
    font-size: 15px;
    line-height: 1.6;
  }
}
