.review {
  width: 100%;
  height: 1071px;
  position: relative;
  padding-top: 200px;
  box-sizing: border-box;

  &>.pack {
    display: flex;
    flex-direction: column;
    align-items: center;

    &>.title {
      display: flex;
      align-items: center;
      font-family: "score";

      &>img {
        width: 35.2px;
      }

      &>.right {
        transform: rotate(180deg);
      }

      &>b {
        font-family: "score";
        font-weight: bold;
        font-size: 30px;
        color: var(--navy);
        margin: 0 25.6px;
      }
    }

    &>.sub {
      margin-top: 20px;
      font-size: 20px;
      text-align: center;
      white-space: pre-line;

      &>b {
        color: #46BAD1;
      }
    }

    &>.list {
      margin-top: 47px;
      display: grid;
      column-gap: 13px;
      row-gap: 14px;
    }
  }

  &>.back {
    width: 100%;
    height: 410px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #ddf3f7;
    z-index: -1;
  }
}

.homecleannv {
  padding-top: 0;
  // height: 1082px;
  height: 600px;

  &>.back {
    background-color: rgb(230, 249, 239);
  }

  &>.pack {

    &>.sub {
      &>b {
        color: var(--shamrock);
      }
    }

    &>.list {
      margin-top: 52px;
    }
  }
}

.airre {
  height: 450px;
}

.airconre {
  height: 1900px;

  &>.back {
    height: 970px;
  }
}

.matre {
  height: 935px;
  padding-top: 134px;
}

.jointre {
  height: 1001px;
  padding-top: 199px;
}

.birdre {
  height: 1023px;
  padding-top: 207px;
}

.buildingre {
  height: 980px;
  padding-top: 100px;
}

.aircarere,
.bugre {
  height: 200px;
  padding-top: 80px;

  &>.back {
    height: 200px;
  }
}

.purifyre {
  height: 920px;
  padding-top: 0;
}

.homere {
  padding-top: 90px;
  height: 300px;


  &>.back {
    height: 300px;
  }
}


@media screen and (max-width : 1024px) {
  .review {
    height: 804px;
    padding-top: 134px;

    &>.pack {
      &>.title {
        &>b {
          font-size: 25px;
          margin: 0 18px;
        }

        &>img {
          width: 32px;
        }

        &>.left {
          transform: translateY(-10px);
        }
      }

      &>.sub {
        margin-top: 19px;
        font-size: 14px;
      }

      &>.list {
        margin-top: 37px;
        gap: 10px 9px;
      }

    }

    &>.back {
      height: 319px;
    }
  }



  .airconre {
    height: 1500px;

    &>.back {
      height: 830px;
    }
  }

  .homere {
    height: 838px;
    padding-top: 50px;

    &>.back {
      height: 400px;
    }
  }

  .homecleannv {
    height: 600px;
  }

  .airre {
    height: 330px;
  }

  .bugre {
    padding-top: 85px;
    height: 200px;


    &>.back {
      height: 200px;
    }
  }

  .aircarere {
    height: 200px;
    padding-top: 80px;

    &>.back {
      height: 200px;
    }
  }

  .homere {
    padding-top: 110px;
    height: 300px;


    &>.back {
      height: 300px;
    }
  }

}

@media screen and (max-width : 767px) {
  .review {
    height: 1074px;
    padding-top: 112px;

    &>.pack {
      &>.title {
        position: relative;
        align-items: flex-start;

        &>img {
          width: 29.5px;
          position: absolute;
        }

        &>.right {
          right: -20px;
          top: -5px;
        }

        &>.left {
          left: -10px;
        }

        &>b {
          text-align: center;
          margin: 0;
          font-size: 25px;
          white-space: pre-wrap;
        }
      }

      &>.list {
        margin-top: 31px;
        gap: 8px;
      }

    }

    &>.back {
      height: 429px;
    }
  }

  .airconre {
    height: 2140px;

    &>.back {
      height: 1180px;
    }
  }

  .homere {
    height: 1400px;

    &>.pack {
      &>.sub {
        font-size: 13px;
      }
    }

    &>.back {
      height: 560px;
    }
  }

  .washre,
  .filterre {
    &>.pack {
      &>.title {
        &>.right {
          top: -25px;
        }

        &>.left {
          top: -20px;
          left: -20px;
        }
      }
    }
  }

  .homecleannv {
    height: 600px;

    &>.back {
      height: 300px;
    }
  }

  .airre {
    padding-top: 90px;
    height: 280px;

    &>.pack {

      &>.title {
        &>.left {
          left: -25px;
        }
      }
    }

    &>.back {
      height: 280px;
    }
  }

  .bugre {
    padding-top: 45px;
    height: 150px;


    &>.back {
      height: 150px;
    }
  }

  .aircarere {
    height: 200px;
    padding-top: 70px;

    &>.back {
      height: 200px;
    }
  }

  .homere {
    padding-top: 100px;
    height: 300px;


    &>.back {
      height: 300px;
    }
  }

}