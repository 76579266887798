.floating {
  position: fixed;
  right: 45px;
  bottom: 54px;
  display: -ms-grid;
  display: grid;
  row-gap: 20px;
  z-index: 1000;
}

.floating > a {
  width: 157px;
  height: 61px;
  border-radius: 30.5px;
  -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
          box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-left: 14px;
  padding-right: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.floating > a > .title {
  font-size: 18px;
  font-weight: bold;
}

.floating > a > img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media screen and (max-width: 1024px) {
  .floating {
    right: 20px;
    bottom: 66px;
    row-gap: 8px;
  }
  .floating > a {
    overflow: hidden;
    width: 47px;
    height: 47px;
    border-radius: 47px;
    padding: unset;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .floating > a > .coupang {
    width: 31.3px;
  }
  .floating > a > .naver {
    width: 24.4px;
  }
  .floating > a > .kakao {
    width: 28px;
  }
  .floating > a > .bot {
    width: 27px;
  }
}
