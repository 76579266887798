.nav {
  height: 72px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom: solid 1px #cbcbcb;
}

.nav > .wrapper {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.nav > .wrapper > button {
  white-space: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-width: 140px;
  padding: 0 10px;
  background-color: unset;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  color: var(--brownish-grey);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.nav > .wrapper > .bar {
  width: 140px;
  height: 3px;
  background-color: var(--dark-sky-blue);
  position: absolute;
  bottom: -2px;
  left: 0;
  -webkit-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out;
}

.nav > .wrapper > .productcolor {
  background-color: var(--dark-sky-blue);
}

.nav > .wrapper > .homecolor {
  background-color: #00bb68;
}

.nav > .wrapper .business {
  background-color: #00bab3;
}

.nav > .wrapper .on {
  color: black;
  font-weight: bold;
}

.nav > .businessgrid {
  display: -ms-grid;
  display: grid;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  -ms-grid-columns: 81px 92px 171px 135px;
      grid-template-columns: 81px 92px 171px 135px;
}

.nav > .businessgrid > button {
  min-width: unset;
  width: 100%;
}

.nav > .businessgrid > .bar {
  width: 81px;
}

@media screen and (max-width: 1024px) {
  .nav {
    height: 50px;
  }
}

@media screen and (max-width: 767px) {
  .nav {
    height: 51px;
    width: 100%;
    position: relative;
  }
  .nav > .wrapper {
    width: 100%;
  }
  .nav > .wrapper > .now-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    width: 100%;
  }
  .nav > .wrapper > .now-nav > img {
    position: absolute;
    right: 21px;
    width: 18px;
  }
  .nav > .businessgrid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-column-gap: unset;
            column-gap: unset;
    -ms-grid-columns: unset;
        grid-template-columns: unset;
  }
  .nav > .nav-wrapper {
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    top: 52px;
    z-index: 700;
    background-color: white;
    width: 100%;
    height: 0;
    position: absolute;
    overflow: hidden;
    display: -ms-grid;
    display: grid;
    row-gap: 36px;
    -ms-grid-rows: (21px)[5];
        grid-template-rows: repeat(5, 21px);
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .nav > .nav-wrapper > button {
    background-color: white;
    font-size: 15px;
    font-weight: 500;
    color: #707070;
  }
  .nav > .nav-wrapper > .on {
    color: #46bad1;
    font-weight: bold;
  }
  .nav > .nav-wrapper > .homemenu {
    color: #00bb68;
  }
  .nav > .open {
    height: 324px;
    padding-top: 34px;
    padding-bottom: 46px;
  }
  .nav > .businessop {
    height: 270px;
  }
  .nav > .aboutop,
  .nav .partnerop {
    height: 150px;
  }
}
