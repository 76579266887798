.check-section {
  height: 1145px;
  width: 100%;
  position: relative;
}

.check-section > .back {
  position: absolute;
  z-index: -1;
  height: 942px;
  width: 100%;
  background-color: #f8f8f8;
  bottom: 0;
  left: 0;
}

.check-section > .pack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.check-section > .pack > .title {
  text-align: center;
  line-height: 1.43;
  font-size: 30px;
  color: var(--navy);
  font-family: "score";
}

.check-section > .pack > .title > b {
  font-family: "score";
  font-weight: bold;
  color: #46BAD1;
}

.check-section > .pack > .circle-wrapper {
  margin-top: 41px;
  display: -ms-grid;
  display: grid;
  -webkit-column-gap: 31px;
          column-gap: 31px;
}

.check-section > .pack > .circle-wrapper > .circle {
  text-align: center;
}

.check-section > .pack > .circle-wrapper > .circle > .title {
  margin-top: 28px;
  font-size: 18px;
  font-weight: bold;
  color: var(--navy);
  white-space: nowrap;
}

.check-section > .pack > .content {
  font-size: 32px;
  font-weight: 500;
  color: var(--navy);
  margin-top: 192px;
  text-align: center;
  line-height: 1.34;
  font-family: "score";
}

.check-section > .pack > .content > b {
  font-family: "score";
  font-weight: bold;
  color: #46BAD1;
}

.check-section > .pack > .check-box {
  margin-top: 48px;
  width: 522px;
  height: 335px;
  background-color: white;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 26, 61, 0.13);
          box-shadow: 0 3px 6px 0 rgba(0, 26, 61, 0.13);
  border-radius: 11px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.check-section > .pack > .check-box > .title {
  width: 180px;
  height: 45px;
  background-color: var(--navy);
  font-size: 18px;
  font-family: 'gmarket';
  font-weight: bold;
  color: var(--dark-sky-blue);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 23px;
  -ms-flex-item-align: center;
      align-self: center;
  top: -22px;
  position: absolute;
}

.check-section > .pack > .check-box > .check {
  margin-right: 14.2px;
  display: -ms-grid;
  display: grid;
  row-gap: 20px;
}

.check-section > .pack > .check-box > .check > img {
  width: 34px;
}

.check-section > .pack > .check-box > .content {
  line-height: 2.2;
  font-size: 23px;
}

.check-section > .pack > .check-box > .content > b {
  position: relative;
  z-index: 1;
}

.check-section > .pack > .check-box > .content > b > span {
  z-index: 10;
  position: relative;
}

.check-section > .pack > .check-box > .content > b > .back {
  left: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
  height: 17px;
  z-index: 1;
  background-color: #addde4;
}

.check-section > .pack > .wash {
  width: 596px;
  height: 230px;
}

.hoodcheck,
.aircheck {
  height: 651px;
}

.hoodcheck > .back,
.aircheck > .back {
  height: 100%;
}

.hoodcheck > .pack > .content,
.aircheck > .pack > .content {
  margin-top: 92px;
}

.filtercheck {
  height: 697px;
}

.filtercheck > .back {
  height: 100%;
}

.filtercheck > .pack > .content {
  margin-top: 120px;
}

.filtercheck > .pack > .check-box {
  width: 640px;
  height: 329px;
}

.homecleancheck > .pack > .title > b {
  color: var(--shamrock);
}

.homecleancheck > .pack > .content > b {
  color: var(--shamrock);
}

.homecleancheck > .pack > .check-box > .title {
  background-color: var(--shamrock);
  color: white;
}

.homecleancheck > .pack > .check-box > .content > b > .back {
  background-color: #cdf3e0;
}

.businesscheck > .pack > .content > b {
  color: #40ccc7;
}

.businesscheck > .pack > .check-box > .title {
  background-color: #40ccc7;
  color: white;
}

.businesscheck > .pack > .check-box > .content > b > .back {
  background-color: #c1eff5;
}

.matcheck > .pack > .check-box {
  width: 553px;
  height: 380px;
}

.jointcheck > .pack > .check-box {
  width: 553px;
  height: 337px;
}

.birdcheck > .pack > .check-box {
  width: 437px;
  height: 185px;
}

.bugcheck {
  height: 958px;
}

.bugcheck > .back {
  height: 755px;
}

.bugcheck > .pack {
  padding-bottom: 118px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.bugcheck > .pack > .content {
  margin-top: 148px;
}

.bugcheck > .pack > .check-box {
  width: 563px;
  height: 229px;
}

.airfiltercheck {
  height: 618px;
}

.airfiltercheck > .back {
  height: 100%;
}

.airfiltercheck > .pack > .content {
  margin-top: 114px;
}

.airfiltercheck > .pack > .check-box {
  width: 634px;
  height: 234px;
}

@media screen and (max-width: 1024px) {
  .check-section {
    height: 900px;
  }
  .check-section > .back {
    height: 745px;
  }
  .check-section > .pack > .title {
    font-size: 25px;
  }
  .check-section > .pack > .circle-wrapper {
    margin-top: 28px;
    -webkit-column-gap: 14px;
            column-gap: 14px;
  }
  .check-section > .pack > .circle-wrapper > .circle > .title {
    margin-top: 15px;
    font-size: 13px;
  }
  .check-section > .pack > .content {
    font-size: 25px;
    margin-top: 132px;
  }
  .check-section > .pack > .check-box {
    margin-top: 59px;
    width: 385px;
    height: 273px;
  }
  .check-section > .pack > .check-box > .title {
    width: 150px;
    height: 38px;
    font-size: 15px;
  }
  .check-section > .pack > .check-box > .check {
    margin-right: 11.2px;
    row-gap: 19px;
  }
  .check-section > .pack > .check-box > .check > img {
    width: 23px;
    height: 22px;
  }
  .check-section > .pack > .check-box > .content {
    font-size: 16px;
    line-height: 2.56;
  }
  .check-section > .pack > .check-box > .content > b > .back {
    height: 10px;
  }
  .washcheck > .pack > .check-box {
    width: 407px;
    height: 170px;
  }
  .hoodcheck,
  .aircheck {
    height: 527px;
  }
  .hoodcheck > .back,
  .aircheck > .back {
    height: 100%;
  }
  .hoodcheck > .pack > .content,
  .aircheck > .pack > .content {
    margin-top: 73px;
  }
  .filtercheck {
    height: 527px;
  }
  .filtercheck > .pack > .content {
    margin-top: 73px;
  }
  .filtercheck > .pack > .check-box {
    width: 436px;
    height: 246px;
  }
  .birdcheck {
    height: 730px;
  }
  .birdcheck > .pack > .circle-wrapper {
    -webkit-column-gap: 21px;
            column-gap: 21px;
  }
  .birdcheck > .pack > .check-box {
    width: 289px;
    height: 130px;
  }
  .birdcheck > .back {
    height: 580px;
  }
  .bugcheck {
    height: 730px;
  }
  .bugcheck > .back {
    height: 580px;
  }
  .bugcheck > .pack > .check-box {
    width: 390px;
    height: 165px;
  }
  .airfiltercheck {
    height: 591px;
  }
  .airfiltercheck > .back {
    height: 100%;
  }
  .airfiltercheck > .pack > .content {
    margin-top: 124px;
  }
  .airfiltercheck > .pack > .check-box {
    width: 465px;
    height: 191px;
  }
}

@media screen and (max-width: 767px) {
  .check-section {
    height: 1100px;
  }
  .check-section > .back {
    height: 942px;
  }
  .check-section > .pack > .circle-wrapper {
    margin-top: 36px;
    row-gap: 27px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
  }
  .check-section > .pack > .circle-wrapper > .circle > .title {
    font-size: 14px;
  }
  .check-section > .pack > .content {
    margin-top: 153px;
  }
  .check-section > .pack > .check-box {
    margin-top: 63px;
    width: 100%;
    height: 265px;
  }
  .check-section > .pack > .check-box > .title {
    width: 161px;
    height: 40px;
    font-size: 16px;
  }
  .check-section > .pack > .check-box > .check {
    margin-right: 9px;
    row-gap: 18px;
  }
  .check-section > .pack > .check-box > .check > img {
    width: 22px;
    height: 22px;
  }
  .check-section > .pack > .check-box > .content {
    font-size: 15px;
    line-height: 2.67;
  }
  .washcheck {
    height: 920px;
  }
  .washcheck > .back {
    height: 735px;
  }
  .washcheck > .pack > .title {
    font-size: 23px;
  }
  .washcheck > .pack > .circle-wrapper {
    -webkit-column-gap: 16px;
            column-gap: 16px;
  }
  .washcheck > .pack > .check-box {
    width: 100%;
    height: 192px;
  }
  .washcheck > .pack > .check-box > .check {
    row-gap: 17px;
    margin-right: 6px;
  }
  .washcheck > .pack > .check-box > .content {
    font-size: 14px;
  }
  .hoodcheck,
  .aircheck,
  .filtercheck,
  .airfiltercheck {
    height: 615px;
  }
  .hoodcheck > .pack > .content,
  .aircheck > .pack > .content,
  .filtercheck > .pack > .content,
  .airfiltercheck > .pack > .content {
    margin-top: 91px;
  }
  .hoodcheck > .pack > .check-box > .content,
  .aircheck > .pack > .check-box > .content,
  .filtercheck > .pack > .check-box > .content,
  .airfiltercheck > .pack > .check-box > .content {
    line-height: 1;
  }
  .hoodcheck > .pack > .check-box > .content > b,
  .aircheck > .pack > .check-box > .content > b,
  .filtercheck > .pack > .check-box > .content > b,
  .airfiltercheck > .pack > .check-box > .content > b {
    line-height: 2.6;
  }
  .hoodcheck > .pack > .check-box > .content > .filter-first,
  .aircheck > .pack > .check-box > .content > .filter-first,
  .filtercheck > .pack > .check-box > .content > .filter-first,
  .airfiltercheck > .pack > .check-box > .content > .filter-first {
    line-height: 1.6;
  }
  .hoodcheck > .pack > .check-box > .content > .filter-first > .two,
  .aircheck > .pack > .check-box > .content > .filter-first > .two,
  .filtercheck > .pack > .check-box > .content > .filter-first > .two,
  .airfiltercheck > .pack > .check-box > .content > .filter-first > .two {
    width: 130px;
    top: 12px;
  }
  .jointcheck > .pack > .circle-wrapper {
    -webkit-column-gap: 16px;
            column-gap: 16px;
  }
  .jointcheck > .pack > .title {
    font-size: 23px;
  }
  .jointcheck > .pack > .content {
    font-size: 23px;
    white-space: nowrap;
  }
  .matcheck > .pack > .title {
    font-size: 23px;
  }
  .matcheck > .pack > .check-box {
    height: 311px;
  }
  .filtercheck > .pack > .check-box > .check {
    -ms-grid-rows: 32px 21px 21px 21px 21px;
        grid-template-rows: 32px 21px 21px 21px 21px;
    row-gap: 18px;
  }
  .bugcheck {
    height: 780px;
  }
  .bugcheck > .back {
    height: 631px;
  }
  .bugcheck > .pack > .title {
    font-size: 23px;
  }
  .bugcheck > .pack > .circle-wrapper {
    -webkit-column-gap: 16px;
            column-gap: 16px;
  }
  .bugcheck > .pack > .circle-wrapper > .circle > .title {
    white-space: pre-line;
  }
  .bugcheck > .pack > .check-box {
    height: 170px;
  }
  .bugcheck > .pack > .check-box > .check {
    margin-right: 7px;
  }
  .bugcheck > .pack > .check-box > .content {
    font-size: 14px;
  }
  .birdcheck {
    height: 930px;
  }
  .birdcheck > .back {
    height: 782px;
  }
  .birdcheck > .pack > .title {
    font-size: 23px;
  }
  .birdcheck > .pack > .circle-wrapper {
    gap: 16px 38px;
  }
  .birdcheck > .pack > .content {
    margin-top: 94px;
  }
  .birdcheck > .pack > .check-box {
    margin-top: 42px;
    width: 284px;
    height: 157px;
  }
  .airfiltercheck {
    height: 591px;
  }
  .airfiltercheck > .pack > .content {
    margin-top: 130px;
  }
  .airfiltercheck > .pack > .check-box {
    height: 192px;
  }
}
