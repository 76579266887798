.kind {
  background-color: #f8f8f8;
  width: 100%;
  height: 1086px;
  padding-top: 154.5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.kind > .pack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.kind > .pack > .title {
  text-align: center;
  color: var(--navy);
  font-size: 35px;
  font-family: "score";
  line-height: 1.3;
}

.kind > .pack > .title > b {
  font-family: "score";
  font-weight: bold;
  color: #46BAD1;
}

.kind > .pack > .list {
  margin-top: 53px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (223px)[3];
      grid-template-columns: repeat(3, 223px);
  -webkit-column-gap: 78px;
          column-gap: 78px;
  row-gap: 57px;
}

.kind > .pack > .list > .card > .circle {
  width: 215px;
  height: 215px;
  border-radius: 215px;
  background-color: white;
}

.kind > .pack > .list > .card > .title {
  width: 100%;
  height: 53px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
  background-color: var(--dark-sky-blue);
  font-size: 22px;
  font-weight: bold;
  margin-top: 18px;
}

.kind > .pack > .list > .card > .title > span {
  margin-left: 5px;
  font-size: 14px;
  font-weight: 500;
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
}

.washkind > .pack > .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
  gap: 48px 44px;
}

.washkind > .pack > .list > .card {
  width: 215px;
}

.hoodkind,
.filterkind {
  height: 748px;
  padding-top: 120px;
}

.hoodkind > .pack > .category,
.filterkind > .pack > .category {
  margin-bottom: 20px;
  width: 155px;
  height: 33px;
  background-color: var(--navy);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
  font-size: 11px;
  font-family: 'gmarket';
  border-radius: 16.5px;
}

.hoodkind > .pack > .list,
.filterkind > .pack > .list {
  -ms-grid-columns: (215px)[2];
      grid-template-columns: repeat(2, 215px);
  -webkit-column-gap: 99px;
          column-gap: 99px;
  margin-top: 45px;
}

.businesskind > .pack > .category {
  background-color: #40ccc7;
}

.businesskind > .pack > .list > .card > .title {
  background-color: #40ccc7;
}

@media screen and (max-width: 1024px) {
  .kind {
    height: 1030px;
    padding-top: 161px;
  }
  .kind > .pack > .title {
    font-size: 25px;
  }
  .kind > .pack > .list {
    margin-top: 48px;
    -ms-grid-columns: (190px)[3];
        grid-template-columns: repeat(3, 190px);
    -webkit-column-gap: 37px;
            column-gap: 37px;
    row-gap: 49px;
  }
  .kind > .pack > .list > .card > .circle {
    width: 100%;
    height: 190px;
  }
  .kind > .pack > .list > .card > .title {
    height: 45px;
    margin-top: 16px;
    font-size: 18px;
  }
  .kind > .pack > .list > .card > .title > span {
    font-size: 11px;
  }
  .washkind {
    padding-top: 220px;
  }
  .washkind > .pack > .list {
    width: 725px;
  }
  .washkind > .pack > .list > .card {
    width: 153px;
  }
  .washkind > .pack > .list > .card > .circle {
    height: 153px;
  }
  .washkind > .pack > .list > .card > .title {
    margin-top: 13px;
    height: 38px;
    font-size: 13px;
  }
  .washkind > .pack > .list > .card > .title > span {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .hoodkind,
  .filterkind {
    height: 745px;
  }
  .hoodkind > .pack > .list,
  .filterkind > .pack > .list {
    -ms-grid-columns: (215px)[2];
        grid-template-columns: repeat(2, 215px);
    -webkit-column-gap: 99px;
            column-gap: 99px;
  }
  .hoodkind > .pack > .list > .card,
  .filterkind > .pack > .list > .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .hoodkind > .pack > .list > .card > .circle,
  .filterkind > .pack > .list > .card > .circle {
    height: 215px;
  }
  .hoodkind > .pack > .list > .card > .title,
  .filterkind > .pack > .list > .card > .title {
    width: 162px;
  }
}

@media screen and (max-width: 767px) {
  .kind {
    height: 1390px;
    padding-top: 223px;
  }
  .kind > .pack > .list {
    margin-top: 91px;
    -ms-grid-columns: (152px)[2];
        grid-template-columns: repeat(2, 152px);
    gap: 67px 16px;
  }
  .kind > .pack > .list > .card > .circle {
    height: 152px;
  }
  .kind > .pack > .list > .card > .title {
    margin-top: 33px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .kind > .pack > .list > .card > .title > span {
    margin-left: 0;
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
  .washkind {
    padding-top: 238px;
    height: 1673px;
  }
  .washkind > .pack > .list {
    width: 100%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 54px 16px;
  }
  .washkind > .pack > .list > .card {
    width: 152px;
  }
  .washkind > .pack > .list > .card > .circle {
    height: 152px;
  }
  .washkind > .pack > .list > .card > .title {
    margin-top: 23px;
    font-size: 18px;
    height: 48px;
  }
  .washkind > .pack > .list > .card > .title > span {
    font-size: 12px;
  }
  .hoodkind,
  .filterkind {
    height: 1090px;
    padding-top: 113px;
  }
  .hoodkind > .pack > .list,
  .filterkind > .pack > .list {
    margin-top: 37px;
    -ms-grid-columns: 215px;
        grid-template-columns: 215px;
  }
  .hoodkind > .pack > .list > .card > .circle,
  .filterkind > .pack > .list > .card > .circle {
    height: 215px;
  }
  .hoodkind > .pack > .list > .card > .title,
  .filterkind > .pack > .list > .card > .title {
    margin-top: 21px;
    width: 138px;
    height: 37px;
    font-size: 15px;
  }
}
