.partner {
  &>.banner {
    height: 370px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: image-set(url('/public/assets/partner/banner.png') 1x, url('/public/assets/partner/banner@2x.png') 2x, url('/public/assets/partner/banner@3x.png') 3x);
    text-align: center;
    padding-top: 234px;
    box-sizing: border-box;

    &>.title {
      font-size: 39px;
      font-weight: bold;
      color: white;
    }
  }

  &>.section1 {
    height: 1096px;
    position: relative;

    &>.back {
      z-index: -1;
      width: 100%;
      height: 372px;
      background-color: #f8f8f8;
      position: absolute;
      left: 0;
      bottom: 0;

    }

    &>.pack {
      padding-top: 58px;
      box-sizing: border-box;

      &>.top {
        display: flex;

        &>.left {
          padding-top: 198px;
          box-sizing: border-box;
          margin-right: 67px;

          &>.category {
            width: 168px;
            border-radius: 16.5px;
            font-weight: bold;
            font-family: 'gmarket';
            display: flex;
            align-items: center;
            justify-content: center;
            height: 33px;
            background-color: var(--navy);
            color: white;
            font-size: 11px;
          }

          &>.title {
            font-size: 35px;
            line-height: 1.26;
            color: var(--navy);
            white-space: nowrap;
            margin: 14px 0;
            font-family: "score";

            &>b {
              font-family: "score";
              font-weight: bold;
            }
          }

          &>.sub {
            font-size: 16px;
            line-height: 1.63;
          }
        }

        &>.right {
          width: 676px;
        }
      }

      &>.bottom {
        margin-top: 88px;
        display: grid;
        grid-template-columns: repeat(4, 237px);
        column-gap: 15px;

        &>.card {
          height: 350px;
          border-radius: 11px;
          box-sizing: border-box;
          padding-left: 28px;
          padding-top: 25px;
          position: relative;
          overflow: hidden;

          &>img {
            width: 53px;
          }

          &>.title {
            font-size: 25px;
            font-weight: bold;
            line-height: 1.2;
            white-space: pre-line;
            margin-top: 30px;
          }

          &>.sub {
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: white;
            height: 159px;
            width: 100%;
            padding-top: 20px;
            padding-left: 29px;
            box-sizing: border-box;
            font-size: 13px;
            line-height: 1.69;
          }
        }

        &>.card:nth-child(odd) {
          background-color: var(--navy);

          &>.title {
            color: white;
          }
        }

        &>.card:nth-child(even) {
          background-color: var(--dark-sky-blue);
        }
      }
    }
  }

  &>.section2 {
    height: 1848px;
    width: 100%;

    &>.pack {
      height: 100%;
      position: relative;
      padding-top: 289px;

      &>.back {
        z-index: -1;
        position: absolute;

        &>div {
          width: 100vw;
          height: 258px;
          background-color: rgb(217, 241, 246);
        }
      }

      &>.first {
        top: 246px;
        right: 420px;
      }

      &>.second {
        right: 340px;
        top: 1000px;

        &>div {
          height: 322px;
          background-color: rgb(237, 237, 237);
        }
      }

      &>div>.img-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 334px);
        grid-template-rows: repeat(2, 223px);
        gap: 14px 16px;
        transform: translateX(250px);
      }

      &>.one {
        &>.title {
          font-size: 40px;
          line-height: 1.28;
          color: var(--navy);
          position: relative;
          font-family: "score";

          &>b {
            font-family: "score";
            font-weight: bold;
          }

          &>.line {
            width: 338px;
            height: 29px;
            position: absolute;
            background-color: #46bad1;
            opacity: 0.57;
            bottom: -8px;
            left: -8px;
            z-index: -1;
          }
        }

        &>.sub {
          margin-top: 13px;
          font-size: 20px;
          margin-bottom: 25px;
        }
      }

      &>.two {
        margin-top: 157px;

        &>.title {
          font-size: 40px;
          font-weight: 500;
          line-height: 1.29;
          color: var(--navy);
          margin-bottom: 19px;
          position: relative;
          font-family: "score";

          &>b {
            font-family: "score";
            font-weight: bold;
          }

          &>.line {
            width: 251px;
            height: 29px;
            position: absolute;
            background-color: #8d8d8d;
            opacity: 0.25;
            bottom: -8px;
            left: -8px;
            z-index: -1;
          }
        }

        &>.sub {
          margin-bottom: 29px;
          font-size: 20px;
          line-height: 1.53;
        }
      }
    }
  }

  &>.section3 {
    height: 1586px;

    &>.pack {
      position: relative;
      padding-top: 86px;
      box-sizing: border-box;

      &>.back {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;

        &>div {
          width: 100vw;
          height: 272px;
          background-color: var(--navy);
        }
      }

      &>.category {
        width: 136px;
        height: 33px;
        background-color: #76c7d3;
        border-radius: 16.5px;
        font-size: 11px;
        font-weight: bold;
        font-family: 'gmarket';
        color: var(--navy);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &>.title {
        color: white;
        font-size: 35px;
        font-weight: 500;
        margin-top: 12px;
        font-family: "score";

        &>b {
          font-family: "score";
          font-weight: bold;
        }
      }

      &>.list {
        margin-top: 68px;
        display: grid;
        row-gap: 113px;

        &>.card {
          width: 100%;
          display: flex;
          position: relative;

          &>img {
            width: 496px;
          }

          &>.content {
            position: absolute;

            &>.title {
              position: relative;
              background-color: var(--dark-sky-blue);
              color: white;
              font-size: 25px;
              font-weight: bold;
              display: flex;
              align-items: center;

              &>.number {
                position: absolute;
                color: var(--dark-sky-blue);
                font-size: 66px;
                font-weight: 300;
                font-family: 'nexon';
                top: -55px;
              }
            }

            &>.sub {
              box-sizing: border-box;
              font-size: 14px;
              line-height: 1.71;

              &>b {
                color: var(--dark-sky-blue);
              }
            }
          }
        }

        &>.first {
          &>.content {
            top: 120px;
            left: 470px;

            &>.title {
              width: 360px;
              height: 65px;
              justify-content: center;

              &>.number {
                left: 45px;
              }
            }

            &>.sub {
              padding-left: 45px;
              padding-top: 22px;
            }
          }
        }

        &>.second {
          justify-content: flex-end;

          &>.content {
            top: 80px;
            left: 100px;

            &>.title {
              width: 422px;
              height: 65px;
              padding-left: 23px;
              box-sizing: border-box;

              &>.number {
                left: 0;
              }
            }

            &>.sub {
              padding-left: 23px;
              padding-top: 22px;
            }
          }
        }

        &>.third {
          &>.content {
            top: 100px;
            right: 60px;

            &>.title {
              width: 487px;
              height: 75px;
              justify-content: center;

              &>.number {
                right: 0;
              }
            }

            &>.sub {
              padding-left: 75px;
              padding-top: 22px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width : 1024px) {
  .partner {
    &>.banner {
      height: 299px;
      padding-top: 210px;

      &>.title {
        font-size: 30px;
      }
    }

    &>.section1 {
      height: 840px;

      &>.back {
        height: 317px;

      }

      &>.pack {
        padding-top: 106px;

        &>.top {
          &>.left {
            padding-top: 56px;
            margin-right: 59px;

            &>.title {
              font-size: 27px;
            }

            &>.sub {
              font-size: 14px;
            }
          }

          &>.right {
            width: 340px;
          }
        }

        &>.bottom {
          margin-top: 93px;
          grid-template-columns: repeat(4, 169px);
          column-gap: 10px;

          &>.card {
            height: 325px;
            padding: 19px 0 0 15px;

            &>img {
              width: 37px;
            }

            &>.title {
              font-size: 15px;
              margin-top: 23px;
            }

            &>.sub {
              height: 190px;
              padding: 13px 0 0 15px;
            }
          }
        }
      }
    }

    &>.section2 {
      height: 1189px;

      &>.pack {
        padding-top: 176px;

        &>.back {
          &>div {
            height: 200px;
          }
        }

        &>.first {
          top: 140px;
          right: 350px;
        }

        &>.second {
          right: 270px;
          top: 584px;

          &>div {
            height: 250px;
          }
        }

        &>div>.img-wrapper {
          width: 800px;
          transform: unset;
          grid-template-columns: repeat(4, 228px);
          grid-template-rows: unset;
          column-gap: 10px;
          overflow-x: scroll;
        }

        &>.one {
          &>.title {
            font-size: 27px;

            &>.line {
              width: 216px;
              height: 16px;
              bottom: -2px;
              left: -3px;
            }
          }

          &>.sub {
            font-size: 14px;
            margin-top: 5px;
            margin-bottom: 31px;
          }
        }

        &>.two {
          margin-top: 170px;

          &>.title {
            font-size: 27px;
            margin-bottom: 0;

            &>.line {
              width: 157px;
              height: 16px;
              bottom: -2px;
              left: -2px;
            }
          }

          &>.sub {
            font-size: 14px;
            margin-top: 8px;
            margin-bottom: 26px;
          }
        }
      }
    }

    &>.section3 {
      height: 1500px;

      &>.pack {
        padding-top: 120px;

        &>.category {
          width: 118px;
          height: 30px;
        }

        &>.title {
          font-size: 27px;
          margin-top: 11px;
        }

        &>.list {
          margin-top: 48px;
          row-gap: 185px;

          &>.card {
            &>img {
              width: 344px;
            }

            &>.content {
              &>.title {
                font-size: 23px;
              }

              &>.number {
                font-size: 60px;
              }
            }
          }

          &>.first {
            &>.content {
              left: 320px;

              &>.title {
                width: 318px;
                height: 61px;
              }

              &>.sub {
                padding-top: 14px;
                padding-left: 38px;
              }
            }
          }

          &>.second {
            &>.content {
              left: 50px;

              &>.title {
                width: 331px;
                height: 61px;
              }

              &>.sub {
                padding-top: 14px;
                padding-left: 15px;
              }
            }
          }

          &>.third {
            &>.content {
              right: 35px;
              top: 68px;

              &>.title {
                width: 406px;
                height: 61px;
              }

              &>.sub {
                padding-top: 15px;
                padding-left: 110px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width : 767px) {
  .partner {
    &>.banner {
      height: 209px;
      padding-top: 117px;

      &>.title {
        font-size: 23px;
      }
    }

    &>.section1 {
      height: 1643px;

      &>.back {
        height: 1048px;
      }

      &>.pack {
        padding-top: 94px;

        &>.top {
          flex-direction: column;
          align-items: center;

          &>.left {
            padding: unset;
            margin: unset;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            &>.title {
              font-size: 23px;
              margin-top: 13px;
              margin-bottom: 5px;
            }

            &>.sub {
              font-size: 13px;
            }
          }

          &>.right {
            width: 320px;
            margin-top: 34px;
          }
        }

        &>.bottom {
          grid-template-columns: 320px;
          grid-template-rows: 168px 220px 168px 175px;
          row-gap: 25px;

          &>.card {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-left: unset;
            padding-top: 17px;

            &>.title {
              white-space: nowrap;
              font-size: 18px;
              margin-top: 7px;
            }

            &>.sub {
              height: auto;
              text-align: center;
              padding: 12px 0 19px 0;
            }
          }
        }
      }
    }

    &>.section2 {
      height: 1140px;

      &>.pack {
        padding-top: 185px;

        &>.back {
          &>div {
            width: 150vw;
            height: 300px;
          }
        }

        &>.first {
          top: 81px;
          right: -15%;
        }

        &>.second {
          right: -15%;
          top: 596px;

          &>div {
            height: 246px;
          }
        }

        &>div>.img-wrapper {
          width: 110%;
          overflow-x: scroll;
          -ms-overflow-style: none;
          /* IE and Edge */
          scrollbar-width: none;
          /* Firefox */
        }

        &>div>.img-wrapper::-webkit-scrollbar {
          display: none;
        }

        &>div>.scroll-wrapper {
          overflow: hidden;
          width: 320px;
          height: 3px;
          background-color: #e0e0e0;
          margin-top: 23px;
          position: relative;

          &>.bar {

            position: absolute;
            left: 0;
            top: 0;
            width: 112px;
            height: 100%;
            background-color: black;
          }
        }

        &>.one {
          &>.title {
            font-size: 25px;

            &>.line {
              width: 201px;
            }
          }

          &>.sub {
            margin-top: 9px;
            margin-bottom: 21px;
          }
        }

        &>.two {
          margin-top: 188px;

          &>.title {
            font-size: 25px;

            &>.line {
              width: 153px;
            }
          }

          &>.sub {
            margin-top: 9px;
            margin-bottom: 26px;
          }
        }
      }
    }

    &>.section3 {
      height: 1650px;

      &>.pack {
        padding-top: 122px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &>.back {
          right: -15%;

          &>div {
            width: 120vw;
            height: 257px;
          }
        }

        &>.category {
          width: 123px;
          height: 30px;
        }

        &>.title {
          font-size: 25px;
          margin-top: 13px;
        }

        &>.list {
          margin-top: 23px;
          row-gap: 79px;

          &>.card {
            flex-direction: column;
            align-items: center;

            &>.content {
              width: 100%;
              position: unset;

              &>.title {
                width: 100%;
                height: 51px;
                margin: 56px 0 14px 0;
                font-size: 19px;
                justify-content: flex-start;
                padding-left: 23px;
                box-sizing: border-box;

                &>.number {
                  font-size: 50px;
                  top: -40px;
                  left: 20px;
                }
              }

              &>.sub {
                font-size: 13px;
                padding: 0 0 0 20px;
              }

            }
          }

          &>.third {
            &>.content {
              &>.title {
                font-size: 17px;
              }
            }
          }
        }
      }
    }
  }
}