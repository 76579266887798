
.header{
  z-index: 1700;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  padding: 44px 100px 44px 100px;
      display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
   transition:background-color 0.2s ease-in-out;
&>a {
   &> .logo {
   width: 168px;
   cursor: pointer;
 }
}
 &>.link-wrapper{
   display: grid;
   grid-template-columns: repeat(5,auto);
   column-gap: 39px;
   &>a{
     white-space: nowrap;
    color: var(--navy);
    font-size: 16px;
    font-weight: bold;
   }
 }
}
@media screen and (max-width : 1024px) {
  .header{
    padding: 36px 32px 36px 32px;
    &>a{
      &>.logo{
        width: 127px;
      }
    }
    &>.link-wrapper{
      column-gap: 27px;
      &>a{
        font-size: 13px;
      }
    }
  }

}
@media screen and (max-width : 767px) {
  .header{
    padding: 32px 20px 32px 20px;
    height: 60px;
    &>.menu{
      cursor: pointer;
      width: 28px;
    }
    &>.mb-menu{
      overflow: hidden;
      transition: height 0.4s ease-in-out;
      left: 0;
      top: 60px;
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: white;
      display: grid;
      justify-content: center;
      grid-template-rows: repeat(5,72px);

      &>a{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        position: relative;
        &>.line{
          width: 72px;
          height: 1px;
          position: absolute;
          bottom: 0;
          background-color: #dbdbdb;
        }
      }
    }
  }
}