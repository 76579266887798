.point {
  height: 1430px;
  width: 100%;
  position: relative;
  padding-top: 182px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.point > .back {
  width: 100%;
  height: 533px;
  background-color: var(--navy);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.point > .back > .pack {
  text-align: right;
}

.point > .back > .pack > img {
  width: 513px;
}

.point > .pack {
  padding: 0 82.8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.point > .pack > .category {
  width: 142px;
  height: 33px;
  background-color: #76c7d3;
  border-radius: 16.5px;
  color: #08174a;
  font-family: 'gmarket';
  font-weight: bold;
  font-size: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.point > .pack > .title {
  color: white;
  line-height: 1.31;
  font-size: 35px;
  margin-top: 10px;
  margin-bottom: 53px;
  font-family: "score";
}

.point > .pack > .title > b {
  font-family: "score";
  font-weight: bold;
}

.point > .pack > .list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (405px)[2];
      grid-template-columns: repeat(2, 405px);
  -webkit-column-gap: 15px;
          column-gap: 15px;
  row-gap: 40px;
}

.point > .pack > .list > .card {
  height: 392px;
  border-radius: 13px;
  background-color: white;
  -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.13);
  text-align: center;
}

.point > .pack > .list > .card > .title {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.point > .pack > .list > .card > .title > b {
  color: var(--dark-sky-blue);
}

.point > .pack > .list > .card > .content {
  white-space: pre-line;
  height: 46px;
  font-size: 16px;
  line-height: 1.38;
  margin-top: 9px;
}

.hoodpoint,
.homepoint {
  height: 1032px;
}

.hoodpoint > .pack,
.homepoint > .pack {
  padding: unset;
}

.hoodpoint > .pack > .list,
.homepoint > .pack > .list {
  -ms-grid-columns: (321px)[3];
      grid-template-columns: repeat(3, 321px);
}

.hoodpoint > .pack > .list > .card,
.homepoint > .pack > .list > .card {
  height: 360px;
}

.hoodpoint > .pack > .list > .card > .title,
.homepoint > .pack > .list > .card > .title {
  font-size: 19px;
  margin-top: 25px;
}

.hoodpoint > .pack > .list > .card > .content,
.homepoint > .pack > .list > .card > .content {
  font-size: 13px;
  margin-top: 13px;
}

.homepoint {
  height: 897px;
}

.jointpoint {
  height: 1518px;
}

.birdpoint {
  height: 1436px;
}

.aircarepoint {
  height: 1545px;
}

@media screen and (max-width: 1024px) {
  .point {
    height: 1012px;
    padding-top: 114px;
  }
  .point > .back {
    height: 300px;
  }
  .point > .back > .pack > img {
    width: 335px;
  }
  .point > .pack {
    padding: 0 84px;
  }
  .point > .pack > .category {
    width: 133px;
    height: 33px;
  }
  .point > .pack > .title {
    font-size: 25px;
    margin-bottom: 26px;
  }
  .point > .pack > .list {
    -ms-grid-columns: (261px)[2];
        grid-template-columns: repeat(2, 261px);
    -webkit-column-gap: 9px;
            column-gap: 9px;
    row-gap: 34px;
  }
  .point > .pack > .list > .card {
    height: 279px;
  }
  .point > .pack > .list > .card > .title {
    font-size: 15px;
    margin-top: 18px;
    white-space: pre-wrap;
  }
  .point > .pack > .list > .card > .content {
    font-size: 13px;
    height: 39px;
    margin-top: 10px;
  }
  .hoodpoint,
  .homepoint {
    height: 713px;
  }
  .hoodpoint > .pack,
  .homepoint > .pack {
    padding: 0;
  }
  .hoodpoint > .pack > .list,
  .homepoint > .pack > .list {
    -ms-grid-columns: (224px)[3];
        grid-template-columns: repeat(3, 224px);
    -webkit-column-gap: 16px;
            column-gap: 16px;
  }
  .hoodpoint > .pack > .list > .card,
  .homepoint > .pack > .list > .card {
    height: 300px;
  }
  .hoodpoint > .pack > .list > .card > .title,
  .homepoint > .pack > .list > .card > .title {
    margin-top: 20px;
  }
  .hoodpoint > .pack > .list > .card > .content,
  .homepoint > .pack > .list > .card > .content {
    margin-top: 14px;
  }
  .hoodpoint > .pack > .list > .card:nth-child(odd) > .title,
  .homepoint > .pack > .list > .card:nth-child(odd) > .title {
    margin-top: 30px;
  }
  .hoodpoint > .pack > .list > .card:nth-child(odd) > .content,
  .homepoint > .pack > .list > .card:nth-child(odd) > .content {
    margin-top: 24px;
  }
}

@media screen and (max-width: 767px) {
  .point {
    height: 1925px;
    padding-top: 252px;
  }
  .point > .back {
    height: 533px;
  }
  .point > .back > .pack > img {
    width: 236px;
  }
  .point > .pack {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .point > .pack > .category {
    width: 142px;
    height: 30px;
  }
  .point > .pack > .title {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 36px;
  }
  .point > .pack > .list {
    -ms-grid-columns: unset;
        grid-template-columns: unset;
  }
  .point > .pack > .list > .card {
    width: 100%;
    height: 313px;
  }
  .point > .pack > .list > .card > .title {
    margin-top: 16px;
    font-size: 18px;
    line-height: 1.28;
  }
  .point > .pack > .list > .card > .sub {
    margin-top: 8px;
  }
  .hoodpoint,
  .homepoint {
    height: 1616px;
  }
  .hoodpoint > .pack > .list > .card > .title,
  .homepoint > .pack > .list > .card > .title {
    margin-top: 16px;
  }
  .hoodpoint > .pack > .list > .card > .content,
  .homepoint > .pack > .list > .card > .content {
    margin-top: 8px;
  }
  .hoodpoint > .pack > .list > .card:nth-child(odd) > .title,
  .homepoint > .pack > .list > .card:nth-child(odd) > .title {
    margin-top: 22px;
  }
  .hoodpoint > .pack > .list > .card:nth-child(odd) > .content,
  .homepoint > .pack > .list > .card:nth-child(odd) > .content {
    margin-top: 9px;
  }
}
