.store {
  width: 100%;
  height: 610px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: image-set(url('/public/assets/common/storebackground.png') 1x, url('/public/assets/common/storebackground@2x.png') 2x, url('/public/assets/common/storebackground@3x.png') 3x);

  &>.pack {
    padding-top: 210px;
    box-sizing: border-box;
    line-height: 1.34;
    color: white;
    font-size: 35px;

    &>.title {
      font-family: "score";

      &>b {
        font-family: "score";
        font-weight: bold;
      }
    }

    &>.button-wrapper {
      margin-top: 37px;
      display: grid;
      row-gap: 17px;

      &>a {
        color: black;
        cursor: pointer;
        width: 436px;
        height: 63px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 23px;
        padding-right: 16px;
        box-sizing: border-box;
        font-size: 21px;

        &>.left {
          display: flex;
          align-items: center;

          &>.naver {
            height: 40px;
          }

          &>.img-wrapper {
            width: 48px;
            text-align: center;
            margin-right: 16.7px;

            &>img {
              width: fit-content;
            }
          }
        }

        &>img {
          width: 50.6px;
        }
      }
    }
  }
}

@media screen and (max-width : 1024px) {
  .store {
    &>.pack {
      font-size: 30px;

      &>.button-wrapper {
        &>a {
          width: 391px;
          height: 55px;
          font-size: 20px;

          &>.left {
            &>.naver {
              &>.img-wrapper {
                &>img {
                  width: 33.3px;
                }
              }
            }

            &>.img-wrapper {
              margin-right: 20px;

              &>img {
                width: 36px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width : 767px) {
  .store {
    height: 587px;

    &>.pack {
      &>.title {
        font-size: 25px;
        line-height: 1.32;
      }

      &>.button-wrapper {
        margin-top: 33px;
        row-gap: 25px;

        &>a {
          width: 100%;
          height: 55px;
          font-size: 18px;
          white-space: nowrap;

          &>.left {
            &>.img-wrapper {
              height: 36px;
              width: 36px;
              margin-right: 13px;
            }
          }

          &>img {
            width: 40px;
          }
        }
      }
    }
  }
}