.about {
  width: 100%;
  padding-top: 95px;
}

.about > .ceo > .section1 {
  height: 416px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.about > .ceo > .section1 > .bottom {
  width: 100%;
  height: 223px;
  background-color: #e5f5f9;
}

.about > .ceo > .section1 > .bottom > .pack {
  padding-top: 46px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.about > .ceo > .section1 > .bottom > .pack > .content > .subject {
  color: var(--dark-sky-blue);
}

.about > .ceo > .section1 > .bottom > .pack > .content > .title {
  font-family: 'score';
  margin-top: 10px;
  font-size: 35px;
  line-height: 1.37;
}

.about > .ceo > .section1 > .bottom > .pack > .content > .title > b {
  font-family: 'score';
}

.about > .ceo > .section1 > .bottom > .pack > img {
  position: absolute;
  width: 442px;
  bottom: 0;
  right: 0;
}

.about > .ceo > .section2 {
  height: 1240px;
  width: 100%;
  padding-top: 75px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.about > .ceo > .section2 > .pack {
  position: relative;
  height: 100%;
}

.about > .ceo > .section2 > .pack > .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.about > .ceo > .section2 > .pack > .list > .line {
  margin: 27.5px 0;
  width: 789px;
  height: 1px;
  background-color: var(--very-light-pink);
}

.about > .ceo > .section2 > .pack > .list > .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.about > .ceo > .section2 > .pack > .list > .box > img {
  width: 165px;
  margin-right: 44px;
}

.about > .ceo > .section2 > .pack > .list > .box > .content > .title {
  line-height: 1.43;
  color: var(--dark-sky-blue);
  font-size: 21px;
  margin-bottom: 12px;
  font-family: 'score';
}

.about > .ceo > .section2 > .pack > .list > .box > .content > .title > b {
  font-family: 'score';
}

.about > .ceo > .section2 > .pack > .list > .box > .content > .subject {
  line-height: 1.71;
  font-size: 17px;
}

.about > .ceo > .section2 > .pack > .list > .second {
  margin: 10px 0;
}

.about > .ceo > .section2 > .pack > .list > .third {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.about > .ceo > .section2 > .pack > .name {
  margin-top: 84px;
  text-align: right;
  font-size: 17px;
  font-weight: 500;
  color: var(--navy);
}

.about > .ceo > .section2 > .pack > img {
  position: absolute;
  width: 629px;
  bottom: 0;
  right: 0;
}

.about > .company > .section1 {
  height: 857px;
  width: 100%;
}

.about > .company > .section1 > .pack {
  height: 100%;
  padding-top: 108px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.about > .company > .section1 > .pack > .subject {
  font-family: 'gmarket';
  font-weight: bold;
  color: var(--dark-sky-blue);
}

.about > .company > .section1 > .pack > .title {
  font-size: 35px;
  color: var(--navy);
  line-height: 1.37;
  margin-top: 10px;
  margin-bottom: 22px;
  font-family: "score";
}

.about > .company > .section1 > .pack > .title > b {
  font-family: "score";
  font-weight: bold;
}

.about > .company > .section1 > .pack > .content {
  font-size: 16px;
  line-height: 1.63;
}

.about > .company > .section1 > .pack > .circle-wrapper {
  -ms-flex-item-align: center;
      align-self: center;
  margin-top: 67px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.about > .company > .section1 > .pack > .circle-wrapper > .circle {
  width: 269px;
  height: 269px;
  background-color: rgba(201, 201, 201, 0.53);
  border-radius: 269px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  padding-top: 68px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.about > .company > .section1 > .pack > .circle-wrapper > .circle > .title {
  font-size: 25px;
  margin-bottom: 9px;
  color: var(--navy);
}

.about > .company > .section1 > .pack > .circle-wrapper > .circle > .content {
  line-height: 1.56;
  font-size: 16px;
}

.about > .company > .section1 > .pack > .circle-wrapper > .blue {
  background-color: rgba(70, 186, 209, 0.24);
}

.about > .company > .section1 > .pack > .circle-wrapper > .first {
  -webkit-transform: translateX(25px);
          transform: translateX(25px);
}

.about > .company > .section1 > .pack > .circle-wrapper > .third {
  -webkit-transform: translateX(-25px);
          transform: translateX(-25px);
}

.about > .company > .section2 {
  height: 785px;
  width: 100%;
}

.about > .company > .section2 > .pack {
  height: 100%;
  position: relative;
  padding-left: 162px;
  padding-top: 154px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.about > .company > .section2 > .pack > .background {
  position: absolute;
  left: 0;
  top: 0;
  width: 456px;
  z-index: -1;
}

.about > .company > .section2 > .pack > .title-wrapper > .cat {
  font-size: 15px;
  font-family: 'gmarket';
  color: var(--dark-sky-blue);
  margin-bottom: 8px;
}

.about > .company > .section2 > .pack > .title-wrapper > .title {
  margin-top: 8px;
  font-size: 35px;
  line-height: 1.34;
  color: var(--navy);
  font-family: "score";
}

.about > .company > .section2 > .pack > .title-wrapper > .title > b {
  font-family: "score";
  font-weight: bold;
}

.about > .company > .section2 > .pack > .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  width: 100%;
  margin-top: 93px;
}

.about > .company > .section2 > .pack > .list > .wrapper {
  width: 489px;
  color: var(--dark-sky-blue);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 91.5px;
  border-bottom: solid 1px var(--dark-sky-blue);
}

.about > .company > .section2 > .pack > .list > .wrapper > .number {
  font-size: 50px;
  font-family: 'score';
  margin-right: 15px;
  font-weight: 500;
  width: 67px;
  height: 50px;
  color: var(--navy);
}

.about > .company > .section2 > .pack > .list > .wrapper > .content {
  font-size: 23px;
  color: black;
  font-weight: 500;
}

.about > .company > .section2 > .pack > .list > .wrapper > .content > b {
  position: relative;
}

.about > .company > .section2 > .pack > .list > .wrapper > .content > b > div {
  position: absolute;
  width: 100%;
  height: 19px;
  background-color: #47bad2;
  opacity: 0.3;
  bottom: -5px;
  left: 0;
  z-index: -1;
}

.about > .company > .section3 {
  height: 1493px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-top: 185px;
}

.about > .company > .section3 > .pack > .title-wrapper > .category {
  font-size: 15px;
  font-family: 'gmarket';
  font-weight: bold;
  color: var(--dark-sky-blue);
  margin-bottom: 8px;
}

.about > .company > .section3 > .pack > .title-wrapper > .title {
  line-height: 1.34;
  font-size: 35px;
  color: var(--navy);
  margin-top: 8px;
  font-family: "score";
}

.about > .company > .section3 > .pack > .title-wrapper > .title > b {
  font-family: "score";
  font-weight: bold;
}

.about > .company > .section3 > .pack > .list {
  margin-top: 54px;
  display: -ms-grid;
  display: grid;
  row-gap: 17px;
}

.about > .company > .section3 > .pack > .list > .card {
  width: 100%;
  height: 185px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 10px;
  padding-left: 37px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.about > .company > .section3 > .pack > .list > .card > img {
  width: 120px;
  height: 120px;
  margin-right: 35px;
}

.about > .company > .section3 > .pack > .list > .card > .content {
  color: var(--navy);
}

.about > .company > .section3 > .pack > .list > .card > .content > .title {
  font-size: 23px;
  font-weight: bold;
}

.about > .company > .section3 > .pack > .list > .card > .content > .line {
  width: 16px;
  height: 2px;
  background-color: var(--navy);
  margin: 10px 0;
}

.about > .company > .section3 > .pack > .list > .card > .content > .content {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.64;
  white-space: pre-wrap;
}

.about > .company > .section3 > .pack > .list > .card:nth-child(even) {
  background-color: #aae0ea;
}

.about > .company > .section3 > .pack > .list > .card:nth-child(odd) {
  background-color: #dff2f7;
}

.about > .company > .section4 {
  height: 900px;
  width: 100%;
  position: relative;
}

.about > .company > .section4 > .back {
  width: 100%;
  height: 505px;
  background-color: #dff2f7;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.about > .company > .section4 > .pack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.about > .company > .section4 > .pack > .category {
  font-size: 15px;
  font-family: 'gmarket';
  color: var(--dark-sky-blue);
}

.about > .company > .section4 > .pack > .title {
  font-size: 35px;
  line-height: 1.31;
  color: var(--navy);
  margin: 23px 0;
  font-family: "score";
}

.about > .company > .section4 > .pack > .title > b {
  font-family: "score";
  font-weight: bold;
}

.about > .company > .section4 > .pack > .sub {
  font-size: 16px;
  line-height: 1.63;
}

.about > .company > .section4 > .pack > .list {
  margin-top: 74px;
  -ms-flex-item-align: center;
      align-self: center;
  width: 900px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  gap: 35px 23px;
}

.about > .company > .section4 > .pack > .list > .doc {
  width: 144px;
}

.about > .company > .section4 > .pack > .list > .doc > .name {
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
}

@media screen and (max-width: 1024px) {
  .about > .ceo > .section1 {
    height: 275px;
  }
  .about > .ceo > .section1 > .bottom {
    height: 153px;
  }
  .about > .ceo > .section1 > .bottom > .pack {
    padding-top: 30px;
  }
  .about > .ceo > .section1 > .bottom > .pack > .content > .title {
    font-size: 25px;
    margin-top: 4px;
  }
  .about > .ceo > .section1 > .bottom > .pack > .content > .subject {
    font-size: 13px;
  }
  .about > .ceo > .section1 > .bottom > .pack > img {
    width: 284px;
  }
  .about > .ceo > .section2 {
    height: 1055px;
    padding-top: 66px;
  }
  .about > .ceo > .section2 > .pack > .list > .box > img {
    width: 123px;
    margin-right: 34px;
  }
  .about > .ceo > .section2 > .pack > .list > .box > .content > .title {
    font-size: 18px;
  }
  .about > .ceo > .section2 > .pack > .list > .box > .content > .subject {
    font-size: 14px;
  }
  .about > .ceo > .section2 > .pack > .list > .line {
    width: 100%;
  }
  .about > .ceo > .section2 > .pack > .name {
    margin-top: 111px;
  }
  .about > .ceo > .section2 > .pack > img {
    width: 534px;
  }
  .about > .company > .section1 {
    height: 649px;
  }
  .about > .company > .section1 > .pack {
    padding-top: 96px;
  }
  .about > .company > .section1 > .pack > .subject {
    font-size: 13px;
  }
  .about > .company > .section1 > .pack > .title {
    font-size: 25px;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .about > .company > .section1 > .pack > .content {
    font-size: 14px;
  }
  .about > .company > .section1 > .pack > .circle-wrapper {
    margin-top: 38px;
  }
  .about > .company > .section1 > .pack > .circle-wrapper > .circle {
    width: 210px;
    height: 210px;
    padding-top: 50px;
  }
  .about > .company > .section1 > .pack > .circle-wrapper > .circle > .title {
    font-size: 19px;
  }
  .about > .company > .section1 > .pack > .circle-wrapper > .circle > .content {
    font-size: 14px;
  }
  .about > .company > .section1 > .pack > .circle-wrapper > .first {
    -webkit-transform: translateX(15px);
            transform: translateX(15px);
  }
  .about > .company > .section1 > .pack > .circle-wrapper > .third {
    -webkit-transform: translateX(-15px);
            transform: translateX(-15px);
  }
  .about > .company > .section2 {
    height: 533px;
  }
  .about > .company > .section2 > .pack {
    padding-left: 155px;
    padding-top: 85px;
  }
  .about > .company > .section2 > .pack > .background {
    width: 310px;
  }
  .about > .company > .section2 > .pack > .title-wrapper > .cat {
    font-size: 13px;
    margin-bottom: 6px;
  }
  .about > .company > .section2 > .pack > .title-wrapper > .title {
    font-size: 29px;
  }
  .about > .company > .section2 > .pack > .list {
    margin-top: 70px;
  }
  .about > .company > .section2 > .pack > .list > .wrapper {
    width: 344px;
    height: 64px;
  }
  .about > .company > .section2 > .pack > .list > .wrapper > .number {
    font-size: 35px;
    margin-right: 13px;
    width: 47px;
    height: 35px;
  }
  .about > .company > .section2 > .pack > .list > .wrapper > .content {
    font-size: 16px;
    white-space: nowrap;
  }
  .about > .company > .section2 > .pack > .list > .wrapper > .content > b > div {
    width: 103%;
    height: 13px;
    bottom: -2px;
    left: -3px;
  }
  .about > .company > .section3 {
    height: 1416px;
    padding-top: 158px;
  }
  .about > .company > .section3 > .pack > .title-wrapper > .category {
    font-size: 13px;
    margin-bottom: 5px;
  }
  .about > .company > .section3 > .pack > .title-wrapper > .title {
    font-size: 25px;
  }
  .about > .company > .section3 > .pack > .list {
    margin-top: 44px;
  }
  .about > .company > .section3 > .pack > .list > .card > img {
    width: 102px;
    height: 102px;
    margin-right: 27px;
  }
  .about > .company > .section3 > .pack > .list > .card > .content > .title {
    font-size: 20px;
  }
  .about > .company > .section4 {
    height: 780px;
  }
  .about > .company > .section4 > .back {
    height: 457px;
  }
  .about > .company > .section4 > .pack > .category {
    font-size: 13px;
  }
  .about > .company > .section4 > .pack > .title {
    font-size: 25px;
    margin: 15px 0;
  }
  .about > .company > .section4 > .pack > .sub {
    font-size: 14px;
  }
  .about > .company > .section4 > .pack > .list {
    width: 100%;
    margin-top: 68px;
    gap: 30px 22px;
  }
  .about > .company > .section4 > .pack > .list > .doc {
    width: 121px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .about > .company > .section4 > .pack > .list > .doc > .name {
    margin-top: 9px;
    font-size: 13px;
  }
}

@media screen and (max-width: 767px) {
  .about > .ceo > .section1 {
    background-color: white;
    height: 395px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .about > .ceo > .section1 > .pack {
    height: 285px;
  }
  .about > .ceo > .section1 > .pack > .title {
    font-size: 30px;
    line-height: 1.23;
    color: var(--navy);
    padding-top: 63px;
  }
  .about > .ceo > .section1 > .bottom {
    height: 109px;
  }
  .about > .ceo > .section1 > .bottom > .pack {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .about > .ceo > .section1 > .bottom > .pack > img {
    width: 271px;
    right: unset;
  }
  .about > .ceo > .section2 {
    background-color: white;
    height: 1831px;
  }
  .about > .ceo > .section2 > .pack > .list > .line {
    margin: 60px 0;
  }
  .about > .ceo > .section2 > .pack > .list > .box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
  }
  .about > .ceo > .section2 > .pack > .list > .box > img {
    margin-right: unset;
  }
  .about > .ceo > .section2 > .pack > .list > .box > .content > .title {
    font-size: 25px;
    margin-top: 16px;
    margin-bottom: 9px;
  }
  .about > .ceo > .section2 > .pack > .list > .box > .content > .subject {
    font-size: 13px;
  }
  .about > .ceo > .section2 > .pack > .name {
    text-align: center;
    margin-top: 101px;
  }
  .about > .ceo > .section2 > .pack > img {
    width: 380px;
    right: -100px;
  }
  .about > .company > .section1 {
    height: 1155px;
  }
  .about > .company > .section1 > .pack {
    padding-top: 79px;
  }
  .about > .company > .section1 > .pack > .subject {
    font-size: 15px;
  }
  .about > .company > .section1 > .pack > .title {
    font-size: 30px;
  }
  .about > .company > .section1 > .pack > .content {
    font-size: 13px;
    margin-top: 26px;
  }
  .about > .company > .section1 > .pack > .circle-wrapper {
    margin-top: 76px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .about > .company > .section1 > .pack > .circle-wrapper > .circle {
    width: 197px;
    height: 197px;
    padding-top: 53px;
  }
  .about > .company > .section1 > .pack > .circle-wrapper > .circle > .title {
    margin-bottom: 5px;
    font-size: 20px;
  }
  .about > .company > .section1 > .pack > .circle-wrapper > .circle > .content {
    font-size: 13px;
  }
  .about > .company > .section1 > .pack > .circle-wrapper > .first {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  .about > .company > .section1 > .pack > .circle-wrapper > .third {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  .about > .company > .section2 {
    height: 755px;
  }
  .about > .company > .section2 > .pack {
    padding-left: unset;
    padding-top: 65px;
  }
  .about > .company > .section2 > .pack > .background {
    width: 227px;
    height: 452.2px;
  }
  .about > .company > .section2 > .pack > .title-wrapper {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding-left: 20px;
  }
  .about > .company > .section2 > .pack > .title-wrapper > .cat {
    font-size: 15px;
    margin-bottom: 8px;
  }
  .about > .company > .section2 > .pack > .title-wrapper > .title {
    font-size: 30px;
  }
  .about > .company > .section2 > .pack > .list {
    margin-top: 306px;
  }
  .about > .company > .section2 > .pack > .list > .wrapper {
    width: 100%;
    height: 59px;
  }
  .about > .company > .section2 > .pack > .list > .wrapper > .number {
    font-size: 32px;
    width: 43px;
    height: 32px;
  }
  .about > .company > .section2 > .pack > .list > .wrapper > .content {
    font-size: 15px;
  }
  .about > .company > .section2 > .pack > .list > .wrapper > .content > b > div {
    width: 101%;
  }
  .about > .company > .section3 {
    height: 1807px;
    padding-top: 180px;
  }
  .about > .company > .section3 > .pack > .title-wrapper > .category {
    margin-bottom: 8px;
    font-size: 15px;
  }
  .about > .company > .section3 > .pack > .title-wrapper > .title {
    font-size: 30px;
  }
  .about > .company > .section3 > .pack > .list {
    margin-top: 37px;
  }
  .about > .company > .section3 > .pack > .list > .card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-top: 25px;
    padding-left: 18px;
  }
  .about > .company > .section3 > .pack > .list > .card > img {
    width: 82px;
    height: 82px;
  }
  .about > .company > .section3 > .pack > .list > .card > .content > .title {
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 9px;
  }
  .about > .company > .section3 > .pack > .list > .card > .content > .content {
    font-size: 13px;
  }
  .about > .company > .section3 > .pack > .list > .card:nth-child(1) {
    height: 273.5px;
  }
  .about > .company > .section3 > .pack > .list > .card:nth-child(2) {
    height: 323.5px;
  }
  .about > .company > .section3 > .pack > .list > .card:nth-child(3) {
    height: 270px;
  }
  .about > .company > .section3 > .pack > .list > .card:nth-child(4) {
    height: 300px;
  }
  .about > .company > .section4 {
    height: 980px;
  }
  .about > .company > .section4 > .pack {
    position: relative;
  }
  .about > .company > .section4 > .pack > .category {
    font-size: 15px;
  }
  .about > .company > .section4 > .pack > .title {
    font-size: 30px;
  }
  .about > .company > .section4 > .pack > .sub {
    font-size: 13px;
  }
  .about > .company > .section4 > .pack > .list {
    gap: 69px 16px;
  }
  .about > .company > .section4 > .pack > .list > .doc {
    width: 96px;
  }
  .about > .company > .section4 > .pack > .list > .doc > .name {
    white-space: pre-line;
    font-size: 12px;
    width: 100px;
  }
  .about > .company > .section4 > .back-list {
    width: 100%;
    z-index: -1;
    left: 0;
    bottom: 0;
    position: absolute;
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: (119px)[3];
        grid-template-rows: repeat(3, 119px);
    row-gap: 123px;
  }
  .about > .company > .section4 > .back-list > .back {
    width: 100%;
    background-color: #dff2f7;
  }
}
