.sponsor {
  width: 100%;
  height: 1028px;
  background-color: #e0f3f7;
  padding-top: 113px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.sponsor > .pack {
  padding: 0 62px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.sponsor > .pack > .title {
  font-size: 34px;
  line-height: 1.38;
  color: var(--navy);
  font-family: "score";
}

.sponsor > .pack > .title > b {
  font-family: "score";
  font-weight: bold;
}

.sponsor > .pack > .content {
  margin-top: 81.7px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (425.3px)[2];
      grid-template-columns: repeat(2, 425.3px);
  -webkit-column-gap: 17.7px;
          column-gap: 17.7px;
  row-gap: 102.7px;
}

.sponsor > .pack > .content > .card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.sponsor > .pack > .content > .card > .icon {
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  top: -63px;
  right: 50px;
  z-index: 1;
  opacity: 0.4;
}

.sponsor > .pack > .content > .card > .medi {
  top: -72px;
  right: 40px;
}

.sponsor > .pack > .content > .card > .school {
  top: -65px;
  right: 24px;
}

.sponsor > .pack > .content > .card > .title {
  width: 160px;
  height: 47px;
  border-radius: 23px;
  background-color: var(--seafoam-blue);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
  font-size: 20px;
  position: absolute;
  top: -25px;
  z-index: 150;
}

.sponsor > .pack > .content > .card > .content-wrapper {
  z-index: 100;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.sponsor > .pack > .content > .card > .content-wrapper > img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.sponsor > .pack > .content > .card > .content-wrapper > .line {
  width: 353px;
  height: 1px;
  background-color: var(--very-light-pink);
  margin-bottom: 11.5px;
}

.sponsor > .pack > .content > .card > .content-wrapper > span {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;
}

.sponsor > .pack > .content > .card:nth-child(1) > .content-wrapper > img {
  margin-top: 20px;
}

.sponsor > .pack > .content > .card:nth-child(2) > .title {
  width: 190px;
}

.sponsor > .pack > .content > .card:nth-child(2) > .content-wrapper > img {
  margin-top: 10px;
}

.sponsor > .pack > .content > .card:nth-child(4) > .content-wrapper > img {
  margin-top: 20px;
}

.sponsor > .pack > .content > .first {
  height: 290.5px;
}

.sponsor > .pack > .content > .two {
  height: 203.7px;
}

@media screen and (max-width: 1024px) {
  .sponsor {
    padding-top: 125px;
  }
  .sponsor > .pack {
    padding: unset;
  }
  .sponsor > .pack > .title {
    font-size: 27px;
  }
  .sponsor > .pack > .content {
    margin-top: 72px;
    -ms-grid-columns: (344px)[2];
        grid-template-columns: repeat(2, 344px);
  }
  .sponsor > .pack > .content > .card {
    height: 171px;
  }
  .sponsor > .pack > .content > .card > .icon {
    width: 45px;
    top: -50px;
    right: 30px;
  }
  .sponsor > .pack > .content > .card > .title {
    width: 130px;
    height: 38px;
    font-size: 15px;
  }
  .sponsor > .pack > .content > .card > .medi {
    width: 52px;
    top: -62px;
    right: 20px;
  }
  .sponsor > .pack > .content > .card > .school {
    width: 77px;
    top: -55px;
    right: 20px;
  }
  .sponsor > .pack > .content > .card > .content-wrapper > .line {
    width: 285.5px;
  }
  .sponsor > .pack > .content > .card > .content-wrapper > span {
    font-size: 13px;
  }
  .sponsor > .pack > .content > .card > .content-wrapper > img {
    width: 100%;
  }
  .sponsor > .pack > .content > .card:nth-child(2) > .title {
    width: 154px;
  }
  .sponsor > .pack > .content > .card:nth-child(4) > .icon {
    width: 53px;
  }
  .sponsor > .pack > .content > .first {
    height: 235px;
  }
  .sponsor > .pack > .content > .first > .content-wrapper > img {
    width: 286px;
  }
  .sponsor > .pack > .content > .two {
    height: 171px;
  }
  .sponsor > .pack > .content > .two > .content-wrapper > img {
    width: 279px;
  }
}

@media screen and (max-width: 767px) {
  .sponsor {
    height: 1669px;
    padding-top: 146px;
  }
  .sponsor > .pack > .title {
    font-size: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .sponsor > .pack > .content {
    -ms-grid-columns: unset;
        grid-template-columns: unset;
  }
  .sponsor > .pack > .content > .card > .title {
    font-size: 20px;
    width: 160.5px;
    height: 45px;
  }
  .sponsor > .pack > .content > .card:nth-child(1) {
    height: 245px;
  }
  .sponsor > .pack > .content > .card:nth-child(1) > .icon {
    right: 15px;
  }
  .sponsor > .pack > .content > .card:nth-child(1) > .content-wrapper {
    height: 245px;
  }
  .sponsor > .pack > .content > .card:nth-child(1) > .content-wrapper > img {
    margin-top: 25px;
    width: 270px;
  }
  .sponsor > .pack > .content > .card:nth-child(2) {
    height: 204px;
  }
  .sponsor > .pack > .content > .card:nth-child(2) > .title {
    width: 172px;
  }
  .sponsor > .pack > .content > .card:nth-child(2) > .content-wrapper > img {
    width: 270px;
    margin-top: 15px;
  }
  .sponsor > .pack > .content > .card:nth-child(3) {
    height: 225px;
  }
  .sponsor > .pack > .content > .card:nth-child(3) > .content-wrapper {
    height: 224px;
  }
  .sponsor > .pack > .content > .card:nth-child(4) {
    height: 198px;
  }
  .sponsor > .pack > .content > .card:nth-child(4) > .icon {
    right: 15px;
  }
  .sponsor > .pack > .content > .card:nth-child(4) > .content-wrapper {
    padding-top: 37px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: 200px;
  }
  .sponsor > .pack > .content > .card:nth-child(4) > .content-wrapper > img {
    width: 236px;
  }
}
