.product {
  width: 100%;
}

.product > .banner {
  height: 370px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: -webkit-image-set(url("/public/assets/product/banner.png") 1x, url("/public/assets/product/banner@2x.png") 2x, url("/public/assets/product/banner@3x.png") 3x);
  background-image: image-set(url("/public/assets/product/banner.png") 1x, url("/public/assets/product/banner@2x.png") 2x, url("/public/assets/product/banner@3x.png") 3x);
  text-align: center;
  padding-top: 234px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.product > .banner > .title {
  font-size: 39px;
  font-weight: bold;
  color: white;
}

.product > .aircon > .section8 {
  width: 100%;
  height: 1200px;
  background-color: #f8f8f8;
  padding-top: 120px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.product > .aircon > .section8 > .pack > .title {
  font-size: 35px;
  font-weight: bold;
  line-height: 1.37;
  color: var(--navy);
  text-align: center;
  font-family: "score";
}

.product > .aircon > .section8 > .pack > .title > b {
  font-family: "score";
  font-weight: bold;
}

.product > .aircon > .section8 > .pack > .list {
  display: -ms-grid;
  display: grid;
  margin-top: 57px;
  row-gap: 48px;
}

.product > .aircon > .section8 > .pack > .list > .card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.product > .aircon > .section8 > .pack > .list > .card > img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 37px;
}

.product > .aircon > .section8 > .pack > .list > .card > .content {
  width: 561px;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .title {
  width: 100%;
  height: 53px;
  background-color: var(--dark-sky-blue);
  padding-left: 34px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 22px;
  font-weight: bold;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .width-line {
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .subject {
  height: 55.5px;
  width: 100%;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 31px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-weight: 500;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .subject b {
  font-size: 18px;
  color: var(--dark-sky-blue);
  margin-right: 15px;
  white-space: nowrap;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .three {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: unset;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .three > .left {
  width: 249px;
  padding-left: 30.5px;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .three > .right {
  width: 198px;
  padding-left: 23.1px;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .three > .pax {
  width: 114px;
  padding-left: 14.9px;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .three > div {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .three > .left,
.product > .aircon > .section8 > .pack > .list > .card > .content > .three .right {
  border-right: solid 1px #e0e0e0;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .fix {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  padding: unset;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .fix > div {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .fix > .left {
  padding-left: 31px;
  border-right: solid 1px #e0e0e0;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .fix > .right {
  padding-left: 15px;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .two > .left {
  width: 219px;
  border-right: solid 1px #e0e0e0;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .two > .right {
  padding-left: 30.5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .third {
  display: -ms-grid;
  display: grid;
  width: 100%;
  font-size: 18px;
  -ms-grid-columns: (1fr)[4];
      grid-template-columns: repeat(4, 1fr);
  padding: unset;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .third > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
}

.product > .aircon > .section8 > .pack > .list > .card > .content > .third > div:nth-child(n+2) {
  border-left: solid 1px #e0e0e0;
}

@media screen and (max-width: 1024px) {
  .product > .banner {
    height: 298px;
    padding-top: 210px;
  }
  .product > .banner > .title {
    font-size: 30px;
  }
  .product > .aircon > .section8 {
    height: 1029px;
    padding-top: 124px;
  }
  .product > .aircon > .section8 > .pack > .title {
    font-size: 25px;
  }
  .product > .aircon > .section8 > .pack > .list {
    margin-top: 52px;
    row-gap: 48px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > img {
    margin-right: 25px;
    width: 169px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content {
    width: 486px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .title {
    height: 50px;
    padding-left: 38px;
    font-size: 18px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .subject {
    height: 50px;
    padding-left: 40px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .subject b {
    font-size: 16px;
    margin-right: 40px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .subject span {
    font-size: 16px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .fix {
    padding: unset;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .fix > .left {
    padding-left: 40px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .three {
    padding: unset;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .three > .left {
    width: 206.7px;
    padding-left: 42px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .three > .left > b {
    margin-right: 19.2px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .three > .right {
    width: 176.8px;
    padding-left: 16.8px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .three > .right > b {
    margin-right: 8.2px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .three > .pax {
    width: 102.5px;
    padding-left: 11.2px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .lux > b {
    margin-right: 22px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .two > .left {
    width: 180px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .two > .left > b {
    margin-right: 19px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .two > .right {
    width: 270px;
    padding-left: 23px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .two > .right > b {
    margin-right: 19px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .third {
    padding-left: 0;
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .product > .aircon > .section8 {
    height: 1789px;
    padding-top: 155px;
  }
  .product > .aircon > .section8 > .pack > .list {
    margin-top: 56px;
    row-gap: 70px;
  }
  .product > .aircon > .section8 > .pack > .list > .card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 320px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > img {
    width: 156px;
    margin-right: unset;
    margin-bottom: 30px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content {
    width: 100%;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .title {
    padding: unset;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .subject {
    height: 53px;
    padding-left: 24px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .one {
    padding-left: 32px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .fix {
    padding: unset;
    -ms-grid-columns: 204.5px 115.5px;
        grid-template-columns: 204.5px 115.5px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .fix > .left {
    padding-left: 32px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .fix > .right {
    padding-left: 33.5px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .three {
    padding: unset;
    -ms-grid-columns: 100%;
        grid-template-columns: 100%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    height: 106px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .three > div {
    height: 53px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .three > .left {
    width: 320px;
    border-right: unset;
    border-bottom: solid 1px #e0e0e0;
    padding-left: 22.5px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .three > .right {
    padding-left: 22.5px;
    width: 204.5px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .three > .pax {
    width: 115.5px;
    padding-left: 18px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content .lux {
    height: 78px;
    padding-left: 22.5px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content .short > b,
  .product > .aircon > .section8 > .pack > .list > .card > .content .lux > b {
    margin-right: 31px !important;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .two {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 107px;
    display: -ms-grid;
    display: grid;
    row-gap: 1px;
    background-color: #e0e0e0;
    padding: 0;
    -ms-grid-rows: 53px 53px;
        grid-template-rows: 53px 53px;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .two > .left {
    width: 100%;
    background-color: white;
    padding-left: 22.5px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-right: unset;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .two > .right {
    padding-left: 22.5px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    background-color: white;
    width: 100%;
    height: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .product > .aircon > .section8 > .pack > .list > .card > .content > .third {
    padding: unset;
  }
}
