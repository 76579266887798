.homeclean > .banner {
  height: 370px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: -webkit-image-set(url("/public/assets/homeclean/banner.png") 1x, url("/public/assets/homeclean/banner@2x.png") 2x, url("/public/assets/homeclean/banner@3x.png") 3x);
  background-image: image-set(url("/public/assets/homeclean/banner.png") 1x, url("/public/assets/homeclean/banner@2x.png") 2x, url("/public/assets/homeclean/banner@3x.png") 3x);
  text-align: center;
  padding-top: 234px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.homeclean > .banner > .title {
  font-size: 39px;
  font-weight: bold;
  color: white;
}

.homeclean > .homecare {
  width: 100%;
  height: 1054px;
  position: relative;
}

.homeclean > .homecare > .pack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 115px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.homeclean > .homecare > .pack > .category {
  width: 109px;
  height: 33px;
  background-color: var(--shamrock);
  border-radius: 16.5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: 'gmarket';
  color: #f8f8f8;
  font-size: 11px;
}

.homeclean > .homecare > .pack > .title {
  text-align: center;
  font-size: 35px;
  font-weight: 500;
  line-height: 1.31;
  color: var(--navy);
  margin-top: 11px;
  font-family: "score";
}

.homeclean > .homecare > .pack > .title > b {
  font-family: "score";
  font-weight: bold;
  color: var(--shamrock);
}

.homeclean > .homecare > .pack > .list {
  margin-top: 68px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (450px)[2];
      grid-template-columns: repeat(2, 450px);
  -webkit-column-gap: 15px;
          column-gap: 15px;
  row-gap: 51px;
}

.homeclean > .homecare > .pack > .list > .card {
  height: 240px;
  width: 100%;
  -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
          box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  background-color: white;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 97px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.homeclean > .homecare > .pack > .list > .card > img {
  width: 111px;
  height: 111px;
  position: absolute;
  top: -30px;
}

.homeclean > .homecare > .pack > .list > .card > .title {
  font-size: 23px;
}

.homeclean > .homecare > .pack > .list > .card > .line {
  width: 256px;
  height: 1px;
  background-color: #e0e0e0;
  margin-top: 8px;
  margin-bottom: 14px;
}

.homeclean > .homecare > .pack > .list > .card > .content {
  text-align: center;
  font-size: 13px;
  line-height: 1.46;
  white-space: pre-line;
}

.homeclean > .homecare > .pack > .list > .card:nth-child(4) {
  padding-top: 87px;
}

.homeclean > .homecare > .pack > .list > .card:nth-child(4) > .line {
  margin: 8px 0;
}

.homeclean > .homecare > .back {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 609px;
  background-color: #e6f9ef;
  z-index: -1;
}

.homeclean > .matservice {
  background-color: #f8f8f8;
  width: 100%;
  height: 883px;
  padding-top: 173px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.homeclean > .matservice > .pack {
  width: 825px;
}

.homeclean > .matservice > .pack > .title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.homeclean > .matservice > .pack > .title-wrapper > .right {
  width: 153px;
}

.homeclean > .matservice > .pack > .title-wrapper > .left > .category {
  margin-bottom: 15px;
  width: 155px;
  height: 33px;
  background-color: var(--shamrock);
  border-radius: 16.5px;
  color: white;
  font-size: 11px;
  font-family: 'gmarket';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.homeclean > .matservice > .pack > .title-wrapper > .left > .title {
  font-size: 33px;
  line-height: 1.39;
  color: var(--navy);
}

.homeclean > .matservice > .pack > .title-wrapper > .left > .title > b {
  color: var(--shamrock);
}

.homeclean > .matservice > .pack > .content {
  margin-top: 48px;
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: (53px)[7];
      grid-template-rows: repeat(7, 53px);
  row-gap: 1px;
}

.homeclean > .matservice > .pack > .content > .content-head {
  background-color: var(--shamrock);
  font-size: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
}

.homeclean > .matservice > .pack > .content > .card {
  width: 100%;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 253px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 18px;
  font-weight: 500;
}

.homeclean > .matservice > .pack > .content > .card > .title {
  width: 120px;
  margin-right: 69px;
}

.homeclean > .jointservice {
  width: 100%;
  height: 1100px;
  background-color: #f8f8f8;
  padding-top: 101px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.homeclean > .jointservice > .pack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.homeclean > .jointservice > .pack > .category {
  width: 155px;
  height: 33px;
  background-color: var(--shamrock);
  border-radius: 16.5px;
  font-family: 'gmarket';
  font-size: 11px;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.homeclean > .jointservice > .pack > .title {
  text-align: center;
  font-size: 35px;
  line-height: 1.31;
  color: var(--navy);
  margin-top: 14px;
  margin-bottom: 52px;
  font-family: "score";
}

.homeclean > .jointservice > .pack > .title > b {
  font-family: "score";
  font-weight: bold;
}

.homeclean > .jointservice > .pack > .content-wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (489px)[2];
      grid-template-columns: repeat(2, 489px);
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

.homeclean > .jointservice > .pack > .content-wrapper > .card {
  height: 400px;
  background-color: #e0e0e0;
}

.homeclean > .jointservice > .pack > .content-wrapper > .card > .card-head {
  width: 100%;
  height: 81px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
}

.homeclean > .jointservice > .pack > .content-wrapper > .card > .card-head > b {
  font-size: 22px;
}

.homeclean > .jointservice > .pack > .content-wrapper > .card > .card-head > span {
  font-size: 14px;
  font-weight: 500;
}

.homeclean > .jointservice > .pack > .content-wrapper > .card > .wrapper {
  background-color: white;
  margin-top: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.homeclean > .jointservice > .pack > .content-wrapper > .card > .wrapper > .name {
  width: 93px;
  font-size: 18px;
  margin-right: 27px;
  white-space: nowrap;
}

.homeclean > .jointservice > .pack > .content-wrapper > .card > .wrapper > .content {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
}

.homeclean > .jointservice > .pack > .content-wrapper > .one > .card-head {
  background-color: var(--shamrock);
}

.homeclean > .jointservice > .pack > .content-wrapper > .one > .wrapper {
  height: 159px;
  padding-left: 26px;
}

.homeclean > .jointservice > .pack > .content-wrapper > .two > .card-head {
  background-color: #1b6a48;
}

.homeclean > .jointservice > .pack > .content-wrapper > .two > .big {
  height: 136px;
}

.homeclean > .jointservice > .pack > .content-wrapper > .two > .small {
  height: 60px;
}

.homeclean > .jointservice > .pack > .content-wrapper > .two > .wrapper {
  padding-left: 30px;
}

.homeclean > .jointservice > .pack > .notice {
  margin-top: 30px;
  width: 100%;
  height: 238px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: (58px)[2];
      grid-template-rows: repeat(2, 58px);
  row-gap: 45px;
  background-color: #ececec;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 49px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-top: 39px;
}

.homeclean > .jointservice > .pack > .notice > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.homeclean > .jointservice > .pack > .notice > .wrapper > img {
  width: 30px;
  margin-right: 24px;
}

.homeclean > .jointservice > .pack > .notice > .wrapper > .title {
  font-size: 20px;
  line-height: 1.45;
}

.homeclean > .jointservice > .pack > .notice > .wrapper > .title > b {
  color: var(--shamrock);
}

.homeclean > .jointservice > .pack > .notice > .wrapper > .title > .red {
  color: #ff5555;
}

.homeclean > .birdservice {
  background-color: #f8f8f8;
  width: 100%;
  height: 757px;
  padding-top: 118px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.homeclean > .birdservice > .pack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.homeclean > .birdservice > .pack > .category {
  width: 155px;
  height: 33px;
  background-color: var(--shamrock);
  border-radius: 16.5px;
  color: white;
  font-size: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: 'gmarket';
}

.homeclean > .birdservice > .pack > .title {
  font-size: 35px;
  color: var(--navy);
  margin-top: 12px;
  margin-bottom: 95px;
}

.homeclean > .birdservice > .pack > .number-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: absolute;
  font-size: 22px;
  font-weight: bold;
  color: var(--shamrock);
  top: 145px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-left: 75px;
}

.homeclean > .birdservice > .pack > .number-wrapper > div {
  font-family: 'score';
}

.homeclean > .birdservice > .pack > .number-wrapper > .one {
  margin-right: 162px;
}

.homeclean > .birdservice > .pack > .number-wrapper > .two {
  margin-right: 255px;
}

.homeclean > .birdservice > .pack > .wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 252px 252px 441px;
      grid-template-columns: 252px 252px 441px;
  -webkit-column-gap: 24px;
          column-gap: 24px;
}

.homeclean > .birdservice > .pack > .wrapper > .card {
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  height: 324px;
  overflow: hidden;
}

.homeclean > .birdservice > .pack > .wrapper > .card > .card-head {
  width: 100%;
  height: 71px;
  background-color: var(--shamrock);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
  font-size: 22px;
}

.homeclean > .birdservice > .pack > .wrapper > .card > .img-wrapper {
  background-color: white;
  height: 253px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.homeclean > .birdservice > .pack > .wrapper > .card > .img-wrapper > img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media screen and (max-width: 1024px) {
  .homeclean > .banner {
    height: 299px;
    padding-top: 210px;
  }
  .homeclean > .banner > .title {
    font-size: 30px;
  }
  .homeclean > .homecare {
    height: 881px;
  }
  .homeclean > .homecare > .pack {
    padding-top: 105px;
  }
  .homeclean > .homecare > .pack > .title {
    font-size: 25px;
  }
  .homeclean > .homecare > .pack > .list {
    margin-top: 62px;
    -ms-grid-columns: (344px)[2];
        grid-template-columns: repeat(2, 344px);
    -ms-grid-rows: 190px 201px;
        grid-template-rows: 190px 201px;
    gap: 70px 16px;
  }
  .homeclean > .homecare > .pack > .list > .card {
    height: 100%;
    padding-top: 60px;
  }
  .homeclean > .homecare > .pack > .list > .card > img {
    width: 85px;
    height: 85px;
    top: -40px;
  }
  .homeclean > .homecare > .pack > .list > .card > .title {
    font-size: 18px;
  }
  .homeclean > .homecare > .pack > .list > .card > .content {
    text-align: center;
  }
  .homeclean > .homecare > .pack > .list > .card > .line {
    width: 259px;
  }
  .homeclean > .homecare > .pack > .list > .card:nth-child(4) {
    padding-top: 60px;
  }
  .homeclean > .homecare > .back {
    height: 513px;
  }
  .homeclean > .matservice > .pack > .title-wrapper > .left > .title {
    font-size: 25px;
  }
  .homeclean > .matservice > .pack > .title-wrapper > .right {
    width: 141px;
  }
  .homeclean > .jointservice {
    height: 1502px;
    padding-top: 117px;
  }
  .homeclean > .jointservice > .pack > .title {
    font-size: 30px;
    margin-bottom: 36px;
  }
  .homeclean > .jointservice > .pack > .content-wrapper {
    width: 100%;
    -ms-grid-columns: 100%;
        grid-template-columns: 100%;
    row-gap: 29px;
  }
  .homeclean > .jointservice > .pack > .content-wrapper > .card > .wrapper {
    padding: 0 133px;
  }
  .homeclean > .jointservice > .pack > .content-wrapper > .card > .wrapper > .name {
    margin-right: 33px;
  }
  .homeclean > .jointservice > .pack > .content-wrapper > .card > .wrapper > .content {
    white-space: nowrap;
  }
  .homeclean > .jointservice > .pack > .content-wrapper > .one {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 81px 144px 97px;
        grid-template-rows: 81px 144px 97px;
    row-gap: 1px;
    height: auto;
  }
  .homeclean > .jointservice > .pack > .content-wrapper > .one > .wrapper {
    height: 100%;
  }
  .homeclean > .jointservice > .pack > .notice {
    padding-left: 28px;
    row-gap: 30px;
  }
  .homeclean > .jointservice > .pack > .notice > .wrapper > img {
    margin-right: 22px;
  }
  .homeclean > .jointservice > .pack > .notice > .wrapper > .title {
    font-size: 16px;
  }
  .homeclean > .birdservice {
    height: 640px;
  }
  .homeclean > .birdservice > .pack > .title {
    font-size: 30px;
    margin-top: 15px;
    margin-bottom: 74px;
  }
  .homeclean > .birdservice > .pack > .number-wrapper {
    font-size: 17px;
    top: 130px;
    padding-left: 55px;
  }
  .homeclean > .birdservice > .pack > .number-wrapper > .one {
    margin-right: 98px;
  }
  .homeclean > .birdservice > .pack > .number-wrapper > .two {
    margin-right: 171px;
  }
  .homeclean > .birdservice > .pack > .wrapper {
    -ms-grid-columns: 181px 181px 317px;
        grid-template-columns: 181px 181px 317px;
    -webkit-column-gap: 12px;
            column-gap: 12px;
  }
  .homeclean > .birdservice > .pack > .wrapper > .card {
    height: 233px;
  }
  .homeclean > .birdservice > .pack > .wrapper > .card > .card-head {
    height: 51px;
    font-size: 15px;
  }
  .homeclean > .birdservice > .pack > .wrapper > .card > .img-wrapper {
    height: 182px;
  }
  .homeclean > .birdservice > .pack > .wrapper > .card > .img-wrapper > img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 127px;
  }
}

@media screen and (max-width: 767px) {
  .homeclean > .banner {
    height: 209px;
    padding-top: 117px;
  }
  .homeclean > .banner > .title {
    font-size: 23px;
  }
  .homeclean > .homecare {
    height: 1422px;
  }
  .homeclean > .homecare > .pack {
    padding-top: 106px;
  }
  .homeclean > .homecare > .pack > .list {
    -ms-grid-columns: 320px;
        grid-template-columns: 320px;
    -ms-grid-rows: 164px 192px 192px 228px;
        grid-template-rows: 164px 192px 192px 228px;
    row-gap: 66px;
  }
  .homeclean > .homecare > .pack > .list > .card {
    padding-top: 49px;
  }
  .homeclean > .homecare > .pack > .list > .card > img {
    width: 73px;
    height: 73px;
  }
  .homeclean > .homecare > .pack > .list > .card > .title {
    font-size: 20px;
  }
  .homeclean > .homecare > .pack > .list > .card > .line {
    margin: 12px 0;
    width: 280px;
  }
  .homeclean > .homecare > .pack > .list > .card:nth-child(1) > .line {
    margin: 12px 0;
  }
  .homeclean > .homecare > .pack > .list > .card:nth-child(2) > .line {
    margin: 11px 0 9.5px 0;
  }
  .homeclean > .homecare > .pack > .list > .card:nth-child(3) > .line {
    margin: 13px 0 9.5px 0;
  }
  .homeclean > .homecare > .pack > .list > .card:nth-child(4) > .line {
    margin: 11px 0 15px 0;
  }
  .homeclean > .homecare > .back {
    height: 1169px;
  }
  .homeclean > .matservice {
    padding-top: 111px;
    height: 1049px;
  }
  .homeclean > .matservice > .pack {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
  }
  .homeclean > .matservice > .pack > .title-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
  }
  .homeclean > .matservice > .pack > .title-wrapper > .left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
  }
  .homeclean > .matservice > .pack > .title-wrapper > .right {
    margin-top: 24px;
  }
  .homeclean > .matservice > .pack > .content {
    width: 320px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .homeclean > .matservice > .pack > .content > .content-head {
    font-size: 19px;
    height: 87px;
  }
  .homeclean > .matservice > .pack > .content > .card {
    padding-left: 22px;
    height: 53px;
  }
  .homeclean > .matservice > .pack > .content > .card > .title {
    width: 120px;
    margin-right: 25.8px;
  }
  .homeclean > .jointservice {
    height: 1545px;
  }
  .homeclean > .jointservice > .pack > .title {
    font-size: 25px;
  }
  .homeclean > .jointservice > .pack > .content-wrapper > .card > .wrapper {
    padding-left: 17px;
    padding-right: 0;
  }
  .homeclean > .jointservice > .pack > .content-wrapper > .card > .wrapper > .name {
    font-size: 15px;
    width: 77px;
    margin-right: 17px;
  }
  .homeclean > .jointservice > .pack > .content-wrapper > .card > .wrapper > .content {
    font-size: 13px;
  }
  .homeclean > .jointservice > .pack > .content-wrapper > .one {
    -ms-grid-rows: 81px 159px 71px;
        grid-template-rows: 81px 159px 71px;
    -ms-grid-columns: 320px;
        grid-template-columns: 320px;
  }
  .homeclean > .jointservice > .pack > .notice {
    margin-top: 37px;
    height: 311px;
    padding: 41px 0 0 19px;
    -ms-grid-rows: 71px 121px;
        grid-template-rows: 71px 121px;
    row-gap: 42px;
  }
  .homeclean > .jointservice > .pack > .notice > .wrapper {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .homeclean > .jointservice > .pack > .notice > .wrapper > img {
    width: 24px;
    margin-right: 14px;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }
  .homeclean > .jointservice > .pack > .notice > .wrapper > .title {
    font-size: 15px;
    line-height: 1.67;
  }
  .homeclean > .birdservice {
    height: 1205px;
    padding-top: 127px;
  }
  .homeclean > .birdservice > .pack > .title {
    font-size: 25px;
    margin-top: 15px;
    margin-bottom: 71px;
  }
  .homeclean > .birdservice > .pack > .number-wrapper {
    top: 120px;
    padding: unset;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .homeclean > .birdservice > .pack > .number-wrapper > .one {
    margin-right: unset;
    margin-bottom: 255px;
  }
  .homeclean > .birdservice > .pack > .number-wrapper > .two {
    margin-right: unset;
    margin-bottom: 255px;
  }
  .homeclean > .birdservice > .pack > .wrapper {
    -ms-grid-columns: 320px;
        grid-template-columns: 320px;
    row-gap: 80px;
    -webkit-column-gap: unset;
            column-gap: unset;
  }
  .homeclean > .birdservice > .pack > .wrapper > .card {
    height: 194px;
  }
  .homeclean > .birdservice > .pack > .wrapper > .card > .img-wrapper {
    height: 143px;
  }
  .homeclean > .birdservice > .pack > .wrapper > .card > .img-wrapper > img {
    height: 101px;
  }
  .homeclean > .birdservice > .pack > .wrapper > .card:nth-child(1) > .img-wrapper > img {
    width: 101px;
  }
  .homeclean > .birdservice > .pack > .wrapper > .card:nth-child(2) > .img-wrapper > img {
    width: 101px;
  }
  .homeclean > .birdservice > .pack > .wrapper > .card:nth-child(3) > .img-wrapper > img {
    width: 262px;
  }
}
