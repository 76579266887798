.caretwo {
  width: 100%;
  height: 948px;
  background-color: #d6f0f4;
}

.caretwo > .pack {
  padding-top: 159px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.caretwo > .pack > .category {
  width: 162px;
  height: 33px;
  border-radius: 16.5px;
  background-color: var(--navy);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
  font-size: 11px;
  font-family: 'gmarket';
  font-weight: bold;
}

.caretwo > .pack > .title {
  font-size: 36px;
  color: var(--navy);
  font-weight: 500;
  margin: 10px 0;
  font-family: "score";
}

.caretwo > .pack > .title > b {
  font-family: "score";
  font-weight: bold;
}

.caretwo > .pack > .sub {
  font-size: 20px;
}

.caretwo > .pack > .sub > b {
  color: #46BAD1;
}

.caretwo > .pack > .list {
  margin-top: 83.8px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (318px)[3];
      grid-template-columns: repeat(3, 318px);
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.caretwo > .pack > .list > .card {
  width: 100%;
  height: 343px;
  background-color: white;
  border-radius: 22px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  padding-top: 82.5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  white-space: pre-line;
}

.caretwo > .pack > .list > .card > img {
  position: absolute;
  width: 100px;
  top: -50px;
}

.caretwo > .pack > .list > .card > .title {
  font-size: 27px;
  line-height: 1.3;
  color: var(--navy);
}

.caretwo > .pack > .list > .card > .line {
  width: 239px;
  height: 1px;
  background-color: #b5b5b5;
  margin-top: 14.5px;
  margin-bottom: 19.2px;
}

.caretwo > .pack > .list > .card > .sub {
  font-size: 14px;
  line-height: 1.64;
}

.hoodtwo > .pack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.hoodtwo > .pack > .list {
  -ms-grid-columns: (237px)[4];
      grid-template-columns: repeat(4, 237px);
}

.hoodtwo > .pack > .list > .card {
  height: 319px;
}

.hoodtwo > .pack > .list > .card > img {
  width: 84px;
}

.hoodtwo > .pack > .list > .card > .title {
  font-size: 22px;
}

.hoodtwo > .pack > .list > .card > .line {
  width: 158px;
}

.airtwo > .pack > .list,
.filtertwo > .pack > .list {
  -ms-grid-columns: (318px)[3];
      grid-template-columns: repeat(3, 318px);
}

.airtwo > .pack > .list > .card,
.filtertwo > .pack > .list > .card {
  height: 343px;
}

.airtwo > .pack > .list > .card > .title,
.filtertwo > .pack > .list > .card > .title {
  font-size: 25px;
  line-height: 1.4;
}

.filtertwo > .pack,
.jointtwo > .pack,
.airtwo > .pack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.homecleantwo {
  background-color: #d8f6e8;
}

.homecleantwo > .pack > .sub > b {
  color: var(--shamrock);
}

.homecleantwo > .pack > .category {
  background-color: var(--shamrock);
}

.businesstwo {
  background-color: #e3f8f7;
}

.businesstwo > .pack > .category {
  background-color: #40ccc7;
}

.mattwo {
  height: 921px;
}

.mattwo > .pack > .list {
  -ms-grid-columns: (237px)[4];
      grid-template-columns: repeat(4, 237px);
}

.mattwo > .pack > .list > .card {
  height: 319px;
}

.mattwo > .pack > .list > .card > img {
  width: 84px;
}

.mattwo > .pack > .list > .card > .title {
  font-size: 22px;
}

.mattwo > .pack > .list > .card > .line {
  width: 158px;
}

.jointtwo > .pack {
  text-align: center;
}

.jointtwo > .pack > .title {
  margin: 10px 0 19px 0;
}

.jointtwo > .pack > .list {
  -ms-grid-columns: (318px)[2];
      grid-template-columns: repeat(2, 318px);
}

.jointtwo > .pack > .list > .card {
  padding-top: 114px;
}

.birdtwo > .pack > .list > .card {
  padding-top: 109px;
}

.birdtwo > .pack > .list > .card > .title {
  font-size: 25px;
}

.bugtwo > .pack > .list {
  margin-top: 88.8px;
}

.bugtwo > .pack > .list > .card {
  padding-top: 99.6px;
}

.bugtwo > .pack > .list > .card > .title {
  font-size: 25px;
}

@media screen and (max-width: 1024px) {
  .caretwo {
    height: 761px;
  }
  .caretwo > .pack {
    padding-top: 113px;
  }
  .caretwo > .pack > .category {
    width: 155px;
  }
  .caretwo > .pack > .title {
    font-size: 25px;
    margin: 11px 0;
  }
  .caretwo > .pack > .sub {
    font-size: 15px;
  }
  .caretwo > .pack > .list {
    margin-top: 67px;
    -ms-grid-columns: (224px)[3];
        grid-template-columns: repeat(3, 224px);
    -webkit-column-gap: 16px;
            column-gap: 16px;
  }
  .caretwo > .pack > .list > .card {
    height: 279px;
    border-radius: 16px;
    padding-top: 60px;
  }
  .caretwo > .pack > .list > .card > img {
    width: 71px;
    top: -30px;
  }
  .caretwo > .pack > .list > .card > .title {
    font-size: 19px;
  }
  .caretwo > .pack > .list > .card > .line {
    width: 168px;
    margin-top: 13px;
    margin-bottom: 15.5px;
  }
  .caretwo > .pack > .list > .card > .sub {
    font-size: 13px;
  }
  .hoodtwo,
  .mattwo {
    height: 687px;
  }
  .hoodtwo > .pack > .list,
  .mattwo > .pack > .list {
    -ms-grid-columns: (167px)[4];
        grid-template-columns: repeat(4, 167px);
  }
  .hoodtwo > .pack > .list > .card,
  .mattwo > .pack > .list > .card {
    height: 208px;
    padding-top: 45px;
  }
  .hoodtwo > .pack > .list > .card > img,
  .mattwo > .pack > .list > .card > img {
    width: 53px;
  }
  .hoodtwo > .pack > .list > .card > .title,
  .mattwo > .pack > .list > .card > .title {
    font-size: 14px;
  }
  .hoodtwo > .pack > .list > .card > .line,
  .mattwo > .pack > .list > .card > .line {
    width: 125.5px;
    margin: 10px 0;
  }
  .hoodtwo > .pack > .list > .card > .sub,
  .mattwo > .pack > .list > .card > .sub {
    font-size: 9px;
  }
  .airtwo,
  .filtertwo {
    height: 761px;
  }
  .airtwo > .pack,
  .filtertwo > .pack {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .mattwo {
    height: 761px;
  }
  .jointtwo > .pack > .list {
    -ms-grid-columns: (225px)[2];
        grid-template-columns: repeat(2, 225px);
  }
  .jointtwo > .pack > .list > .card {
    height: 198px;
  }
}

@media screen and (max-width: 767px) {
  .caretwo {
    height: 1376px;
  }
  .caretwo > .pack {
    padding-top: 218px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .caretwo > .pack > .title {
    text-align: center;
  }
  .caretwo > .pack > .sub {
    text-align: center;
    font-size: 13px;
  }
  .caretwo > .pack > .list {
    width: 295px;
    -ms-grid-columns: unset;
        grid-template-columns: unset;
    -ms-grid-rows: 221px 201px 221px;
        grid-template-rows: 221px 201px 221px;
    row-gap: 62px;
  }
  .caretwo > .pack > .list > .card {
    height: 100%;
    width: 100%;
  }
  .caretwo > .pack > .list > .card > img {
    width: 67px;
    top: -35px;
  }
  .caretwo > .pack > .list > .card > .title {
    font-size: 20px;
    white-space: nowrap;
  }
  .caretwo > .pack > .list > .card > .line {
    width: 239px;
  }
  .caretwo > .pack > .list > .card > .sub {
    text-align: left;
  }
  .hoodtwo {
    height: 1602px;
  }
  .hoodtwo > .pack > .list {
    row-gap: 72px;
    -ms-grid-rows: 212px 169px 195px 195px;
        grid-template-rows: 212px 169px 195px 195px;
  }
  .hoodtwo > .pack > .list > .card > .title {
    font-size: 18px;
  }
  .hoodtwo > .pack > .list > .card > .sub {
    font-size: 13px;
  }
  .hoodtwo > .pack > .list > .card:nth-child(1) > .title {
    white-space: pre-wrap;
  }
  .mattwo {
    height: 1714px;
  }
  .mattwo > .pack > .list {
    -ms-grid-rows: 212px 203px 220px 175px;
        grid-template-rows: 212px 203px 220px 175px;
  }
  .mattwo > .pack > .list > .card {
    padding-top: 55px;
  }
  .mattwo > .pack > .list > .card > .title {
    font-size: 20px;
  }
  .mattwo > .pack > .list > .card > .sub {
    font-size: 13px;
  }
  .mattwo > .pack > .list > .card:nth-child(3) > .title {
    white-space: pre-wrap;
  }
  .airtwo,
  .filtertwo {
    height: 1319px;
  }
  .airtwo > .pack > .list,
  .filtertwo > .pack > .list {
    -ms-grid-rows: 225px 190px 175px;
        grid-template-rows: 225px 190px 175px;
  }
  .airtwo > .pack > .list > .card,
  .filtertwo > .pack > .list > .card {
    padding-top: 52px;
  }
  .airtwo > .pack > .list > .card > .line,
  .filtertwo > .pack > .list > .card > .line {
    margin: 10px 0;
  }
  .airtwo > .pack > .list > .card > .title,
  .filtertwo > .pack > .list > .card > .title {
    font-size: 18px;
  }
  .airtwo > .pack > .list > .card:nth-child(1),
  .filtertwo > .pack > .list > .card:nth-child(1) {
    padding-top: 47.4px;
  }
  .airtwo > .pack > .list > .card:nth-child(1) > .title,
  .filtertwo > .pack > .list > .card:nth-child(1) > .title {
    white-space: pre-wrap;
  }
  .filtertwo > .pack > .list > .card > .title {
    font-size: 20px;
  }
  .jointtwo {
    height: 1030px;
  }
  .jointtwo > .pack {
    padding-top: 179px;
  }
  .jointtwo > .pack > .list {
    -ms-grid-rows: 171px 189px;
        grid-template-rows: 171px 189px;
    margin-top: 82px;
  }
  .jointtwo > .pack > .list > .card {
    padding-top: 49px;
  }
  .jointtwo > .pack > .list > .card > .line {
    margin: 12.5px 0;
  }
  .jointtwo > .pack > .list > .card > .sub {
    text-align: center;
  }
  .birdtwo {
    height: 1265px;
  }
  .birdtwo > .pack > .list {
    -ms-grid-rows: 212px 215px 178px;
        grid-template-rows: 212px 215px 178px;
  }
  .birdtwo > .pack > .list > .card {
    padding-top: 52px;
  }
  .bugtwo {
    height: 1392px;
  }
  .bugtwo > .pack > .sub {
    font-size: 15px;
  }
  .bugtwo > .pack > .list {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    -ms-grid-rows: 212px 213px 207px;
        grid-template-rows: 212px 213px 207px;
    -ms-grid-columns: 294px;
        grid-template-columns: 294px;
  }
  .bugtwo > .pack > .list > .card {
    padding-top: 46px;
  }
  .bugtwo > .pack > .list > .card > .line {
    margin: 11px 0;
  }
  .bugtwo > .pack > .list > .card > .title {
    white-space: pre-wrap;
  }
  .bugtwo > .pack > .list > .card > .sub {
    text-align: center;
  }
  .airfiltertwo {
    height: 1376px;
  }
  .airfiltertwo > .pack > .list {
    -ms-grid-rows: 266px 221px 221px;
        grid-template-rows: 266px 221px 221px;
    row-gap: 55px;
  }
  .airfiltertwo > .pack > .list > .card {
    padding-top: 54px;
  }
  .airfiltertwo > .pack > .list > .card > .sub {
    text-align: center;
  }
  .airfiltertwo > .pack > .list > .card:nth-child(1) > .title {
    white-space: pre-wrap;
  }
}
