.about {
  width: 100%;
  padding-top: 95px;

  &>.ceo {
    &>.section1 {
      height: 416px;
      width: 100%;
      display: flex;
      align-items: flex-end;

      &>.bottom {
        width: 100%;
        height: 223px;
        background-color: #e5f5f9;

        &>.pack {
          padding-top: 46px;
          box-sizing: border-box;
          height: 100%;
          display: flex;
          position: relative;

          &>.content {

            &>.subject {
              color: var(--dark-sky-blue);
            }

            &>.title {
              font-family: 'score';
              margin-top: 10px;
              font-size: 35px;
              line-height: 1.37;

              &>b {
                font-family: 'score';
              }
            }
          }

          &>img {
            position: absolute;
            width: 442px;
            bottom: 0;
            right: 0;
          }
        }
      }
    }

    &>.section2 {
      height: 1240px;
      width: 100%;
      padding-top: 75px;
      box-sizing: border-box;

      &>.pack {
        position: relative;
        height: 100%;

        &>.list {
          display: flex;
          flex-direction: column;

          &>.line {
            margin: 27.5px 0;
            width: 789px;
            height: 1px;
            background-color: var(--very-light-pink);
          }

          &>.box {
            display: flex;
            align-items: flex-end;

            &>img {
              width: 165px;
              margin-right: 44px;
            }

            &>.content {
              &>.title {
                line-height: 1.43;
                color: var(--dark-sky-blue);
                font-size: 21px;
                margin-bottom: 12px;
                font-family: 'score';

                &>b {
                  font-family: 'score';
                }
              }

              &>.subject {
                line-height: 1.71;
                font-size: 17px;
              }
            }
          }

          &>.second {
            margin: 10px 0;
          }

          &>.third {
            align-items: flex-start;
          }
        }

        &>.name {
          margin-top: 84px;
          text-align: right;
          font-size: 17px;
          font-weight: 500;
          color: var(--navy);
        }

        &>img {
          position: absolute;
          width: 629px;
          bottom: 0;
          right: 0;
        }
      }
    }
  }

  &>.company {
    &>.section1 {
      height: 857px;
      width: 100%;

      &>.pack {
        height: 100%;
        padding-top: 108px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        &>.subject {
          font-family: 'gmarket';
          font-weight: bold;
          color: var(--dark-sky-blue);
        }

        &>.title {
          font-size: 35px;
          color: var(--navy);
          line-height: 1.37;
          margin-top: 10px;
          margin-bottom: 22px;
          font-family: "score";

          &>b {
            font-family: "score";
            font-weight: bold;
          }
        }

        &>.content {
          font-size: 16px;
          line-height: 1.63;
        }

        &>.circle-wrapper {
          align-self: center;
          margin-top: 67px;
          display: flex;

          &>.circle {
            width: 269px;
            height: 269px;
            background-color: rgba(201, 201, 201, 0.53);
            border-radius: 269px;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
            padding-top: 68px;
            box-sizing: border-box;

            &>.title {
              font-size: 25px;
              margin-bottom: 9px;
              color: var(--navy);
            }

            &>.content {
              line-height: 1.56;
              font-size: 16px;
            }
          }

          &>.blue {
            background-color: rgba(70, 186, 209, 0.24);
          }

          &>.first {
            transform: translateX(25px);
          }

          &>.third {
            transform: translateX(-25px);
          }
        }
      }
    }

    &>.section2 {
      height: 785px;
      width: 100%;

      &>.pack {
        height: 100%;
        position: relative;
        padding-left: 162px;
        padding-top: 154px;
        box-sizing: border-box;

        &>.background {
          position: absolute;
          left: 0;
          top: 0;
          width: 456px;
          z-index: -1;
        }

        &>.title-wrapper {
          &>.cat {
            font-size: 15px;
            font-family: 'gmarket';
            color: var(--dark-sky-blue);
            margin-bottom: 8px;
          }

          &>.title {
            margin-top: 8px;
            font-size: 35px;
            line-height: 1.34;
            color: var(--navy);
            font-family: "score";

            &>b {
              font-family: "score";
              font-weight: bold;
            }
          }
        }

        &>.list {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          width: 100%;
          margin-top: 93px;

          &>.wrapper {
            width: 489px;
            color: var(--dark-sky-blue);
            display: flex;
            align-items: center;
            height: 91.5px;
            border-bottom: solid 1px var(--dark-sky-blue);

            &>.number {
              font-size: 50px;
              font-family: 'score';
              margin-right: 15px;
              font-weight: 500;
              width: 67px;
              height: 50px;
              color: var(--navy);
            }

            &>.content {
              font-size: 23px;
              color: black;
              font-weight: 500;

              &>b {
                position: relative;

                &>div {
                  position: absolute;
                  width: 100%;
                  height: 19px;
                  background-color: #47bad2;
                  opacity: 0.3;
                  bottom: -5px;
                  left: 0;
                  z-index: -1;
                }
              }
            }
          }
        }
      }
    }

    &>.section3 {
      height: 1493px;
      width: 100%;
      box-sizing: border-box;
      padding-top: 185px;

      &>.pack {
        &>.title-wrapper {
          &>.category {
            font-size: 15px;
            font-family: 'gmarket';
            font-weight: bold;
            color: var(--dark-sky-blue);
            margin-bottom: 8px;
          }

          &>.title {
            line-height: 1.34;
            font-size: 35px;
            color: var(--navy);
            margin-top: 8px;
            font-family: "score";

            &>b {
              font-family: "score";
              font-weight: bold;
            }
          }
        }

        &>.list {
          margin-top: 54px;
          display: grid;
          row-gap: 17px;

          &>.card {
            width: 100%;
            height: 185px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            padding-left: 37px;
            box-sizing: border-box;

            &>img {
              width: 120px;
              height: 120px;
              margin-right: 35px;
            }

            &>.content {
              color: var(--navy);

              &>.title {
                font-size: 23px;
                font-weight: bold;
              }

              &>.line {
                width: 16px;
                height: 2px;
                background-color: var(--navy);
                margin: 10px 0;
              }

              &>.content {
                font-size: 14px;
                font-weight: 500;
                line-height: 1.64;
                white-space: pre-wrap;
              }
            }
          }

          &>.card:nth-child(even) {
            background-color: rgb(170, 224, 234);
          }

          &>.card:nth-child(odd) {
            background-color: rgb(223, 242, 247);
          }
        }


      }
    }

    &>.section4 {
      height: 900px;
      width: 100%;
      position: relative;

      &>.back {
        width: 100%;
        height: 505px;
        background-color: rgb(223, 242, 247);
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
      }

      &>.pack {
        display: flex;
        flex-direction: column;

        &>.category {
          font-size: 15px;
          font-family: 'gmarket';
          color: var(--dark-sky-blue);
        }

        &>.title {
          font-size: 35px;
          line-height: 1.31;
          color: var(--navy);
          margin: 23px 0;
          font-family: "score";

          &>b {
            font-family: "score";
            font-weight: bold;
          }
        }

        &>.sub {
          font-size: 16px;
          line-height: 1.63;
        }

        &>.list {
          margin-top: 74px;
          align-self: center;
          width: 900px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          text-align: center;
          gap: 35px 23px;

          &>.doc {
            width: 144px;

            &>.name {
              font-size: 12px;
              font-weight: 500;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width : 1024px) {
  .about {
    &>.ceo {
      &>.section1 {
        height: 275px;

        &>.bottom {
          height: 153px;

          &>.pack {
            padding-top: 30px;

            &>.content {
              &>.title {
                font-size: 25px;
                margin-top: 4px;
              }

              &>.subject {
                font-size: 13px;
              }
            }

            &>img {
              width: 284px;
            }
          }
        }
      }

      &>.section2 {
        height: 1055px;
        padding-top: 66px;

        &>.pack {
          &>.list {
            &>.box {
              &>img {
                width: 123px;
                margin-right: 34px;
              }

              &>.content {
                &>.title {
                  font-size: 18px;
                }

                &>.subject {
                  font-size: 14px;
                }
              }
            }

            &>.line {
              width: 100%;
            }
          }

          &>.name {
            margin-top: 111px;
          }

          &>img {
            width: 534px;
          }
        }
      }
    }

    &>.company {
      &>.section1 {
        height: 649px;

        &>.pack {
          padding-top: 96px;

          &>.subject {
            font-size: 13px;
          }

          &>.title {
            font-size: 25px;
            margin-top: 5px;
            margin-bottom: 15px;
          }

          &>.content {
            font-size: 14px;
          }

          &>.circle-wrapper {
            margin-top: 38px;

            &>.circle {
              width: 210px;
              height: 210px;
              padding-top: 50px;

              &>.title {
                font-size: 19px;
              }

              &>.content {
                font-size: 14px;
              }
            }

            &>.first {
              transform: translateX(15px);
            }

            &>.third {
              transform: translateX(-15px);
            }
          }
        }
      }

      &>.section2 {
        height: 533px;

        &>.pack {
          padding-left: 155px;
          padding-top: 85px;

          &>.background {
            width: 310px;
          }

          &>.title-wrapper {
            &>.cat {
              font-size: 13px;
              margin-bottom: 6px;
            }

            &>.title {
              font-size: 29px;
            }
          }

          &>.list {
            margin-top: 70px;

            &>.wrapper {
              width: 344px;
              height: 64px;

              &>.number {
                font-size: 35px;
                margin-right: 13px;
                width: 47px;
                height: 35px;
              }

              &>.content {
                font-size: 16px;
                white-space: nowrap;

                &>b {
                  &>div {
                    width: 103%;
                    height: 13px;
                    bottom: -2px;
                    left: -3px;
                  }
                }
              }
            }
          }
        }
      }

      &>.section3 {
        height: 1416px;
        padding-top: 158px;

        &>.pack {
          &>.title-wrapper {
            &>.category {
              font-size: 13px;
              margin-bottom: 5px;
            }

            &>.title {
              font-size: 25px;
            }
          }

          &>.list {
            margin-top: 44px;

            &>.card {
              &>img {
                width: 102px;
                height: 102px;
                margin-right: 27px;
              }

              &>.content {
                &>.title {
                  font-size: 20px;
                }

              }
            }
          }
        }
      }

      &>.section4 {
        height: 780px;

        &>.back {
          height: 457px;
        }

        &>.pack {
          &>.category {
            font-size: 13px;
          }

          &>.title {
            font-size: 25px;
            margin: 15px 0;
          }

          &>.sub {
            font-size: 14px;
          }

          &>.list {
            width: 100%;
            margin-top: 68px;
            gap: 30px 22px;

            &>.doc {
              width: 121px;
              display: flex;
              flex-direction: column;
              align-items: center;

              &>.name {
                margin-top: 9px;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width : 767px) {
  .about {
    &>.ceo {
      &>.section1 {
        background-color: white;
        height: 395px;
        display: flex;
        flex-direction: column;

        &>.pack {
          height: 285px;

          &>.title {
            font-size: 30px;
            line-height: 1.23;
            color: var(--navy);
            padding-top: 63px;

          }
        }

        &>.bottom {
          height: 109px;

          &>.pack {
            justify-content: center;

            &>img {
              width: 271px;
              right: unset;
            }
          }
        }
      }

      &>.section2 {
        background-color: white;
        height: 1831px;

        &>.pack {
          &>.list {
            &>.line {
              margin: 60px 0;
            }

            &>.box {
              flex-direction: column;
              align-items: center;
              text-align: center;

              &>img {
                margin-right: unset;
              }

              &>.content {
                &>.title {
                  font-size: 25px;
                  margin-top: 16px;
                  margin-bottom: 9px;
                }

                &>.subject {
                  font-size: 13px;
                }

              }
            }
          }

          &>.name {
            text-align: center;
            margin-top: 101px;
          }

          &>img {
            width: 380px;
            right: -100px;
          }
        }
      }

    }

    &>.company {
      &>.section1 {
        height: 1155px;

        &>.pack {
          padding-top: 79px;

          &>.subject {
            font-size: 15px;
          }

          &>.title {
            font-size: 30px;
          }

          &>.content {
            font-size: 13px;
            margin-top: 26px;
          }

          &>.circle-wrapper {
            margin-top: 76px;
            flex-direction: column;

            &>.circle {
              width: 197px;
              height: 197px;
              padding-top: 53px;

              &>.title {
                margin-bottom: 5px;
                font-size: 20px;
              }

              &>.content {
                font-size: 13px;
              }
            }

            &>.first {
              transform: translateY(20px);
            }

            &>.third {
              transform: translateY(-20px);
            }
          }
        }
      }

      &>.section2 {
        height: 755px;

        &>.pack {
          padding-left: unset;
          padding-top: 65px;

          &>.background {
            width: 227px;
            height: 452.2px;
          }

          &>.title-wrapper {
            box-sizing: border-box;
            padding-left: 20px;

            &>.cat {
              font-size: 15px;
              margin-bottom: 8px;
            }

            &>.title {
              font-size: 30px;
            }
          }

          &>.list {
            margin-top: 306px;

            &>.wrapper {
              width: 100%;
              height: 59px;

              &>.number {
                font-size: 32px;
                width: 43px;
                height: 32px;
              }

              &>.content {
                font-size: 15px;

                &>b {
                  &>div {
                    width: 101%;
                  }
                }
              }
            }
          }
        }
      }

      &>.section3 {
        height: 1807px;
        padding-top: 180px;

        &>.pack {
          &>.title-wrapper {
            &>.category {
              margin-bottom: 8px;
              font-size: 15px;
            }

            &>.title {
              font-size: 30px;

            }

          }

          &>.list {
            margin-top: 37px;

            &>.card {
              flex-direction: column;
              align-items: flex-start;
              padding-top: 25px;
              padding-left: 18px;

              &>img {
                width: 82px;
                height: 82px;
              }

              &>.content {
                &>.title {
                  font-size: 20px;
                  margin-top: 25px;
                  margin-bottom: 9px;
                }

                &>.content {
                  font-size: 13px;
                }
              }
            }

            &>.card:nth-child(1) {
              height: 273.5px;
            }

            &>.card:nth-child(2) {
              height: 323.5px;
            }

            &>.card:nth-child(3) {
              height: 270px;
            }

            &>.card:nth-child(4) {
              height: 300px;
            }
          }

        }
      }

      &>.section4 {
        height: 980px;

        &>.pack {
          position: relative;

          &>.category {
            font-size: 15px;
          }

          &>.title {
            font-size: 30px;
          }

          &>.sub {
            font-size: 13px;
          }

          &>.list {
            gap: 69px 16px;

            &>.doc {
              width: 96px;

              &>.name {
                white-space: pre-line;
                font-size: 12px;
                width: 100px;
              }
            }
          }
        }

        &>.back-list {
          width: 100%;
          z-index: -1;
          left: 0;
          bottom: 0;
          position: absolute;
          display: grid;
          grid-template-rows: repeat(3, 119px);
          row-gap: 123px;

          &>.back {
            width: 100%;
            background-color: rgb(223, 242, 247);
          }
        }
      }
    }

  }
}