.header {
  z-index: 1700;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  padding: 44px 100px 44px 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}

.header > a > .logo {
  width: 168px;
  cursor: pointer;
}

.header > .link-wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (auto)[5];
      grid-template-columns: repeat(5, auto);
  -webkit-column-gap: 39px;
          column-gap: 39px;
}

.header > .link-wrapper > a {
  white-space: nowrap;
  color: var(--navy);
  font-size: 16px;
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .header {
    padding: 36px 32px 36px 32px;
  }
  .header > a > .logo {
    width: 127px;
  }
  .header > .link-wrapper {
    -webkit-column-gap: 27px;
            column-gap: 27px;
  }
  .header > .link-wrapper > a {
    font-size: 13px;
  }
}

@media screen and (max-width: 767px) {
  .header {
    padding: 32px 20px 32px 20px;
    height: 60px;
  }
  .header > .menu {
    cursor: pointer;
    width: 28px;
  }
  .header > .mb-menu {
    overflow: hidden;
    -webkit-transition: height 0.4s ease-in-out;
    transition: height 0.4s ease-in-out;
    left: 0;
    top: 60px;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: white;
    display: -ms-grid;
    display: grid;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-grid-rows: (72px)[5];
        grid-template-rows: repeat(5, 72px);
  }
  .header > .mb-menu > a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 20px;
    font-weight: bold;
    position: relative;
  }
  .header > .mb-menu > a > .line {
    width: 72px;
    height: 1px;
    position: absolute;
    bottom: 0;
    background-color: #dbdbdb;
  }
}
