.careone {
  height: 1482px;
  width: 100%;
  padding-top: 140px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.careone > .pack > .category {
  background-color: var(--navy);
  width: 162px;
  height: 33px;
  font-family: 'gmarket';
  font-size: 11px;
  font-weight: bold;
  color: white;
  border-radius: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.careone > .pack > .title {
  font-size: 30px;
  font-weight: 500;
  white-space: nowrap;
  line-height: 1.33;
  color: var(--navy);
  margin-top: 13px;
  position: relative;
  font-family: "score";
}

.careone > .pack > .title > b {
  font-family: "score";
  font-weight: bold;
}

.careone > .pack > .sub {
  font-size: 20px;
  margin-top: 17px;
}

.careone > .pack > .list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (237px)[4];
      grid-template-columns: repeat(4, 237px);
  -webkit-column-gap: 15px;
          column-gap: 15px;
  margin-top: 47px;
  row-gap: 53px;
}

.careone > .pack > .list > .card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.careone > .pack > .list > .card > .number {
  position: absolute;
  top: -19px;
  width: 38px;
  height: 38px;
  border-radius: 38px;
  background-color: var(--navy);
  color: white;
  font-family: 'gmarket';
  font-weight: bold;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.careone > .pack > .list > .card > .title {
  margin-top: 8.2px;
  font-size: 18px;
  font-weight: 500;
}

.washclean {
  height: 1153px;
}

.washclean > .pack {
  padding: 0 125px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.washclean > .pack > .list {
  -ms-grid-columns: (237px)[3];
      grid-template-columns: repeat(3, 237px);
}

.hoodclean,
.filterclean,
.matclean {
  height: 1072px;
  padding-top: 183px;
}

.hoodclean > .pack,
.filterclean > .pack,
.matclean > .pack {
  padding: 0 125px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.hoodclean > .pack > .list,
.filterclean > .pack > .list,
.matclean > .pack > .list {
  -ms-grid-columns: (237px)[3];
      grid-template-columns: repeat(3, 237px);
}

.airclean {
  height: 400px;
  padding-top: 100px;
}

.airclean > .pack {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 125px;
}

.airclean > .pack > .list {
  -ms-grid-columns: (237px)[3];
      grid-template-columns: repeat(3, 237px);
}

.airclean > .pack > .list > .card > img {
  height: 158px;
  background-color: #8d8d8d;
}

.homecleancare > .pack > .category {
  background-color: var(--shamrock);
}

.homecleancare > .pack > .title > b {
  color: var(--shamrock);
}

.homecleancare > .pack > .list > .card > .number {
  background-color: var(--shamrock);
}

.businesscare > .pack > .category {
  background-color: #40ccc7;
}

.businesscare > .pack > .title > b {
  color: #40ccc7;
}

.businesscare > .pack > .list > .card > .number {
  background-color: #40ccc7;
}

.aircareclean {
  padding-top: 0;
  height: 250px;
}

.jointclean {
  height: 1100px;
}

.jointclean > .pack {
  width: 771px;
}

.jointclean > .pack > .sub {
  white-space: nowrap;
}

.jointclean > .pack > .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.birdclean {
  height: 804px;
}

.birdclean > .pack > .title {
  margin-top: 13px;
}

.birdclean > .pack > .list {
  margin-top: 62px;
}

.bugclean {
  height: 500px;
}

.airfilterclean {
  height: 1073px;
  padding-top: 190px;
}

.airfilterclean > .pack > .list {
  row-gap: 14px;
}

.airfilterclean > .pack > .list > .card {
  height: 238px;
}

@media screen and (max-width: 1024px) {
  .careone {
    height: 1204px;
    padding-top: 125px;
  }
  .careone > .pack > .category {
    width: 155px;
  }
  .careone > .pack > .title {
    font-size: 25px;
    margin-top: 13px;
  }
  .careone > .pack > .sub {
    font-size: 15px;
    margin-top: 11px;
  }
  .careone > .pack > .list {
    -ms-grid-columns: (168px)[4];
        grid-template-columns: repeat(4, 168px);
    -webkit-column-gap: 10px;
            column-gap: 10px;
    margin-top: 45px;
    row-gap: 39px;
  }
  .careone > .pack > .list > .card > .number {
    width: 27px;
    height: 27px;
    font-size: 11px;
    top: -15px;
  }
  .careone > .pack > .list > .card > .title {
    font-size: 12px;
    margin-top: 5px;
  }
  .jointclean {
    height: 824px;
  }
  .jointclean > .pack {
    padding: 0 100px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .jointclean > .pack > .list > .card {
    width: 168px;
  }
  .washclean,
  .hoodclean,
  .airclean,
  .filterclean,
  .matclean {
    height: 773px;
    padding-top: 108px;
  }
  .washclean > .pack,
  .hoodclean > .pack,
  .airclean > .pack,
  .filterclean > .pack,
  .matclean > .pack {
    padding: 0 90px;
  }
  .washclean > .pack > .list,
  .hoodclean > .pack > .list,
  .airclean > .pack > .list,
  .filterclean > .pack > .list,
  .matclean > .pack > .list {
    margin-top: 60px;
    -ms-grid-columns: (168px)[3];
        grid-template-columns: repeat(3, 168px);
  }
  .birdclean {
    padding-top: 169px;
    height: 766px;
  }
  .bugclean {
    height: 440px;
  }
  .aircareclean {
    height: 300px;
  }
  .airfilterclean {
    height: 984px;
    padding-top: 200px;
  }
  .airfilterclean > .pack > .list {
    gap: 9px;
  }
  .airfilterclean > .pack > .list > .card {
    height: 170px;
  }
  .airclean > .pack > .list > .card > img {
    height: 111px;
  }
}

@media screen and (max-width: 767px) {
  .careone {
    height: 1272px;
    padding-top: 76px;
  }
  .careone > .pack {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
  }
  .careone > .pack > .category {
    height: 30px;
    width: 154px;
  }
  .careone > .pack > .title {
    margin-top: 10px;
    line-height: 1.48;
  }
  .careone > .pack > .sub {
    margin-top: 14px;
    font-size: 13px;
  }
  .careone > .pack > .list {
    -ms-grid-columns: (102px)[3];
        grid-template-columns: repeat(3, 102px);
    -webkit-column-gap: 7px;
            column-gap: 7px;
    row-gap: 47px;
  }
  .careone > .pack > .list > .card > .number {
    width: 26px;
    height: 26px;
    top: -12px;
  }
  .washclean,
  .hoodclean,
  .airclean,
  .filterclean,
  .matclean {
    height: 819px;
  }
  .washclean > .pack,
  .hoodclean > .pack,
  .airclean > .pack,
  .filterclean > .pack,
  .matclean > .pack {
    padding: 0;
  }
  .washclean > .pack > .sub,
  .hoodclean > .pack > .sub,
  .airclean > .pack > .sub,
  .filterclean > .pack > .sub,
  .matclean > .pack > .sub {
    white-space: nowrap;
  }
  .washclean > .pack > .list,
  .hoodclean > .pack > .list,
  .airclean > .pack > .list,
  .filterclean > .pack > .list,
  .matclean > .pack > .list {
    white-space: pre-wrap;
  }
  .matclean {
    padding-top: 108px;
  }
  .matclean > .pack > .list {
    margin-top: 76px;
  }
  .hoodclean {
    height: 740px;
  }
  .airclean {
    height: 740px;
  }
  .filterclean {
    height: 740px;
    padding-top: 115px;
  }
  .jointclean {
    height: 707px;
  }
  .jointclean > .pack {
    width: 100%;
    padding: 0;
  }
  .jointclean > .pack > .list > .card {
    width: 103px;
  }
  .birdclean {
    height: 807px;
  }
  .birdclean > .pack > .list {
    -ms-grid-columns: (120px)[2];
        grid-template-columns: repeat(2, 120px);
  }
  .birdclean > .pack > .list > .card > .title {
    white-space: pre-wrap;
  }
  .bugclean {
    height: 400px;
  }
  .aircareclean {
    height: 320px;
  }
  .airfilterclean {
    height: 1162px;
    padding-top: 173px;
  }
  .airfilterclean > .pack > .list {
    -ms-grid-columns: (152px)[2];
        grid-template-columns: repeat(2, 152px);
    gap: 8px;
  }
  .airfilterclean > .pack > .list > .card {
    height: 152px;
  }
  .airclean > .pack > .list > .card > img {
    height: 68.2px;
  }
}
