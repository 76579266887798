.careone {
  height: 1482px;
  width: 100%;
  padding-top: 140px;
  box-sizing: border-box;

  &>.pack {
    &>.category {
      background-color: var(--navy);
      width: 162px;
      height: 33px;
      font-family: 'gmarket';
      font-size: 11px;
      font-weight: bold;
      color: white;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &>.title {
      font-size: 30px;
      font-weight: 500;
      white-space: nowrap;
      line-height: 1.33;
      color: var(--navy);
      margin-top: 13px;
      position: relative;
      font-family: "score";

      &>b {
        font-family: "score";
        font-weight: bold;
      }

      // &>.circle-wrapper {
      //   position: absolute;
      //   display: flex;
      //   column-gap: 28px;
      //   top: -9px;
      //   left: 266px;

      //   &>div {
      //     display: grid;
      //     grid-template-columns: repeat(2, 7px);
      //     column-gap: 23px;

      //     &>div {
      //       height: 7px;
      //       background-color: #76c7d3;
      //       border-radius: 7px;
      //     }
      //   }
      // }
    }

    &>.sub {
      font-size: 20px;
      margin-top: 17px;
    }

    &>.list {
      display: grid;
      grid-template-columns: repeat(4, 237px);
      column-gap: 15px;
      margin-top: 47px;
      row-gap: 53px;

      &>.card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        &>.number {
          position: absolute;
          top: -19px;
          width: 38px;
          height: 38px;
          border-radius: 38px;
          background-color: var(--navy);
          color: white;
          font-family: 'gmarket';
          font-weight: bold;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &>.title {
          margin-top: 8.2px;
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }
}

.washclean {
  height: 1153px;

  &>.pack {
    padding: 0 125px;
    box-sizing: border-box;

    &>.list {
      grid-template-columns: repeat(3, 237px);
    }
  }
}

.hoodclean,
.filterclean,
.matclean {
  height: 1072px;
  padding-top: 183px;

  &>.pack {
    padding: 0 125px;
    box-sizing: border-box;

    &>.list {
      grid-template-columns: repeat(3, 237px);
    }
  }
}

.airclean {
  height: 400px;
  padding-top: 100px;

  &>.pack {
    box-sizing: border-box;
    padding: 0 125px;

    &>.list {
      grid-template-columns: repeat(3, 237px);

      &>.card {
        &>img {
          height: 158px;
          background-color: #8d8d8d;
        }
      }
    }
  }
}

.homecleancare {
  &>.pack {
    &>.category {
      background-color: var(--shamrock);
    }

    &>.title {
      // &>.circle-wrapper {
      //   &>div {
      //     &>div {
      //       background-color: rgb(121, 218, 170);
      //     }
      //   }
      // }

      &>b {
        color: var(--shamrock);

      }
    }

    &>.list {
      &>.card {
        &>.number {
          background-color: var(--shamrock);
        }
      }
    }
  }
}

.businesscare {
  &>.pack {
    &>.category {
      background-color: #40ccc7;
    }

    &>.title {
      // &>.circle-wrapper {
      //   &>div {
      //     &>div {
      //       background-color: #40ccc7;
      //     }
      //   }
      // }

      &>b {
        color: #40ccc7;
        ;
      }
    }

    &>.list {
      &>.card {
        &>.number {
          background-color: #40ccc7;
          ;
        }
      }
    }
  }
}

.aircareclean {
  padding-top: 0;
  height: 250px;
}

.jointclean {
  height: 1100px;

  &>.pack {
    width: 771px;

    &>.sub {
      white-space: nowrap;
    }

    &>.list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

.birdclean {
  height: 804px;

  &>.pack {
    &>.title {
      margin-top: 13px;
    }

    &>.list {
      margin-top: 62px;
    }
  }
}

.bugclean {
  height: 500px;
}

.airfilterclean {
  height: 1073px;
  padding-top: 190px;

  &>.pack {
    &>.list {
      row-gap: 14px;

      &>.card {
        height: 238px;
      }
    }
  }
}

@media screen and (max-width : 1024px) {
  .careone {
    height: 1204px;
    padding-top: 125px;

    &>.pack {
      &>.category {
        width: 155px;
      }

      &>.title {
        font-size: 25px;
        margin-top: 13px;

        // &>.circle-wrapper {
        //   left: 210px;
        //   top: -3px;
        //   column-gap: 22px;

        //   &>div {
        //     grid-template-columns: repeat(2, 5px);
        //     column-gap: 19px;

        //     &>div {
        //       height: 5px;

        //     }
        //   }
        // }
      }

      &>.sub {
        font-size: 15px;
        margin-top: 11px;
      }

      &>.list {
        grid-template-columns: repeat(4, 168px);
        column-gap: 10px;
        margin-top: 45px;
        row-gap: 39px;

        &>.card {
          &>.number {
            width: 27px;
            height: 27px;
            font-size: 11px;
            top: -15px;
          }

          &>.title {
            font-size: 12px;
            margin-top: 5px;
          }
        }
      }

    }
  }

  .jointclean {
    height: 824px;

    &>.pack {
      padding: 0 100px;
      box-sizing: border-box;

      &>.list {
        &>.card {
          width: 168px;
        }
      }
    }
  }

  .washclean,
  .hoodclean,
  .airclean,
  .filterclean,
  .matclean {
    height: 773px;
    padding-top: 108px;

    &>.pack {

      padding: 0 90px;

      &>.list {
        margin-top: 60px;
        grid-template-columns: repeat(3, 168px);
      }
    }
  }

  .birdclean {
    padding-top: 169px;
    height: 766px;
  }

  .bugclean {
    height: 440px;
  }

  .aircareclean {
    height: 300px;

  }

  .airfilterclean {
    height: 984px;
    padding-top: 200px;

    &>.pack {
      &>.list {
        gap: 9px;

        &>.card {
          height: 170px;
        }
      }
    }
  }

  .airclean {

    &>.pack {
      &>.list {
        &>.card {
          &>img {
            height: 111px;

          }
        }
      }
    }
  }

}

@media screen and (max-width : 767px) {
  .careone {
    height: 1272px;
    padding-top: 76px;

    &>.pack {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &>.category {
        height: 30px;
        width: 154px;
      }

      &>.title {
        margin-top: 10px;
        line-height: 1.48;

        // &>.circle-wrapper {
        //   top: 38px;
        //   left: 44px;
        //   column-gap: 25px;

        //   &>div {
        //     grid-template-columns: repeat(2, 4px);
        //     column-gap: 19px;

        //     &>div {
        //       height: 4px;
        //     }
        //   }
        // }
      }

      &>.sub {
        margin-top: 14px;
        font-size: 13px;
      }

      &>.list {
        grid-template-columns: repeat(3, 102px);
        column-gap: 7px;
        row-gap: 47px;

        &>.card {
          &>.number {
            width: 26px;
            height: 26px;
            top: -12px;
          }
        }
      }
    }
  }

  .washclean,
  .hoodclean,
  .airclean,
  .filterclean,
  .matclean {
    height: 819px;

    &>.pack {
      padding: 0;

      &>.sub {
        white-space: nowrap;
      }

      &>.list {
        white-space: pre-wrap;
      }
    }
  }

  .matclean {
    padding-top: 108px;

    &>.pack {
      &>.title {
        // &>.circle-wrapper {
        //   left: 80px;
        // }
      }

      &>.list {
        margin-top: 76px;
      }
    }
  }

  .hoodclean {
    height: 740px;

    &>.pack {
      &>.title {
        // &>.circle-wrapper {
        //   left: 35px;
        //   top: 38px;
        // }
      }
    }
  }

  .washclean {
    &>.pack {
      &>.title {
        // &>.circle-wrapper {
        //   top: 38px;
        //   left: 39px;
        // }
      }
    }
  }

  .airclean {
    height: 740px;

    &>.pack {
      &>.title {
        // &>.circle-wrapper {
        //   top: 38px;
        //   left: 44px;
        // }
      }
    }
  }

  .filterclean {
    height: 740px;
    padding-top: 115px;

    // &>.pack {
    //   &>.title {
    //     &>.circle-wrapper {
    //       top: 38px;
    //       left: 58px;
    //     }
    //   }
    // }
  }

  // .homecleancare {
  //   &>.pack {
  //     &>.title {
  //       &>.circle-wrapper {
  //         &>div {
  //           &>div {
  //             background-color: #00bb68;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  .jointclean {
    height: 707px;

    &>.pack {
      width: 100%;
      padding: 0;

      // &>.title {
      //   &>.circle-wrapper {
      //     left: 68px;
      //   }
      // }

      &>.list {
        &>.card {
          width: 103px;
        }
      }
    }
  }

  .birdclean {
    height: 807px;

    &>.pack {
      &>.list {
        grid-template-columns: repeat(2, 120px);

        &>.card {
          &>.title {
            white-space: pre-wrap;
          }
        }
      }
    }
  }

  .bugclean {
    height: 400px;
  }

  .aircareclean {
    height: 320px;


  }

  .airfilterclean {
    height: 1162px;
    padding-top: 173px;

    &>.pack {

      &>.list {
        grid-template-columns: repeat(2, 152px);
        gap: 8px;

        &>.card {
          height: 152px;
        }
      }
    }
  }

  .airclean {

    &>.pack {
      &>.list {
        &>.card {
          &>img {
            height: 68.2px;

          }
        }
      }
    }
  }
}