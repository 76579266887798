.floating{
  position: fixed;
  right: 45px;
  bottom: 54px;
  display: grid;
  row-gap: 20px;
  z-index: 1000;
  &>a{
     width: 157px;
  height: 61px; 
   border-radius: 30.5px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  box-sizing: border-box;
  padding-left: 14px;
  padding-right: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &>.title{
    font-size: 18px;
    font-weight: bold;
  }
  &>img{
    width: fit-content;
  }
  }
}
@media screen and (max-width : 1024px) {
  .floating{
    right: 20px;
    bottom: 66px;
    row-gap: 8px;
    &>a{
      overflow: hidden;
      width: 47px;
      height: 47px;
      border-radius: 47px;
      padding: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      &>.coupang{
         width: 31.3px;
      }
      &>.naver{
        width: 24.4px;
      }
      &>.kakao{
        width: 28px;
      }
      &>.bot{
        width: 27px;
      }
    }
  }
}