.service {
  position: relative;
  width: 100%;
  height: 780px;
  padding: 0px;
  background-color: #d6f0f5;
  padding-top: 84px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.service > .pack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.service > .pack > .category {
  width: 155px;
  height: 33px;
  background-color: var(--navy);
  border-radius: 20px;
  color: white;
  font-size: 11px;
  font-weight: bold;
  font-family: 'gmarket';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.service > .pack > .title {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  margin-top: 14px;
  margin-bottom: 25px;
  color: var(--navy);
  font-family: "score";
}

.service > .pack > .title > b {
  font-family: "score";
  font-weight: bold;
}

.service > .pack > .list {
  width: 855px;
  height: 406px;
  background-color: white;
  -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.13);
          box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.13);
  border-radius: 13px;
  padding: 0 38.5px 0 43.5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.service > .pack > .list > .card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.service > .pack > .list > .card > .number {
  font-family: 'score';
  font-size: 40px;
  color: var(--navy);
  width: 52px;
  margin-right: 19px;
  font-weight: 500;
}

.service > .pack > .list > .card > .title {
  font-size: 18px;
  font-weight: 500;
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  white-space: nowrap;
}

.service > .pack > .list > .card > .title > b {
  color: var(--dark-sky-blue);
}

.service > .pack > .list > .line {
  margin-top: 7px;
  margin-bottom: 13px;
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
}

.service > .pack > .washservice {
  height: 343px;
}

.service::after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 50px 40px 0;
  border-color: #d6f0f5 transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -50px;
  left: 48%;
}

@media screen and (max-width: 1024px) {
  .service {
    height: 841px;
    padding-top: 91px;
  }
  .service > .pack > .title {
    font-size: 25px;
    margin-top: 19px;
    margin-bottom: 48px;
  }
  .service > .pack > .list {
    width: 620px;
    height: 392px;
    padding: 0 36px 0 34px;
  }
  .service > .pack > .list > .card > .number {
    font-size: 38px;
    margin-right: 22.5px;
    width: 50px;
  }
  .service > .pack > .list > .card > .title {
    font-size: 16px;
  }
  .service > .pack > .list > .line {
    margin: 12px 0;
  }
  .service > .pack > .washservice {
    width: 620px;
    height: 299px;
  }
  .service::after {
    left: 45%;
  }
  .washspack {
    height: 745px;
  }
}

@media screen and (max-width: 767px) {
  .service {
    height: 872px;
    padding-top: 134px;
  }
  .service > .pack > .title {
    margin-top: 9px;
    margin-bottom: 40px;
  }
  .service > .pack > .list {
    width: 100%;
    height: 495px;
    padding: 0 22px 0 24.5px;
  }
  .service > .pack > .list > .card {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .service > .pack > .list > .card > .number {
    font-size: 30px;
    width: 40px;
    margin-right: 14px;
  }
  .service > .pack > .list > .card > .title {
    font-size: 15px;
  }
  .service > .pack > .list > .card:nth-child(1) {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .service > .pack > .list > .card:nth-child(5) {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .service > .pack > .list > .card:nth-child(7) {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .service > .pack > .list > .line {
    margin: 20px 0;
  }
  .service::after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 50px 40px 0;
    border-color: #d6f0f5 transparent;
    display: block;
    width: 0;
    z-index: 1;
    bottom: -50px;
    left: 40%;
  }
  .washspack {
    height: 812px;
  }
  .washspack > .pack > .list {
    height: 395px;
  }
  .washspack > .pack > .list > .card {
    width: 272px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .washspack > .pack > .list > .line {
    margin: 15px 0;
  }
}
