.sponsor {
  width: 100%;
  height: 1028px;
  background-color: rgb(224, 243, 247);
  padding-top: 113px;
  box-sizing: border-box;

  &>.pack {
    padding: 0 62px;
    box-sizing: border-box;

    &>.title {
      font-size: 34px;
      line-height: 1.38;
      color: var(--navy);
      font-family: "score";

      &>b {
        font-family: "score";
        font-weight: bold;
      }
    }

    &>.content {
      margin-top: 81.7px;
      display: grid;
      grid-template-columns: repeat(2, 425.3px);
      column-gap: 17.7px;
      row-gap: 102.7px;

      &>.card {
        position: relative;
        display: flex;
        justify-content: center;

        &>.icon {
          position: absolute;
          width: fit-content;
          top: -63px;
          right: 50px;
          z-index: 1;
          opacity: 0.4;
        }

        &>.medi {
          top: -72px;
          right: 40px;
        }

        &>.school {
          top: -65px;
          right: 24px;
        }

        &>.title {
          width: 160px;
          height: 47px;
          border-radius: 23px;
          background-color: var(--seafoam-blue);
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 20px;
          position: absolute;
          top: -25px;
          z-index: 150;
        }

        &>.content-wrapper {
          z-index: 100;
          border-radius: 10px;
          width: 100%;
          height: 100%;
          background-color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          &>img {
            width: fit-content;
          }

          &>.line {
            width: 353px;
            height: 1px;
            background-color: var(--very-light-pink);
            margin-bottom: 11.5px;
          }

          &>span {
            font-size: 15px;
            font-weight: 500;
            line-height: 1.6;
            text-align: center;
          }
        }
      }

      &>.card:nth-child(1) {
        &>.content-wrapper {
          &>img {
            margin-top: 20px;
          }
        }
      }

      &>.card:nth-child(2) {
        &>.title {
          width: 190px;
        }

        &>.content-wrapper {
          &>img {
            margin-top: 10px;
          }
        }
      }

      &>.card:nth-child(4) {
        &>.content-wrapper {
          &>img {
            margin-top: 20px;
          }
        }

      }

      &>.first {
        height: 290.5px;

      }

      &>.two {
        height: 203.7px;
      }
    }
  }
}

@media screen and (max-width : 1024px) {
  .sponsor {
    padding-top: 125px;

    &>.pack {
      padding: unset;

      &>.title {
        font-size: 27px;
      }

      &>.content {
        margin-top: 72px;
        grid-template-columns: repeat(2, 344px);

        &>.card {
          height: 171px;

          &>.icon {
            width: 45px;
            top: -50px;
            right: 30px;
          }

          &>.title {
            width: 130px;
            height: 38px;
            font-size: 15px;
          }

          &>.medi {
            width: 52px;
            top: -62px;
            right: 20px;
          }

          &>.school {
            width: 77px;
            top: -55px;
            right: 20px;
          }

          &>.content-wrapper {
            &>.line {
              width: 285.5px;
            }

            &>span {
              font-size: 13px;
            }

            &>img {
              width: 100%;
            }
          }
        }

        &>.card:nth-child(2) {
          &>.title {
            width: 154px;
          }
        }

        &>.card:nth-child(4) {
          &>.icon {
            width: 53px;
          }

        }

        &>.first {
          height: 235px;

          &>.content-wrapper {
            &>img {
              width: 286px;
            }
          }
        }

        &>.two {
          height: 171px;

          &>.content-wrapper {
            &>img {
              width: 279px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width : 767px) {
  .sponsor {
    height: 1669px;
    padding-top: 146px;

    &>.pack {
      &>.title {
        font-size: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &>.content {
        grid-template-columns: unset;

        &>.card {

          &>.title {
            font-size: 20px;
            width: 160.5px;
            height: 45px;
          }

        }

        &>.card:nth-child(1) {
          height: 245px;

          &>.icon {
            right: 15px;
          }

          &>.content-wrapper {
            height: 245px;

            &>img {
              margin-top: 25px;
              width: 270px;
            }
          }
        }

        &>.card:nth-child(2) {
          height: 204px;

          &>.title {
            width: 172px;
          }

          &>.content-wrapper {
            &>img {
              width: 270px;
              margin-top: 15px;
            }
          }
        }

        &>.card:nth-child(3) {
          height: 225px;

          &>.content-wrapper {
            height: 224px;
          }
        }

        &>.card:nth-child(4) {
          height: 198px;

          &>.icon {
            right: 15px;
          }

          &>.content-wrapper {
            padding-top: 37px;
            box-sizing: border-box;
            height: 200px;

            &>img {
              width: 236px;
            }
          }

        }
      }
    }
  }
}