.caretwo {
  width: 100%;
  height: 948px;
  background-color: rgb(214, 240, 244);

  &>.pack {
    padding-top: 159px;
    box-sizing: border-box;

    &>.category {
      width: 162px;
      height: 33px;
      border-radius: 16.5px;
      background-color: var(--navy);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 11px;
      font-family: 'gmarket';
      font-weight: bold;
    }

    &>.title {
      font-size: 36px;
      color: var(--navy);
      font-weight: 500;
      margin: 10px 0;
      font-family: "score";

      &>b {
        font-family: "score";
        font-weight: bold;
      }
    }

    &>.sub {
      font-size: 20px;

      &>b {
        color: #46BAD1;
      }

    }

    &>.list {
      margin-top: 83.8px;
      display: grid;
      grid-template-columns: repeat(3, 318px);
      column-gap: 20px;

      &>.card {
        width: 100%;
        height: 343px;
        background-color: white;
        border-radius: 22px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-top: 82.5px;
        box-sizing: border-box;
        white-space: pre-line;

        &>img {
          position: absolute;
          width: 100px;
          top: -50px;
        }

        &>.title {
          font-size: 27px;
          line-height: 1.3;
          color: var(--navy);
        }

        &>.line {
          width: 239px;
          height: 1px;
          background-color: #b5b5b5;
          margin-top: 14.5px;
          margin-bottom: 19.2px;
        }

        &>.sub {
          font-size: 14px;
          line-height: 1.64;

        }
      }
    }
  }
}

.hoodtwo {
  &>.pack {
    display: flex;
    flex-direction: column;
    align-items: center;

    &>.list {
      grid-template-columns: repeat(4, 237px);

      &>.card {
        height: 319px;

        &>img {
          width: 84px;
        }

        &>.title {
          font-size: 22px;
        }

        &>.line {
          width: 158px;
        }
      }
    }
  }
}

.airtwo,
.filtertwo {
  &>.pack {
    &>.list {
      grid-template-columns: repeat(3, 318px);

      &>.card {
        height: 343px;

        &>.title {
          font-size: 25px;
          line-height: 1.4;
        }
      }
    }
  }
}

.filtertwo,
.jointtwo,
.airtwo {
  &>.pack {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.homecleantwo {
  background-color: rgb(216, 246, 232);

  &>.pack {
    &>.sub {
      &>b {
        color: var(--shamrock);
      }
    }

    &>.category {
      background-color: var(--shamrock);
    }

  }
}

.businesstwo {
  background-color: #e3f8f7;

  &>.pack {
    &>.category {
      background-color: #40ccc7;
    }
  }
}

.mattwo {
  height: 921px;

  &>.pack {
    &>.list {
      grid-template-columns: repeat(4, 237px);

      &>.card {
        height: 319px;

        &>img {
          width: 84px;
        }

        &>.title {
          font-size: 22px;
        }

        &>.line {
          width: 158px;
        }
      }
    }
  }
}

.jointtwo {
  &>.pack {
    text-align: center;

    &>.title {
      margin: 10px 0 19px 0;
    }

    &>.list {
      grid-template-columns: repeat(2, 318px);

      &>.card {
        padding-top: 114px;
      }
    }
  }
}

.birdtwo {
  &>.pack {
    &>.list {
      &>.card {
        padding-top: 109px;

        &>.title {
          font-size: 25px;
        }
      }
    }
  }
}

.bugtwo {
  &>.pack {
    &>.list {
      margin-top: 88.8px;

      &>.card {
        padding-top: 99.6px;

        &>.title {
          font-size: 25px;
        }
      }
    }
  }
}

@media screen and (max-width : 1024px) {
  .caretwo {
    height: 761px;

    &>.pack {
      padding-top: 113px;

      &>.category {
        width: 155px;

      }

      &>.title {
        font-size: 25px;
        margin: 11px 0;
      }

      &>.sub {
        font-size: 15px;
      }

      &>.list {
        margin-top: 67px;
        grid-template-columns: repeat(3, 224px);
        column-gap: 16px;

        &>.card {
          height: 279px;
          border-radius: 16px;
          padding-top: 60px;

          &>img {
            width: 71px;
            top: -30px;
          }

          &>.title {
            font-size: 19px;
          }

          &>.line {
            width: 168px;
            margin-top: 13px;
            margin-bottom: 15.5px;
          }

          &>.sub {
            font-size: 13px;
          }
        }
      }
    }
  }

  .hoodtwo,
  .mattwo {
    height: 687px;

    &>.pack {
      &>.list {
        grid-template-columns: repeat(4, 167px);

        &>.card {
          height: 208px;
          padding-top: 45px;

          &>img {
            width: 53px;
          }

          &>.title {
            font-size: 14px;
          }

          &>.line {
            width: 125.5px;
            margin: 10px 0;
          }

          &>.sub {
            font-size: 9px;
          }
        }
      }
    }
  }

  .airtwo,
  .filtertwo {
    height: 761px;

    &>.pack {
      align-items: flex-start;
    }
  }

  .mattwo {
    height: 761px;

  }

  .jointtwo {
    &>.pack {
      &>.list {
        grid-template-columns: repeat(2, 225px);

        &>.card {
          height: 198px;
        }
      }
    }
  }

}

@media screen and (max-width : 767px) {
  .caretwo {
    height: 1376px;

    &>.pack {
      padding-top: 218px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &>.title {
        text-align: center;
      }

      &>.sub {
        text-align: center;
        font-size: 13px;
      }

      &>.list {
        width: 295px;
        grid-template-columns: unset;
        grid-template-rows: 221px 201px 221px;
        row-gap: 62px;

        &>.card {
          height: 100%;
          width: 100%;

          &>img {
            width: 67px;
            top: -35px;
          }

          &>.title {
            font-size: 20px;
            white-space: nowrap;
          }

          &>.line {
            width: 239px;
          }

          &>.sub {
            text-align: left;
          }
        }
      }
    }

  }

  .hoodtwo {
    height: 1602px;

    &>.pack {
      &>.list {
        row-gap: 72px;
        grid-template-rows: 212px 169px 195px 195px;

        &>.card {
          &>.title {
            font-size: 18px;
          }

          &>.sub {
            font-size: 13px;
          }
        }

        &>.card:nth-child(1) {
          &>.title {
            white-space: pre-wrap;
          }

        }
      }
    }
  }

  .mattwo {
    height: 1714px;

    &>.pack {
      &>.list {
        grid-template-rows: 212px 203px 220px 175px;

        &>.card {
          padding-top: 55px;

          &>.title {
            font-size: 20px;
          }

          &>.sub {
            font-size: 13px;
          }
        }

        &>.card:nth-child(3) {
          &>.title {
            white-space: pre-wrap;
          }

        }
      }
    }
  }

  .airtwo,
  .filtertwo {
    height: 1319px;

    &>.pack {
      &>.list {
        grid-template-rows: 225px 190px 175px;

        &>.card {
          padding-top: 52px;

          &>.line {
            margin: 10px 0;
          }

          &>.title {
            font-size: 18px;
          }
        }

        &>.card:nth-child(1) {
          padding-top: 47.4px;

          &>.title {
            white-space: pre-wrap;
          }

        }
      }
    }
  }

  .filtertwo {
    &>.pack {
      &>.list {
        &>.card {
          &>.title {
            font-size: 20px;
          }
        }
      }
    }
  }

  .jointtwo {
    height: 1030px;

    &>.pack {
      padding-top: 179px;

      &>.list {
        grid-template-rows: 171px 189px;
        margin-top: 82px;

        &>.card {
          padding-top: 49px;

          &>.line {
            margin: 12.5px 0;
          }

          &>.sub {
            text-align: center;
          }
        }
      }
    }
  }

  .birdtwo {
    height: 1265px;

    &>.pack {
      &>.list {
        grid-template-rows: 212px 215px 178px;

        &>.card {
          padding-top: 52px;

        }
      }
    }
  }

  .bugtwo {
    height: 1392px;

    &>.pack {
      &>.sub {
        font-size: 15px;
      }

      &>.list {
        justify-content: center;
        box-sizing: border-box;
        width: 100%;
        grid-template-rows: 212px 213px 207px;
        grid-template-columns: 294px;

        &>.card {
          padding-top: 46px;

          &>.line {
            margin: 11px 0;
          }

          &>.title {
            white-space: pre-wrap;
          }

          &>.sub {
            text-align: center;
          }
        }

      }
    }
  }

  .airfiltertwo {
    height: 1376px;

    &>.pack {
      &>.list {
        grid-template-rows: 266px 221px 221px;
        row-gap: 55px;

        &>.card {
          padding-top: 54px;

          &>.sub {
            text-align: center;
          }
        }

        &>.card:nth-child(1) {
          &>.title {
            white-space: pre-wrap;
          }

        }
      }
    }
  }
}