.service {
  position: relative;
  width: 100%;
  height: 780px;
  padding: 0px;
  background-color: #d6f0f5;
  padding-top: 84px;
  box-sizing: border-box;

  &>.pack {
    display: flex;
    flex-direction: column;
    align-items: center;

    &>.category {
      width: 155px;
      height: 33px;
      background-color: var(--navy);
      border-radius: 20px;
      color: white;
      font-size: 11px;
      font-weight: bold;
      font-family: 'gmarket';
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &>.title {
      font-size: 30px;
      font-weight: 500;
      text-align: center;
      margin-top: 14px;
      margin-bottom: 25px;
      color: var(--navy);
      font-family: "score";

      &>b {
        font-family: "score";
        font-weight: bold;
      }
    }

    &>.list {
      width: 855px;
      height: 406px;
      background-color: white;
      box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.13);
      border-radius: 13px;
      padding: 0 38.5px 0 43.5px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &>.card {
        display: flex;
        align-items: center;

        &>.number {
          font-family: 'score';
          font-size: 40px;
          color: var(--navy);
          width: 52px;
          margin-right: 19px;
          font-weight: 500;
        }

        &>.title {
          font-size: 18px;
          font-weight: 500;
          transform: translateY(-3px);
          white-space: nowrap;

          &>b {
            color: var(--dark-sky-blue);
          }
        }

      }

      &>.line {
        margin-top: 7px;
        margin-bottom: 13px;
        width: 100%;
        height: 1px;
        background-color: #d8d8d8;
      }
    }

    &>.washservice {
      height: 343px;
    }
  }
}

.service::after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 50px 40px 0;
  border-color: #d6f0f5 transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -50px;
  left: 48%;
}

@media screen and (max-width : 1024px) {
  .service {
    height: 841px;
    padding-top: 91px;

    &>.pack {
      &>.title {
        font-size: 25px;
        margin-top: 19px;
        margin-bottom: 48px;
      }

      &>.list {
        width: 620px;
        height: 392px;
        padding: 0 36px 0 34px;

        &>.card {
          &>.number {
            font-size: 38px;
            margin-right: 22.5px;
            width: 50px;
          }

          &>.title {
            font-size: 16px;
          }
        }

        &>.line {
          margin: 12px 0;
        }
      }

      &>.washservice {
        width: 620px;
        height: 299px;
      }
    }
  }

  .service::after {
    left: 45%;
  }


  .washspack {
    height: 745px;
  }
}

@media screen and (max-width : 767px) {
  .service {
    height: 872px;
    padding-top: 134px;

    &>.pack {
      &>.title {
        margin-top: 9px;
        margin-bottom: 40px;
      }

      &>.list {
        width: 100%;
        height: 495px;
        padding: 0 22px 0 24.5px;

        &>.card {
          align-items: flex-start;

          &>.number {

            font-size: 30px;
            width: 40px;
            margin-right: 14px;
          }

          &>.title {
            font-size: 15px;
          }
        }

        &>.card:nth-child(1) {
          align-items: center;
        }

        &>.card:nth-child(5) {
          align-items: center;
        }

        &>.card:nth-child(7) {
          align-items: center;
        }

        &>.line {
          margin: 20px 0;
        }
      }

    }
  }

  .service::after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 50px 40px 0;
    border-color: #d6f0f5 transparent;
    display: block;
    width: 0;
    z-index: 1;
    bottom: -50px;
    left: 40%;
  }

  .washspack {
    height: 812px;

    &>.pack {
      &>.list {
        height: 395px;

        &>.card {
          width: 272px;
          align-items: center;
        }

        &>.line {
          margin: 15px 0;
        }
      }
    }
  }

}