.review {
  width: 100%;
  height: 1071px;
  position: relative;
  padding-top: 200px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.review > .pack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.review > .pack > .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "score";
}

.review > .pack > .title > img {
  width: 35.2px;
}

.review > .pack > .title > .right {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.review > .pack > .title > b {
  font-family: "score";
  font-weight: bold;
  font-size: 30px;
  color: var(--navy);
  margin: 0 25.6px;
}

.review > .pack > .sub {
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
  white-space: pre-line;
}

.review > .pack > .sub > b {
  color: #46BAD1;
}

.review > .pack > .list {
  margin-top: 47px;
  display: -ms-grid;
  display: grid;
  -webkit-column-gap: 13px;
          column-gap: 13px;
  row-gap: 14px;
}

.review > .back {
  width: 100%;
  height: 410px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #ddf3f7;
  z-index: -1;
}

.homecleannv {
  padding-top: 0;
  height: 600px;
}

.homecleannv > .back {
  background-color: #e6f9ef;
}

.homecleannv > .pack > .sub > b {
  color: var(--shamrock);
}

.homecleannv > .pack > .list {
  margin-top: 52px;
}

.airre {
  height: 450px;
}

.airconre {
  height: 1900px;
}

.airconre > .back {
  height: 970px;
}

.matre {
  height: 935px;
  padding-top: 134px;
}

.jointre {
  height: 1001px;
  padding-top: 199px;
}

.birdre {
  height: 1023px;
  padding-top: 207px;
}

.buildingre {
  height: 980px;
  padding-top: 100px;
}

.aircarere,
.bugre {
  height: 200px;
  padding-top: 80px;
}

.aircarere > .back,
.bugre > .back {
  height: 200px;
}

.purifyre {
  height: 920px;
  padding-top: 0;
}

.homere {
  padding-top: 90px;
  height: 300px;
}

.homere > .back {
  height: 300px;
}

@media screen and (max-width: 1024px) {
  .review {
    height: 804px;
    padding-top: 134px;
  }
  .review > .pack > .title > b {
    font-size: 25px;
    margin: 0 18px;
  }
  .review > .pack > .title > img {
    width: 32px;
  }
  .review > .pack > .title > .left {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  .review > .pack > .sub {
    margin-top: 19px;
    font-size: 14px;
  }
  .review > .pack > .list {
    margin-top: 37px;
    gap: 10px 9px;
  }
  .review > .back {
    height: 319px;
  }
  .airconre {
    height: 1500px;
  }
  .airconre > .back {
    height: 830px;
  }
  .homere {
    height: 838px;
    padding-top: 50px;
  }
  .homere > .back {
    height: 400px;
  }
  .homecleannv {
    height: 600px;
  }
  .airre {
    height: 330px;
  }
  .bugre {
    padding-top: 85px;
    height: 200px;
  }
  .bugre > .back {
    height: 200px;
  }
  .aircarere {
    height: 200px;
    padding-top: 80px;
  }
  .aircarere > .back {
    height: 200px;
  }
  .homere {
    padding-top: 110px;
    height: 300px;
  }
  .homere > .back {
    height: 300px;
  }
}

@media screen and (max-width: 767px) {
  .review {
    height: 1074px;
    padding-top: 112px;
  }
  .review > .pack > .title {
    position: relative;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .review > .pack > .title > img {
    width: 29.5px;
    position: absolute;
  }
  .review > .pack > .title > .right {
    right: -20px;
    top: -5px;
  }
  .review > .pack > .title > .left {
    left: -10px;
  }
  .review > .pack > .title > b {
    text-align: center;
    margin: 0;
    font-size: 25px;
    white-space: pre-wrap;
  }
  .review > .pack > .list {
    margin-top: 31px;
    gap: 8px;
  }
  .review > .back {
    height: 429px;
  }
  .airconre {
    height: 2140px;
  }
  .airconre > .back {
    height: 1180px;
  }
  .homere {
    height: 1400px;
  }
  .homere > .pack > .sub {
    font-size: 13px;
  }
  .homere > .back {
    height: 560px;
  }
  .washre > .pack > .title > .right,
  .filterre > .pack > .title > .right {
    top: -25px;
  }
  .washre > .pack > .title > .left,
  .filterre > .pack > .title > .left {
    top: -20px;
    left: -20px;
  }
  .homecleannv {
    height: 600px;
  }
  .homecleannv > .back {
    height: 300px;
  }
  .airre {
    padding-top: 90px;
    height: 280px;
  }
  .airre > .pack > .title > .left {
    left: -25px;
  }
  .airre > .back {
    height: 280px;
  }
  .bugre {
    padding-top: 45px;
    height: 150px;
  }
  .bugre > .back {
    height: 150px;
  }
  .aircarere {
    height: 200px;
    padding-top: 70px;
  }
  .aircarere > .back {
    height: 200px;
  }
  .homere {
    padding-top: 100px;
    height: 300px;
  }
  .homere > .back {
    height: 300px;
  }
}
