.partner > .banner {
  height: 370px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: -webkit-image-set(url("/public/assets/partner/banner.png") 1x, url("/public/assets/partner/banner@2x.png") 2x, url("/public/assets/partner/banner@3x.png") 3x);
  background-image: image-set(url("/public/assets/partner/banner.png") 1x, url("/public/assets/partner/banner@2x.png") 2x, url("/public/assets/partner/banner@3x.png") 3x);
  text-align: center;
  padding-top: 234px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.partner > .banner > .title {
  font-size: 39px;
  font-weight: bold;
  color: white;
}

.partner > .section1 {
  height: 1096px;
  position: relative;
}

.partner > .section1 > .back {
  z-index: -1;
  width: 100%;
  height: 372px;
  background-color: #f8f8f8;
  position: absolute;
  left: 0;
  bottom: 0;
}

.partner > .section1 > .pack {
  padding-top: 58px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.partner > .section1 > .pack > .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.partner > .section1 > .pack > .top > .left {
  padding-top: 198px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-right: 67px;
}

.partner > .section1 > .pack > .top > .left > .category {
  width: 168px;
  border-radius: 16.5px;
  font-weight: bold;
  font-family: 'gmarket';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 33px;
  background-color: var(--navy);
  color: white;
  font-size: 11px;
}

.partner > .section1 > .pack > .top > .left > .title {
  font-size: 35px;
  line-height: 1.26;
  color: var(--navy);
  white-space: nowrap;
  margin: 14px 0;
  font-family: "score";
}

.partner > .section1 > .pack > .top > .left > .title > b {
  font-family: "score";
  font-weight: bold;
}

.partner > .section1 > .pack > .top > .left > .sub {
  font-size: 16px;
  line-height: 1.63;
}

.partner > .section1 > .pack > .top > .right {
  width: 676px;
}

.partner > .section1 > .pack > .bottom {
  margin-top: 88px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (237px)[4];
      grid-template-columns: repeat(4, 237px);
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

.partner > .section1 > .pack > .bottom > .card {
  height: 350px;
  border-radius: 11px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-left: 28px;
  padding-top: 25px;
  position: relative;
  overflow: hidden;
}

.partner > .section1 > .pack > .bottom > .card > img {
  width: 53px;
}

.partner > .section1 > .pack > .bottom > .card > .title {
  font-size: 25px;
  font-weight: bold;
  line-height: 1.2;
  white-space: pre-line;
  margin-top: 30px;
}

.partner > .section1 > .pack > .bottom > .card > .sub {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: white;
  height: 159px;
  width: 100%;
  padding-top: 20px;
  padding-left: 29px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 13px;
  line-height: 1.69;
}

.partner > .section1 > .pack > .bottom > .card:nth-child(odd) {
  background-color: var(--navy);
}

.partner > .section1 > .pack > .bottom > .card:nth-child(odd) > .title {
  color: white;
}

.partner > .section1 > .pack > .bottom > .card:nth-child(even) {
  background-color: var(--dark-sky-blue);
}

.partner > .section2 {
  height: 1848px;
  width: 100%;
}

.partner > .section2 > .pack {
  height: 100%;
  position: relative;
  padding-top: 289px;
}

.partner > .section2 > .pack > .back {
  z-index: -1;
  position: absolute;
}

.partner > .section2 > .pack > .back > div {
  width: 100vw;
  height: 258px;
  background-color: #d9f1f6;
}

.partner > .section2 > .pack > .first {
  top: 246px;
  right: 420px;
}

.partner > .section2 > .pack > .second {
  right: 340px;
  top: 1000px;
}

.partner > .section2 > .pack > .second > div {
  height: 322px;
  background-color: #ededed;
}

.partner > .section2 > .pack > div > .img-wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (334px)[2];
      grid-template-columns: repeat(2, 334px);
  -ms-grid-rows: (223px)[2];
      grid-template-rows: repeat(2, 223px);
  gap: 14px 16px;
  -webkit-transform: translateX(250px);
          transform: translateX(250px);
}

.partner > .section2 > .pack > .one > .title {
  font-size: 40px;
  line-height: 1.28;
  color: var(--navy);
  position: relative;
  font-family: "score";
}

.partner > .section2 > .pack > .one > .title > b {
  font-family: "score";
  font-weight: bold;
}

.partner > .section2 > .pack > .one > .title > .line {
  width: 338px;
  height: 29px;
  position: absolute;
  background-color: #46bad1;
  opacity: 0.57;
  bottom: -8px;
  left: -8px;
  z-index: -1;
}

.partner > .section2 > .pack > .one > .sub {
  margin-top: 13px;
  font-size: 20px;
  margin-bottom: 25px;
}

.partner > .section2 > .pack > .two {
  margin-top: 157px;
}

.partner > .section2 > .pack > .two > .title {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.29;
  color: var(--navy);
  margin-bottom: 19px;
  position: relative;
  font-family: "score";
}

.partner > .section2 > .pack > .two > .title > b {
  font-family: "score";
  font-weight: bold;
}

.partner > .section2 > .pack > .two > .title > .line {
  width: 251px;
  height: 29px;
  position: absolute;
  background-color: #8d8d8d;
  opacity: 0.25;
  bottom: -8px;
  left: -8px;
  z-index: -1;
}

.partner > .section2 > .pack > .two > .sub {
  margin-bottom: 29px;
  font-size: 20px;
  line-height: 1.53;
}

.partner > .section3 {
  height: 1586px;
}

.partner > .section3 > .pack {
  position: relative;
  padding-top: 86px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.partner > .section3 > .pack > .back {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.partner > .section3 > .pack > .back > div {
  width: 100vw;
  height: 272px;
  background-color: var(--navy);
}

.partner > .section3 > .pack > .category {
  width: 136px;
  height: 33px;
  background-color: #76c7d3;
  border-radius: 16.5px;
  font-size: 11px;
  font-weight: bold;
  font-family: 'gmarket';
  color: var(--navy);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.partner > .section3 > .pack > .title {
  color: white;
  font-size: 35px;
  font-weight: 500;
  margin-top: 12px;
  font-family: "score";
}

.partner > .section3 > .pack > .title > b {
  font-family: "score";
  font-weight: bold;
}

.partner > .section3 > .pack > .list {
  margin-top: 68px;
  display: -ms-grid;
  display: grid;
  row-gap: 113px;
}

.partner > .section3 > .pack > .list > .card {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.partner > .section3 > .pack > .list > .card > img {
  width: 496px;
}

.partner > .section3 > .pack > .list > .card > .content {
  position: absolute;
}

.partner > .section3 > .pack > .list > .card > .content > .title {
  position: relative;
  background-color: var(--dark-sky-blue);
  color: white;
  font-size: 25px;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.partner > .section3 > .pack > .list > .card > .content > .title > .number {
  position: absolute;
  color: var(--dark-sky-blue);
  font-size: 66px;
  font-weight: 300;
  font-family: 'nexon';
  top: -55px;
}

.partner > .section3 > .pack > .list > .card > .content > .sub {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 14px;
  line-height: 1.71;
}

.partner > .section3 > .pack > .list > .card > .content > .sub > b {
  color: var(--dark-sky-blue);
}

.partner > .section3 > .pack > .list > .first > .content {
  top: 120px;
  left: 470px;
}

.partner > .section3 > .pack > .list > .first > .content > .title {
  width: 360px;
  height: 65px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.partner > .section3 > .pack > .list > .first > .content > .title > .number {
  left: 45px;
}

.partner > .section3 > .pack > .list > .first > .content > .sub {
  padding-left: 45px;
  padding-top: 22px;
}

.partner > .section3 > .pack > .list > .second {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.partner > .section3 > .pack > .list > .second > .content {
  top: 80px;
  left: 100px;
}

.partner > .section3 > .pack > .list > .second > .content > .title {
  width: 422px;
  height: 65px;
  padding-left: 23px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.partner > .section3 > .pack > .list > .second > .content > .title > .number {
  left: 0;
}

.partner > .section3 > .pack > .list > .second > .content > .sub {
  padding-left: 23px;
  padding-top: 22px;
}

.partner > .section3 > .pack > .list > .third > .content {
  top: 100px;
  right: 60px;
}

.partner > .section3 > .pack > .list > .third > .content > .title {
  width: 487px;
  height: 75px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.partner > .section3 > .pack > .list > .third > .content > .title > .number {
  right: 0;
}

.partner > .section3 > .pack > .list > .third > .content > .sub {
  padding-left: 75px;
  padding-top: 22px;
}

@media screen and (max-width: 1024px) {
  .partner > .banner {
    height: 299px;
    padding-top: 210px;
  }
  .partner > .banner > .title {
    font-size: 30px;
  }
  .partner > .section1 {
    height: 840px;
  }
  .partner > .section1 > .back {
    height: 317px;
  }
  .partner > .section1 > .pack {
    padding-top: 106px;
  }
  .partner > .section1 > .pack > .top > .left {
    padding-top: 56px;
    margin-right: 59px;
  }
  .partner > .section1 > .pack > .top > .left > .title {
    font-size: 27px;
  }
  .partner > .section1 > .pack > .top > .left > .sub {
    font-size: 14px;
  }
  .partner > .section1 > .pack > .top > .right {
    width: 340px;
  }
  .partner > .section1 > .pack > .bottom {
    margin-top: 93px;
    -ms-grid-columns: (169px)[4];
        grid-template-columns: repeat(4, 169px);
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }
  .partner > .section1 > .pack > .bottom > .card {
    height: 325px;
    padding: 19px 0 0 15px;
  }
  .partner > .section1 > .pack > .bottom > .card > img {
    width: 37px;
  }
  .partner > .section1 > .pack > .bottom > .card > .title {
    font-size: 15px;
    margin-top: 23px;
  }
  .partner > .section1 > .pack > .bottom > .card > .sub {
    height: 190px;
    padding: 13px 0 0 15px;
  }
  .partner > .section2 {
    height: 1189px;
  }
  .partner > .section2 > .pack {
    padding-top: 176px;
  }
  .partner > .section2 > .pack > .back > div {
    height: 200px;
  }
  .partner > .section2 > .pack > .first {
    top: 140px;
    right: 350px;
  }
  .partner > .section2 > .pack > .second {
    right: 270px;
    top: 584px;
  }
  .partner > .section2 > .pack > .second > div {
    height: 250px;
  }
  .partner > .section2 > .pack > div > .img-wrapper {
    width: 800px;
    -webkit-transform: unset;
            transform: unset;
    -ms-grid-columns: (228px)[4];
        grid-template-columns: repeat(4, 228px);
    -ms-grid-rows: unset;
        grid-template-rows: unset;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    overflow-x: scroll;
  }
  .partner > .section2 > .pack > .one > .title {
    font-size: 27px;
  }
  .partner > .section2 > .pack > .one > .title > .line {
    width: 216px;
    height: 16px;
    bottom: -2px;
    left: -3px;
  }
  .partner > .section2 > .pack > .one > .sub {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 31px;
  }
  .partner > .section2 > .pack > .two {
    margin-top: 170px;
  }
  .partner > .section2 > .pack > .two > .title {
    font-size: 27px;
    margin-bottom: 0;
  }
  .partner > .section2 > .pack > .two > .title > .line {
    width: 157px;
    height: 16px;
    bottom: -2px;
    left: -2px;
  }
  .partner > .section2 > .pack > .two > .sub {
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 26px;
  }
  .partner > .section3 {
    height: 1500px;
  }
  .partner > .section3 > .pack {
    padding-top: 120px;
  }
  .partner > .section3 > .pack > .category {
    width: 118px;
    height: 30px;
  }
  .partner > .section3 > .pack > .title {
    font-size: 27px;
    margin-top: 11px;
  }
  .partner > .section3 > .pack > .list {
    margin-top: 48px;
    row-gap: 185px;
  }
  .partner > .section3 > .pack > .list > .card > img {
    width: 344px;
  }
  .partner > .section3 > .pack > .list > .card > .content > .title {
    font-size: 23px;
  }
  .partner > .section3 > .pack > .list > .card > .content > .number {
    font-size: 60px;
  }
  .partner > .section3 > .pack > .list > .first > .content {
    left: 320px;
  }
  .partner > .section3 > .pack > .list > .first > .content > .title {
    width: 318px;
    height: 61px;
  }
  .partner > .section3 > .pack > .list > .first > .content > .sub {
    padding-top: 14px;
    padding-left: 38px;
  }
  .partner > .section3 > .pack > .list > .second > .content {
    left: 50px;
  }
  .partner > .section3 > .pack > .list > .second > .content > .title {
    width: 331px;
    height: 61px;
  }
  .partner > .section3 > .pack > .list > .second > .content > .sub {
    padding-top: 14px;
    padding-left: 15px;
  }
  .partner > .section3 > .pack > .list > .third > .content {
    right: 35px;
    top: 68px;
  }
  .partner > .section3 > .pack > .list > .third > .content > .title {
    width: 406px;
    height: 61px;
  }
  .partner > .section3 > .pack > .list > .third > .content > .sub {
    padding-top: 15px;
    padding-left: 110px;
  }
}

@media screen and (max-width: 767px) {
  .partner > .banner {
    height: 209px;
    padding-top: 117px;
  }
  .partner > .banner > .title {
    font-size: 23px;
  }
  .partner > .section1 {
    height: 1643px;
  }
  .partner > .section1 > .back {
    height: 1048px;
  }
  .partner > .section1 > .pack {
    padding-top: 94px;
  }
  .partner > .section1 > .pack > .top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .partner > .section1 > .pack > .top > .left {
    padding: unset;
    margin: unset;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
  }
  .partner > .section1 > .pack > .top > .left > .title {
    font-size: 23px;
    margin-top: 13px;
    margin-bottom: 5px;
  }
  .partner > .section1 > .pack > .top > .left > .sub {
    font-size: 13px;
  }
  .partner > .section1 > .pack > .top > .right {
    width: 320px;
    margin-top: 34px;
  }
  .partner > .section1 > .pack > .bottom {
    -ms-grid-columns: 320px;
        grid-template-columns: 320px;
    -ms-grid-rows: 168px 220px 168px 175px;
        grid-template-rows: 168px 220px 168px 175px;
    row-gap: 25px;
  }
  .partner > .section1 > .pack > .bottom > .card {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: unset;
    padding-top: 17px;
  }
  .partner > .section1 > .pack > .bottom > .card > .title {
    white-space: nowrap;
    font-size: 18px;
    margin-top: 7px;
  }
  .partner > .section1 > .pack > .bottom > .card > .sub {
    height: auto;
    text-align: center;
    padding: 12px 0 19px 0;
  }
  .partner > .section2 {
    height: 1140px;
  }
  .partner > .section2 > .pack {
    padding-top: 185px;
  }
  .partner > .section2 > .pack > .back > div {
    width: 150vw;
    height: 300px;
  }
  .partner > .section2 > .pack > .first {
    top: 81px;
    right: -15%;
  }
  .partner > .section2 > .pack > .second {
    right: -15%;
    top: 596px;
  }
  .partner > .section2 > .pack > .second > div {
    height: 246px;
  }
  .partner > .section2 > .pack > div > .img-wrapper {
    width: 110%;
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  .partner > .section2 > .pack > div > .img-wrapper::-webkit-scrollbar {
    display: none;
  }
  .partner > .section2 > .pack > div > .scroll-wrapper {
    overflow: hidden;
    width: 320px;
    height: 3px;
    background-color: #e0e0e0;
    margin-top: 23px;
    position: relative;
  }
  .partner > .section2 > .pack > div > .scroll-wrapper > .bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 112px;
    height: 100%;
    background-color: black;
  }
  .partner > .section2 > .pack > .one > .title {
    font-size: 25px;
  }
  .partner > .section2 > .pack > .one > .title > .line {
    width: 201px;
  }
  .partner > .section2 > .pack > .one > .sub {
    margin-top: 9px;
    margin-bottom: 21px;
  }
  .partner > .section2 > .pack > .two {
    margin-top: 188px;
  }
  .partner > .section2 > .pack > .two > .title {
    font-size: 25px;
  }
  .partner > .section2 > .pack > .two > .title > .line {
    width: 153px;
  }
  .partner > .section2 > .pack > .two > .sub {
    margin-top: 9px;
    margin-bottom: 26px;
  }
  .partner > .section3 {
    height: 1650px;
  }
  .partner > .section3 > .pack {
    padding-top: 122px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .partner > .section3 > .pack > .back {
    right: -15%;
  }
  .partner > .section3 > .pack > .back > div {
    width: 120vw;
    height: 257px;
  }
  .partner > .section3 > .pack > .category {
    width: 123px;
    height: 30px;
  }
  .partner > .section3 > .pack > .title {
    font-size: 25px;
    margin-top: 13px;
  }
  .partner > .section3 > .pack > .list {
    margin-top: 23px;
    row-gap: 79px;
  }
  .partner > .section3 > .pack > .list > .card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .partner > .section3 > .pack > .list > .card > .content {
    width: 100%;
    position: unset;
  }
  .partner > .section3 > .pack > .list > .card > .content > .title {
    width: 100%;
    height: 51px;
    margin: 56px 0 14px 0;
    font-size: 19px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding-left: 23px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .partner > .section3 > .pack > .list > .card > .content > .title > .number {
    font-size: 50px;
    top: -40px;
    left: 20px;
  }
  .partner > .section3 > .pack > .list > .card > .content > .sub {
    font-size: 13px;
    padding: 0 0 0 20px;
  }
  .partner > .section3 > .pack > .list > .third > .content > .title {
    font-size: 17px;
  }
}
